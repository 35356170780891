class Emojis {
    constructor(custom_emojis = []) {
        this.init_emojis().add_custom_emojis(custom_emojis);
    }

    init_emojis() {
        this._emojis = [
            { "category": "smileys_n_people", "keyword": "grinning face", "code": "\uD83D\uDE00" },
            { "category": "smileys_n_people", "keyword": "beaming face with smiling eyes", "code": "\uD83D\uDE01" },
            { "category": "smileys_n_people", "keyword": "face with tears of joy", "code": "\uD83D\uDE02" },
            { "category": "smileys_n_people", "keyword": "rolling on the floor laughing", "code": "\uD83E\uDD23" },
            { "category": "smileys_n_people", "keyword": "grinning face with big eyes", "code": "\uD83D\uDE03" },
            { "category": "smileys_n_people", "keyword": "grinning face with smiling eyes", "code": "\uD83D\uDE04" },
            { "category": "smileys_n_people", "keyword": "grinning face with sweat", "code": "\uD83D\uDE05" },
            { "category": "smileys_n_people", "keyword": "grinning squinting face", "code": "\uD83D\uDE06" },
            { "category": "smileys_n_people", "keyword": "winking face", "code": "\uD83D\uDE09" },
            { "category": "smileys_n_people", "keyword": "smiling face with smiling eyes", "code": "\uD83D\uDE0A" },
            { "category": "smileys_n_people", "keyword": "face savoring food", "code": "\uD83D\uDE0B" },
            { "category": "smileys_n_people", "keyword": "smiling face with sunglasses", "code": "\uD83D\uDE0E" },
            { "category": "smileys_n_people", "keyword": "smiling face with heart-eyes", "code": "\uD83D\uDE0D" },
            { "category": "smileys_n_people", "keyword": "face blowing a kiss", "code": "\uD83D\uDE18" },
            { "category": "smileys_n_people", "keyword": "smiling face with 3 hearts", "code": "\uD83E\uDD70" },
            { "category": "smileys_n_people", "keyword": "kissing face", "code": "\uD83D\uDE17" },
            { "category": "smileys_n_people", "keyword": "kissing face with smiling eyes", "code": "\uD83D\uDE19" },
            { "category": "smileys_n_people", "keyword": "kissing face with closed eyes", "code": "\uD83D\uDE1A" },
            { "category": "smileys_n_people", "keyword": "smiling face", "code": "\u263A\uFE0F" },
            { "category": "smileys_n_people", "keyword": "slightly smiling face", "code": "\uD83D\uDE42" },
            { "category": "smileys_n_people", "keyword": "hugging face", "code": "\uD83E\uDD17" },
            { "category": "smileys_n_people", "keyword": "star-struck", "code": "\uD83E\uDD29" },
            { "category": "smileys_n_people", "keyword": "thinking face", "code": "\uD83E\uDD14" },
            { "category": "smileys_n_people", "keyword": "face with raised eyebrow", "code": "\uD83E\uDD28" },
            { "category": "smileys_n_people", "keyword": "neutral face", "code": "\uD83D\uDE10" },
            { "category": "smileys_n_people", "keyword": "expressionless face", "code": "\uD83D\uDE11" },
            { "category": "smileys_n_people", "keyword": "face without mouth", "code": "\uD83D\uDE36" },
            { "category": "smileys_n_people", "keyword": "face with rolling eyes", "code": "\uD83D\uDE44" },
            { "category": "smileys_n_people", "keyword": "smirking face", "code": "\uD83D\uDE0F" },
            { "category": "smileys_n_people", "keyword": "persevering face", "code": "\uD83D\uDE23" },
            { "category": "smileys_n_people", "keyword": "sad but relieved face", "code": "\uD83D\uDE25" },
            { "category": "smileys_n_people", "keyword": "face with open mouth", "code": "\uD83D\uDE2E" },
            { "category": "smileys_n_people", "keyword": "zipper-mouth face", "code": "\uD83E\uDD10" },
            { "category": "smileys_n_people", "keyword": "hushed face", "code": "\uD83D\uDE2F" },
            { "category": "smileys_n_people", "keyword": "sleepy face", "code": "\uD83D\uDE2A" },
            { "category": "smileys_n_people", "keyword": "tired face", "code": "\uD83D\uDE2B" },
            { "category": "smileys_n_people", "keyword": "sleeping face", "code": "\uD83D\uDE34" },
            { "category": "smileys_n_people", "keyword": "relieved face", "code": "\uD83D\uDE0C" },
            { "category": "smileys_n_people", "keyword": "face with tongue", "code": "\uD83D\uDE1B" },
            { "category": "smileys_n_people", "keyword": "winking face with tongue", "code": "\uD83D\uDE1C" },
            { "category": "smileys_n_people", "keyword": "squinting face with tongue", "code": "\uD83D\uDE1D" },
            { "category": "smileys_n_people", "keyword": "drooling face", "code": "\uD83E\uDD24" },
            { "category": "smileys_n_people", "keyword": "unamused face", "code": "\uD83D\uDE12" },
            { "category": "smileys_n_people", "keyword": "downcast face with sweat", "code": "\uD83D\uDE13" },
            { "category": "smileys_n_people", "keyword": "pensive face", "code": "\uD83D\uDE14" },
            { "category": "smileys_n_people", "keyword": "confused face", "code": "\uD83D\uDE15" },
            { "category": "smileys_n_people", "keyword": "upside-down face", "code": "\uD83D\uDE43" },
            { "category": "smileys_n_people", "keyword": "money-mouth face", "code": "\uD83E\uDD11" },
            { "category": "smileys_n_people", "keyword": "astonished face", "code": "\uD83D\uDE32" },
            { "category": "smileys_n_people", "keyword": "frowning face", "code": "\u2639\uFE0F" },
            { "category": "smileys_n_people", "keyword": "slightly frowning face", "code": "\uD83D\uDE41" },
            { "category": "smileys_n_people", "keyword": "confounded face", "code": "\uD83D\uDE16" },
            { "category": "smileys_n_people", "keyword": "disappointed face", "code": "\uD83D\uDE1E" },
            { "category": "smileys_n_people", "keyword": "worried face", "code": "\uD83D\uDE1F" },
            { "category": "smileys_n_people", "keyword": "face with steam from nose", "code": "\uD83D\uDE24" },
            { "category": "smileys_n_people", "keyword": "crying face", "code": "\uD83D\uDE22" },
            { "category": "smileys_n_people", "keyword": "loudly crying face", "code": "\uD83D\uDE2D" },
            { "category": "smileys_n_people", "keyword": "frowning face with open mouth", "code": "\uD83D\uDE26" },
            { "category": "smileys_n_people", "keyword": "anguished face", "code": "\uD83D\uDE27" },
            { "category": "smileys_n_people", "keyword": "fearful face", "code": "\uD83D\uDE28" },
            { "category": "smileys_n_people", "keyword": "weary face", "code": "\uD83D\uDE29" },
            { "category": "smileys_n_people", "keyword": "exploding head", "code": "\uD83E\uDD2F" },
            { "category": "smileys_n_people", "keyword": "grimacing face", "code": "\uD83D\uDE2C" },
            { "category": "smileys_n_people", "keyword": "anxious face with sweat", "code": "\uD83D\uDE30" },
            { "category": "smileys_n_people", "keyword": "face screaming in fear", "code": "\uD83D\uDE31" },
            { "category": "smileys_n_people", "keyword": "hot face", "code": "\uD83E\uDD75" },
            { "category": "smileys_n_people", "keyword": "cold face", "code": "\uD83E\uDD76" },
            { "category": "smileys_n_people", "keyword": "flushed face", "code": "\uD83D\uDE33" },
            { "category": "smileys_n_people", "keyword": "zany face", "code": "\uD83E\uDD2A" },
            { "category": "smileys_n_people", "keyword": "dizzy face", "code": "\uD83D\uDE35" },
            { "category": "smileys_n_people", "keyword": "pouting face", "code": "\uD83D\uDE21" },
            { "category": "smileys_n_people", "keyword": "angry face", "code": "\uD83D\uDE20" },
            { "category": "smileys_n_people", "keyword": "face with symbols on mouth", "code": "\uD83E\uDD2C" },
            { "category": "smileys_n_people", "keyword": "face with medical mask", "code": "\uD83D\uDE37" },
            { "category": "smileys_n_people", "keyword": "face with thermometer", "code": "\uD83E\uDD12" },
            { "category": "smileys_n_people", "keyword": "face with head-bandage", "code": "\uD83E\uDD15" },
            { "category": "smileys_n_people", "keyword": "nauseated face", "code": "\uD83E\uDD22" },
            { "category": "smileys_n_people", "keyword": "face vomiting", "code": "\uD83E\uDD2E" },
            { "category": "smileys_n_people", "keyword": "sneezing face", "code": "\uD83E\uDD27" },
            { "category": "smileys_n_people", "keyword": "smiling face with halo", "code": "\uD83D\uDE07" },
            { "category": "smileys_n_people", "keyword": "cowboy hat face", "code": "\uD83E\uDD20" },
            { "category": "smileys_n_people", "keyword": "partying face", "code": "\uD83E\uDD73" },
            { "category": "smileys_n_people", "keyword": "woozy face", "code": "\uD83E\uDD74" },
            { "category": "smileys_n_people", "keyword": "pleading face", "code": "\uD83E\uDD7A" },
            { "category": "smileys_n_people", "keyword": "lying face", "code": "\uD83E\uDD25" },
            { "category": "smileys_n_people", "keyword": "shushing face", "code": "\uD83E\uDD2B" },
            { "category": "smileys_n_people", "keyword": "face with hand over mouth", "code": "\uD83E\uDD2D" },
            { "category": "smileys_n_people", "keyword": "face with monocle", "code": "\uD83E\uDDD0" },
            { "category": "smileys_n_people", "keyword": "nerd face", "code": "\uD83E\uDD13" },
            { "category": "smileys_n_people", "keyword": "smiling face with horns", "code": "\uD83D\uDE08" },
            { "category": "smileys_n_people", "keyword": "angry face with horns", "code": "\uD83D\uDC7F" },
            { "category": "smileys_n_people", "keyword": "clown face", "code": "\uD83E\uDD21" },
            { "category": "smileys_n_people", "keyword": "ogre", "code": "\uD83D\uDC79" },
            { "category": "smileys_n_people", "keyword": "goblin", "code": "\uD83D\uDC7A" },
            { "category": "smileys_n_people", "keyword": "skull", "code": "\uD83D\uDC80" },
            { "category": "smileys_n_people", "keyword": "skull and crossbones", "code": "\u2620\uFE0F" },
            { "category": "smileys_n_people", "keyword": "ghost", "code": "\uD83D\uDC7B" },
            { "category": "smileys_n_people", "keyword": "alien", "code": "\uD83D\uDC7D" },
            { "category": "smileys_n_people", "keyword": "alien monster", "code": "\uD83D\uDC7E" },
            { "category": "smileys_n_people", "keyword": "robot face", "code": "\uD83E\uDD16" },
            { "category": "smileys_n_people", "keyword": "pile of poo", "code": "\uD83D\uDCA9" },
            { "category": "smileys_n_people", "keyword": "grinning cat face", "code": "\uD83D\uDE3A" },
            { "category": "smileys_n_people", "keyword": "grinning cat face with smiling eyes", "code": "\uD83D\uDE38" },
            { "category": "smileys_n_people", "keyword": "cat face with tears of joy", "code": "\uD83D\uDE39" },
            { "category": "smileys_n_people", "keyword": "smiling cat face with heart-eyes", "code": "\uD83D\uDE3B" },
            { "category": "smileys_n_people", "keyword": "cat face with wry smile", "code": "\uD83D\uDE3C" },
            { "category": "smileys_n_people", "keyword": "kissing cat face", "code": "\uD83D\uDE3D" },
            { "category": "smileys_n_people", "keyword": "weary cat face", "code": "\uD83D\uDE40" },
            { "category": "smileys_n_people", "keyword": "crying cat face", "code": "\uD83D\uDE3F" },
            { "category": "smileys_n_people", "keyword": "pouting cat face", "code": "\uD83D\uDE3E" },
            { "category": "smileys_n_people", "keyword": "see-no-evil monkey", "code": "\uD83D\uDE48" },
            { "category": "smileys_n_people", "keyword": "hear-no-evil monkey", "code": "\uD83D\uDE49" },
            { "category": "smileys_n_people", "keyword": "speak-no-evil monkey", "code": "\uD83D\uDE4A" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "light skin tone", "code": "\uD83C\uDFFB" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "medium-light skin tone", "code": "\uD83C\uDFFC" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "medium skin tone", "code": "\uD83C\uDFFD" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "medium-dark skin tone", "code": "\uD83C\uDFFE" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "dark skin tone", "code": "\uD83C\uDFFF" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "baby", "code": "\uD83D\uDC76" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "baby: light skin tone", "code": "\uD83D\uDC76\uD83C\uDFFB" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "baby: medium-light skin tone", "code": "\uD83D\uDC76\uD83C\uDFFC" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "baby: medium skin tone", "code": "\uD83D\uDC76\uD83C\uDFFD" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "baby: medium-dark skin tone", "code": "\uD83D\uDC76\uD83C\uDFFE" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "baby: dark skin tone", "code": "\uD83D\uDC76\uD83C\uDFFF" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "child", "code": "\uD83E\uDDD2" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "child: light skin tone", "code": "\uD83E\uDDD2\uD83C\uDFFB" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "child: medium-light skin tone", "code": "\uD83E\uDDD2\uD83C\uDFFC" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "child: medium skin tone", "code": "\uD83E\uDDD2\uD83C\uDFFD" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "child: medium-dark skin tone", "code": "\uD83E\uDDD2\uD83C\uDFFE" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "child: dark skin tone", "code": "\uD83E\uDDD2\uD83C\uDFFF" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "boy", "code": "\uD83D\uDC66" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "boy: light skin tone", "code": "\uD83D\uDC66\uD83C\uDFFB" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "boy: medium-light skin tone", "code": "\uD83D\uDC66\uD83C\uDFFC" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "boy: medium skin tone", "code": "\uD83D\uDC66\uD83C\uDFFD" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "boy: medium-dark skin tone", "code": "\uD83D\uDC66\uD83C\uDFFE" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "boy: dark skin tone", "code": "\uD83D\uDC66\uD83C\uDFFF" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "girl", "code": "\uD83D\uDC67" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "girl: light skin tone", "code": "\uD83D\uDC67\uD83C\uDFFB" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "girl: medium-light skin tone", "code": "\uD83D\uDC67\uD83C\uDFFC" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "girl: medium skin tone", "code": "\uD83D\uDC67\uD83C\uDFFD" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "girl: medium-dark skin tone", "code": "\uD83D\uDC67\uD83C\uDFFE" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "girl: dark skin tone", "code": "\uD83D\uDC67\uD83C\uDFFF" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "adult", "code": "\uD83E\uDDD1" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "adult: light skin tone", "code": "\uD83E\uDDD1\uD83C\uDFFB" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "adult: medium-light skin tone", "code": "\uD83E\uDDD1\uD83C\uDFFC" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "adult: medium skin tone", "code": "\uD83E\uDDD1\uD83C\uDFFD" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "adult: medium-dark skin tone", "code": "\uD83E\uDDD1\uD83C\uDFFE" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "adult: dark skin tone", "code": "\uD83E\uDDD1\uD83C\uDFFF" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "man", "code": "\uD83D\uDC68" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "man: light skin tone", "code": "\uD83D\uDC68\uD83C\uDFFB" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "man: medium-light skin tone", "code": "\uD83D\uDC68\uD83C\uDFFC" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "man: medium skin tone", "code": "\uD83D\uDC68\uD83C\uDFFD" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "man: medium-dark skin tone", "code": "\uD83D\uDC68\uD83C\uDFFE" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "man: dark skin tone", "code": "\uD83D\uDC68\uD83C\uDFFF" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "woman", "code": "\uD83D\uDC69" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "woman: light skin tone", "code": "\uD83D\uDC69\uD83C\uDFFB" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "woman: medium-light skin tone", "code": "\uD83D\uDC69\uD83C\uDFFC" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "woman: medium skin tone", "code": "\uD83D\uDC69\uD83C\uDFFD" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "woman: medium-dark skin tone", "code": "\uD83D\uDC69\uD83C\uDFFE" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "woman: dark skin tone", "code": "\uD83D\uDC69\uD83C\uDFFF" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "older adult", "code": "\uD83E\uDDD3" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "older adult: light skin tone", "code": "\uD83E\uDDD3\uD83C\uDFFB" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "older adult: medium-light skin tone", "code": "\uD83E\uDDD3\uD83C\uDFFC" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "older adult: medium skin tone", "code": "\uD83E\uDDD3\uD83C\uDFFD" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "older adult: medium-dark skin tone", "code": "\uD83E\uDDD3\uD83C\uDFFE" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "older adult: dark skin tone", "code": "\uD83E\uDDD3\uD83C\uDFFF" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "old man", "code": "\uD83D\uDC74" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "old man: light skin tone", "code": "\uD83D\uDC74\uD83C\uDFFB" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "old man: medium-light skin tone", "code": "\uD83D\uDC74\uD83C\uDFFC" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "old man: medium skin tone", "code": "\uD83D\uDC74\uD83C\uDFFD" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "old man: medium-dark skin tone", "code": "\uD83D\uDC74\uD83C\uDFFE" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "old man: dark skin tone", "code": "\uD83D\uDC74\uD83C\uDFFF" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "old woman", "code": "\uD83D\uDC75" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "old woman: light skin tone", "code": "\uD83D\uDC75\uD83C\uDFFB" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "old woman: medium-light skin tone", "code": "\uD83D\uDC75\uD83C\uDFFC" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "old woman: medium skin tone", "code": "\uD83D\uDC75\uD83C\uDFFD" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "old woman: medium-dark skin tone", "code": "\uD83D\uDC75\uD83C\uDFFE" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "old woman: dark skin tone", "code": "\uD83D\uDC75\uD83C\uDFFF" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "man health worker", "code": "\uD83D\uDC68\u200D\u2695\uFE0F" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "man health worker: light skin tone", "code": "\uD83D\uDC68\uD83C\uDFFB\u200D\u2695\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "man health worker: medium-light skin tone", "code": "\uD83D\uDC68\uD83C\uDFFC\u200D\u2695\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "man health worker: medium skin tone", "code": "\uD83D\uDC68\uD83C\uDFFD\u200D\u2695\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "man health worker: medium-dark skin tone", "code": "\uD83D\uDC68\uD83C\uDFFE\u200D\u2695\uFE0F" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "man health worker: dark skin tone", "code": "\uD83D\uDC68\uD83C\uDFFF\u200D\u2695\uFE0F" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "woman health worker", "code": "\uD83D\uDC69\u200D\u2695\uFE0F" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "woman health worker: light skin tone", "code": "\uD83D\uDC69\uD83C\uDFFB\u200D\u2695\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "woman health worker: medium-light skin tone", "code": "\uD83D\uDC69\uD83C\uDFFC\u200D\u2695\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "woman health worker: medium skin tone", "code": "\uD83D\uDC69\uD83C\uDFFD\u200D\u2695\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "woman health worker: medium-dark skin tone", "code": "\uD83D\uDC69\uD83C\uDFFE\u200D\u2695\uFE0F" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "woman health worker: dark skin tone", "code": "\uD83D\uDC69\uD83C\uDFFF\u200D\u2695\uFE0F" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "man student", "code": "\uD83D\uDC68\u200D\uD83C\uDF93" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "man student: light skin tone", "code": "\uD83D\uDC68\uD83C\uDFFB\u200D\uD83C\uDF93" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "man student: medium-light skin tone", "code": "\uD83D\uDC68\uD83C\uDFFC\u200D\uD83C\uDF93" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "man student: medium skin tone", "code": "\uD83D\uDC68\uD83C\uDFFD\u200D\uD83C\uDF93" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "man student: medium-dark skin tone", "code": "\uD83D\uDC68\uD83C\uDFFE\u200D\uD83C\uDF93" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "man student: dark skin tone", "code": "\uD83D\uDC68\uD83C\uDFFF\u200D\uD83C\uDF93" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "woman student", "code": "\uD83D\uDC69\u200D\uD83C\uDF93" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "woman student: light skin tone", "code": "\uD83D\uDC69\uD83C\uDFFB\u200D\uD83C\uDF93" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "woman student: medium-light skin tone", "code": "\uD83D\uDC69\uD83C\uDFFC\u200D\uD83C\uDF93" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "woman student: medium skin tone", "code": "\uD83D\uDC69\uD83C\uDFFD\u200D\uD83C\uDF93" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "woman student: medium-dark skin tone", "code": "\uD83D\uDC69\uD83C\uDFFE\u200D\uD83C\uDF93" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "woman student: dark skin tone", "code": "\uD83D\uDC69\uD83C\uDFFF\u200D\uD83C\uDF93" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "man teacher", "code": "\uD83D\uDC68\u200D\uD83C\uDFEB" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "man teacher: light skin tone", "code": "\uD83D\uDC68\uD83C\uDFFB\u200D\uD83C\uDFEB" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "man teacher: medium-light skin tone", "code": "\uD83D\uDC68\uD83C\uDFFC\u200D\uD83C\uDFEB" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "man teacher: medium skin tone", "code": "\uD83D\uDC68\uD83C\uDFFD\u200D\uD83C\uDFEB" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "man teacher: medium-dark skin tone", "code": "\uD83D\uDC68\uD83C\uDFFE\u200D\uD83C\uDFEB" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "man teacher: dark skin tone", "code": "\uD83D\uDC68\uD83C\uDFFF\u200D\uD83C\uDFEB" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "woman teacher", "code": "\uD83D\uDC69\u200D\uD83C\uDFEB" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "woman teacher: light skin tone", "code": "\uD83D\uDC69\uD83C\uDFFB\u200D\uD83C\uDFEB" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "woman teacher: medium-light skin tone", "code": "\uD83D\uDC69\uD83C\uDFFC\u200D\uD83C\uDFEB" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "woman teacher: medium skin tone", "code": "\uD83D\uDC69\uD83C\uDFFD\u200D\uD83C\uDFEB" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "woman teacher: medium-dark skin tone", "code": "\uD83D\uDC69\uD83C\uDFFE\u200D\uD83C\uDFEB" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "woman teacher: dark skin tone", "code": "\uD83D\uDC69\uD83C\uDFFF\u200D\uD83C\uDFEB" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "man judge", "code": "\uD83D\uDC68\u200D\u2696\uFE0F" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "man judge: light skin tone", "code": "\uD83D\uDC68\uD83C\uDFFB\u200D\u2696\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "man judge: medium-light skin tone", "code": "\uD83D\uDC68\uD83C\uDFFC\u200D\u2696\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "man judge: medium skin tone", "code": "\uD83D\uDC68\uD83C\uDFFD\u200D\u2696\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "man judge: medium-dark skin tone", "code": "\uD83D\uDC68\uD83C\uDFFE\u200D\u2696\uFE0F" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "man judge: dark skin tone", "code": "\uD83D\uDC68\uD83C\uDFFF\u200D\u2696\uFE0F" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "woman judge", "code": "\uD83D\uDC69\u200D\u2696\uFE0F" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "woman judge: light skin tone", "code": "\uD83D\uDC69\uD83C\uDFFB\u200D\u2696\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "woman judge: medium-light skin tone", "code": "\uD83D\uDC69\uD83C\uDFFC\u200D\u2696\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "woman judge: medium skin tone", "code": "\uD83D\uDC69\uD83C\uDFFD\u200D\u2696\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "woman judge: medium-dark skin tone", "code": "\uD83D\uDC69\uD83C\uDFFE\u200D\u2696\uFE0F" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "woman judge: dark skin tone", "code": "\uD83D\uDC69\uD83C\uDFFF\u200D\u2696\uFE0F" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "man farmer", "code": "\uD83D\uDC68\u200D\uD83C\uDF3E" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "man farmer: light skin tone", "code": "\uD83D\uDC68\uD83C\uDFFB\u200D\uD83C\uDF3E" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "man farmer: medium-light skin tone", "code": "\uD83D\uDC68\uD83C\uDFFC\u200D\uD83C\uDF3E" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "man farmer: medium skin tone", "code": "\uD83D\uDC68\uD83C\uDFFD\u200D\uD83C\uDF3E" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "man farmer: medium-dark skin tone", "code": "\uD83D\uDC68\uD83C\uDFFE\u200D\uD83C\uDF3E" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "man farmer: dark skin tone", "code": "\uD83D\uDC68\uD83C\uDFFF\u200D\uD83C\uDF3E" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "woman farmer", "code": "\uD83D\uDC69\u200D\uD83C\uDF3E" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "woman farmer: light skin tone", "code": "\uD83D\uDC69\uD83C\uDFFB\u200D\uD83C\uDF3E" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "woman farmer: medium-light skin tone", "code": "\uD83D\uDC69\uD83C\uDFFC\u200D\uD83C\uDF3E" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "woman farmer: medium skin tone", "code": "\uD83D\uDC69\uD83C\uDFFD\u200D\uD83C\uDF3E" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "woman farmer: medium-dark skin tone", "code": "\uD83D\uDC69\uD83C\uDFFE\u200D\uD83C\uDF3E" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "woman farmer: dark skin tone", "code": "\uD83D\uDC69\uD83C\uDFFF\u200D\uD83C\uDF3E" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "man cook", "code": "\uD83D\uDC68\u200D\uD83C\uDF73" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "man cook: light skin tone", "code": "\uD83D\uDC68\uD83C\uDFFB\u200D\uD83C\uDF73" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "man cook: medium-light skin tone", "code": "\uD83D\uDC68\uD83C\uDFFC\u200D\uD83C\uDF73" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "man cook: medium skin tone", "code": "\uD83D\uDC68\uD83C\uDFFD\u200D\uD83C\uDF73" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "man cook: medium-dark skin tone", "code": "\uD83D\uDC68\uD83C\uDFFE\u200D\uD83C\uDF73" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "man cook: dark skin tone", "code": "\uD83D\uDC68\uD83C\uDFFF\u200D\uD83C\uDF73" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "woman cook", "code": "\uD83D\uDC69\u200D\uD83C\uDF73" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "woman cook: light skin tone", "code": "\uD83D\uDC69\uD83C\uDFFB\u200D\uD83C\uDF73" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "woman cook: medium-light skin tone", "code": "\uD83D\uDC69\uD83C\uDFFC\u200D\uD83C\uDF73" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "woman cook: medium skin tone", "code": "\uD83D\uDC69\uD83C\uDFFD\u200D\uD83C\uDF73" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "woman cook: medium-dark skin tone", "code": "\uD83D\uDC69\uD83C\uDFFE\u200D\uD83C\uDF73" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "woman cook: dark skin tone", "code": "\uD83D\uDC69\uD83C\uDFFF\u200D\uD83C\uDF73" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "man mechanic", "code": "\uD83D\uDC68\u200D\uD83D\uDD27" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "man mechanic: light skin tone", "code": "\uD83D\uDC68\uD83C\uDFFB\u200D\uD83D\uDD27" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "man mechanic: medium-light skin tone", "code": "\uD83D\uDC68\uD83C\uDFFC\u200D\uD83D\uDD27" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "man mechanic: medium skin tone", "code": "\uD83D\uDC68\uD83C\uDFFD\u200D\uD83D\uDD27" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "man mechanic: medium-dark skin tone", "code": "\uD83D\uDC68\uD83C\uDFFE\u200D\uD83D\uDD27" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "man mechanic: dark skin tone", "code": "\uD83D\uDC68\uD83C\uDFFF\u200D\uD83D\uDD27" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "woman mechanic", "code": "\uD83D\uDC69\u200D\uD83D\uDD27" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "woman mechanic: light skin tone", "code": "\uD83D\uDC69\uD83C\uDFFB\u200D\uD83D\uDD27" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "woman mechanic: medium-light skin tone", "code": "\uD83D\uDC69\uD83C\uDFFC\u200D\uD83D\uDD27" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "woman mechanic: medium skin tone", "code": "\uD83D\uDC69\uD83C\uDFFD\u200D\uD83D\uDD27" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "woman mechanic: medium-dark skin tone", "code": "\uD83D\uDC69\uD83C\uDFFE\u200D\uD83D\uDD27" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "woman mechanic: dark skin tone", "code": "\uD83D\uDC69\uD83C\uDFFF\u200D\uD83D\uDD27" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "man factory worker", "code": "\uD83D\uDC68\u200D\uD83C\uDFED" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "man factory worker: light skin tone", "code": "\uD83D\uDC68\uD83C\uDFFB\u200D\uD83C\uDFED" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "man factory worker: medium-light skin tone", "code": "\uD83D\uDC68\uD83C\uDFFC\u200D\uD83C\uDFED" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "man factory worker: medium skin tone", "code": "\uD83D\uDC68\uD83C\uDFFD\u200D\uD83C\uDFED" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "man factory worker: medium-dark skin tone", "code": "\uD83D\uDC68\uD83C\uDFFE\u200D\uD83C\uDFED" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "man factory worker: dark skin tone", "code": "\uD83D\uDC68\uD83C\uDFFF\u200D\uD83C\uDFED" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "woman factory worker", "code": "\uD83D\uDC69\u200D\uD83C\uDFED" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "woman factory worker: light skin tone", "code": "\uD83D\uDC69\uD83C\uDFFB\u200D\uD83C\uDFED" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "woman factory worker: medium-light skin tone", "code": "\uD83D\uDC69\uD83C\uDFFC\u200D\uD83C\uDFED" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "woman factory worker: medium skin tone", "code": "\uD83D\uDC69\uD83C\uDFFD\u200D\uD83C\uDFED" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "woman factory worker: medium-dark skin tone", "code": "\uD83D\uDC69\uD83C\uDFFE\u200D\uD83C\uDFED" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "woman factory worker: dark skin tone", "code": "\uD83D\uDC69\uD83C\uDFFF\u200D\uD83C\uDFED" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "man office worker", "code": "\uD83D\uDC68\u200D\uD83D\uDCBC" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "man office worker: light skin tone", "code": "\uD83D\uDC68\uD83C\uDFFB\u200D\uD83D\uDCBC" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "man office worker: medium-light skin tone", "code": "\uD83D\uDC68\uD83C\uDFFC\u200D\uD83D\uDCBC" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "man office worker: medium skin tone", "code": "\uD83D\uDC68\uD83C\uDFFD\u200D\uD83D\uDCBC" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "man office worker: medium-dark skin tone", "code": "\uD83D\uDC68\uD83C\uDFFE\u200D\uD83D\uDCBC" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "man office worker: dark skin tone", "code": "\uD83D\uDC68\uD83C\uDFFF\u200D\uD83D\uDCBC" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "woman office worker", "code": "\uD83D\uDC69\u200D\uD83D\uDCBC" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "woman office worker: light skin tone", "code": "\uD83D\uDC69\uD83C\uDFFB\u200D\uD83D\uDCBC" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "woman office worker: medium-light skin tone", "code": "\uD83D\uDC69\uD83C\uDFFC\u200D\uD83D\uDCBC" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "woman office worker: medium skin tone", "code": "\uD83D\uDC69\uD83C\uDFFD\u200D\uD83D\uDCBC" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "woman office worker: medium-dark skin tone", "code": "\uD83D\uDC69\uD83C\uDFFE\u200D\uD83D\uDCBC" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "woman office worker: dark skin tone", "code": "\uD83D\uDC69\uD83C\uDFFF\u200D\uD83D\uDCBC" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "man scientist", "code": "\uD83D\uDC68\u200D\uD83D\uDD2C" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "man scientist: light skin tone", "code": "\uD83D\uDC68\uD83C\uDFFB\u200D\uD83D\uDD2C" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "man scientist: medium-light skin tone", "code": "\uD83D\uDC68\uD83C\uDFFC\u200D\uD83D\uDD2C" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "man scientist: medium skin tone", "code": "\uD83D\uDC68\uD83C\uDFFD\u200D\uD83D\uDD2C" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "man scientist: medium-dark skin tone", "code": "\uD83D\uDC68\uD83C\uDFFE\u200D\uD83D\uDD2C" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "man scientist: dark skin tone", "code": "\uD83D\uDC68\uD83C\uDFFF\u200D\uD83D\uDD2C" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "woman scientist", "code": "\uD83D\uDC69\u200D\uD83D\uDD2C" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "woman scientist: light skin tone", "code": "\uD83D\uDC69\uD83C\uDFFB\u200D\uD83D\uDD2C" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "woman scientist: medium-light skin tone", "code": "\uD83D\uDC69\uD83C\uDFFC\u200D\uD83D\uDD2C" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "woman scientist: medium skin tone", "code": "\uD83D\uDC69\uD83C\uDFFD\u200D\uD83D\uDD2C" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "woman scientist: medium-dark skin tone", "code": "\uD83D\uDC69\uD83C\uDFFE\u200D\uD83D\uDD2C" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "woman scientist: dark skin tone", "code": "\uD83D\uDC69\uD83C\uDFFF\u200D\uD83D\uDD2C" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "man technologist", "code": "\uD83D\uDC68\u200D\uD83D\uDCBB" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "man technologist: light skin tone", "code": "\uD83D\uDC68\uD83C\uDFFB\u200D\uD83D\uDCBB" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "man technologist: medium-light skin tone", "code": "\uD83D\uDC68\uD83C\uDFFC\u200D\uD83D\uDCBB" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "man technologist: medium skin tone", "code": "\uD83D\uDC68\uD83C\uDFFD\u200D\uD83D\uDCBB" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "man technologist: medium-dark skin tone", "code": "\uD83D\uDC68\uD83C\uDFFE\u200D\uD83D\uDCBB" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "man technologist: dark skin tone", "code": "\uD83D\uDC68\uD83C\uDFFF\u200D\uD83D\uDCBB" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "woman technologist", "code": "\uD83D\uDC69\u200D\uD83D\uDCBB" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "woman technologist: light skin tone", "code": "\uD83D\uDC69\uD83C\uDFFB\u200D\uD83D\uDCBB" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "woman technologist: medium-light skin tone", "code": "\uD83D\uDC69\uD83C\uDFFC\u200D\uD83D\uDCBB" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "woman technologist: medium skin tone", "code": "\uD83D\uDC69\uD83C\uDFFD\u200D\uD83D\uDCBB" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "woman technologist: medium-dark skin tone", "code": "\uD83D\uDC69\uD83C\uDFFE\u200D\uD83D\uDCBB" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "woman technologist: dark skin tone", "code": "\uD83D\uDC69\uD83C\uDFFF\u200D\uD83D\uDCBB" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "man singer", "code": "\uD83D\uDC68\u200D\uD83C\uDFA4" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "man singer: light skin tone", "code": "\uD83D\uDC68\uD83C\uDFFB\u200D\uD83C\uDFA4" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "man singer: medium-light skin tone", "code": "\uD83D\uDC68\uD83C\uDFFC\u200D\uD83C\uDFA4" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "man singer: medium skin tone", "code": "\uD83D\uDC68\uD83C\uDFFD\u200D\uD83C\uDFA4" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "man singer: medium-dark skin tone", "code": "\uD83D\uDC68\uD83C\uDFFE\u200D\uD83C\uDFA4" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "man singer: dark skin tone", "code": "\uD83D\uDC68\uD83C\uDFFF\u200D\uD83C\uDFA4" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "woman singer", "code": "\uD83D\uDC69\u200D\uD83C\uDFA4" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "woman singer: light skin tone", "code": "\uD83D\uDC69\uD83C\uDFFB\u200D\uD83C\uDFA4" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "woman singer: medium-light skin tone", "code": "\uD83D\uDC69\uD83C\uDFFC\u200D\uD83C\uDFA4" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "woman singer: medium skin tone", "code": "\uD83D\uDC69\uD83C\uDFFD\u200D\uD83C\uDFA4" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "woman singer: medium-dark skin tone", "code": "\uD83D\uDC69\uD83C\uDFFE\u200D\uD83C\uDFA4" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "woman singer: dark skin tone", "code": "\uD83D\uDC69\uD83C\uDFFF\u200D\uD83C\uDFA4" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "man artist", "code": "\uD83D\uDC68\u200D\uD83C\uDFA8" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "man artist: light skin tone", "code": "\uD83D\uDC68\uD83C\uDFFB\u200D\uD83C\uDFA8" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "man artist: medium-light skin tone", "code": "\uD83D\uDC68\uD83C\uDFFC\u200D\uD83C\uDFA8" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "man artist: medium skin tone", "code": "\uD83D\uDC68\uD83C\uDFFD\u200D\uD83C\uDFA8" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "man artist: medium-dark skin tone", "code": "\uD83D\uDC68\uD83C\uDFFE\u200D\uD83C\uDFA8" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "man artist: dark skin tone", "code": "\uD83D\uDC68\uD83C\uDFFF\u200D\uD83C\uDFA8" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "woman artist", "code": "\uD83D\uDC69\u200D\uD83C\uDFA8" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "woman artist: light skin tone", "code": "\uD83D\uDC69\uD83C\uDFFB\u200D\uD83C\uDFA8" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "woman artist: medium-light skin tone", "code": "\uD83D\uDC69\uD83C\uDFFC\u200D\uD83C\uDFA8" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "woman artist: medium skin tone", "code": "\uD83D\uDC69\uD83C\uDFFD\u200D\uD83C\uDFA8" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "woman artist: medium-dark skin tone", "code": "\uD83D\uDC69\uD83C\uDFFE\u200D\uD83C\uDFA8" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "woman artist: dark skin tone", "code": "\uD83D\uDC69\uD83C\uDFFF\u200D\uD83C\uDFA8" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "man pilot", "code": "\uD83D\uDC68\u200D\u2708\uFE0F" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "man pilot: light skin tone", "code": "\uD83D\uDC68\uD83C\uDFFB\u200D\u2708\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "man pilot: medium-light skin tone", "code": "\uD83D\uDC68\uD83C\uDFFC\u200D\u2708\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "man pilot: medium skin tone", "code": "\uD83D\uDC68\uD83C\uDFFD\u200D\u2708\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "man pilot: medium-dark skin tone", "code": "\uD83D\uDC68\uD83C\uDFFE\u200D\u2708\uFE0F" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "man pilot: dark skin tone", "code": "\uD83D\uDC68\uD83C\uDFFF\u200D\u2708\uFE0F" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "woman pilot", "code": "\uD83D\uDC69\u200D\u2708\uFE0F" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "woman pilot: light skin tone", "code": "\uD83D\uDC69\uD83C\uDFFB\u200D\u2708\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "woman pilot: medium-light skin tone", "code": "\uD83D\uDC69\uD83C\uDFFC\u200D\u2708\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "woman pilot: medium skin tone", "code": "\uD83D\uDC69\uD83C\uDFFD\u200D\u2708\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "woman pilot: medium-dark skin tone", "code": "\uD83D\uDC69\uD83C\uDFFE\u200D\u2708\uFE0F" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "woman pilot: dark skin tone", "code": "\uD83D\uDC69\uD83C\uDFFF\u200D\u2708\uFE0F" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "man astronaut", "code": "\uD83D\uDC68\u200D\uD83D\uDE80" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "man astronaut: light skin tone", "code": "\uD83D\uDC68\uD83C\uDFFB\u200D\uD83D\uDE80" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "man astronaut: medium-light skin tone", "code": "\uD83D\uDC68\uD83C\uDFFC\u200D\uD83D\uDE80" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "man astronaut: medium skin tone", "code": "\uD83D\uDC68\uD83C\uDFFD\u200D\uD83D\uDE80" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "man astronaut: medium-dark skin tone", "code": "\uD83D\uDC68\uD83C\uDFFE\u200D\uD83D\uDE80" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "man astronaut: dark skin tone", "code": "\uD83D\uDC68\uD83C\uDFFF\u200D\uD83D\uDE80" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "woman astronaut", "code": "\uD83D\uDC69\u200D\uD83D\uDE80" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "woman astronaut: light skin tone", "code": "\uD83D\uDC69\uD83C\uDFFB\u200D\uD83D\uDE80" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "woman astronaut: medium-light skin tone", "code": "\uD83D\uDC69\uD83C\uDFFC\u200D\uD83D\uDE80" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "woman astronaut: medium skin tone", "code": "\uD83D\uDC69\uD83C\uDFFD\u200D\uD83D\uDE80" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "woman astronaut: medium-dark skin tone", "code": "\uD83D\uDC69\uD83C\uDFFE\u200D\uD83D\uDE80" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "woman astronaut: dark skin tone", "code": "\uD83D\uDC69\uD83C\uDFFF\u200D\uD83D\uDE80" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "man firefighter", "code": "\uD83D\uDC68\u200D\uD83D\uDE92" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "man firefighter: light skin tone", "code": "\uD83D\uDC68\uD83C\uDFFB\u200D\uD83D\uDE92" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "man firefighter: medium-light skin tone", "code": "\uD83D\uDC68\uD83C\uDFFC\u200D\uD83D\uDE92" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "man firefighter: medium skin tone", "code": "\uD83D\uDC68\uD83C\uDFFD\u200D\uD83D\uDE92" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "man firefighter: medium-dark skin tone", "code": "\uD83D\uDC68\uD83C\uDFFE\u200D\uD83D\uDE92" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "man firefighter: dark skin tone", "code": "\uD83D\uDC68\uD83C\uDFFF\u200D\uD83D\uDE92" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "woman firefighter", "code": "\uD83D\uDC69\u200D\uD83D\uDE92" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "woman firefighter: light skin tone", "code": "\uD83D\uDC69\uD83C\uDFFB\u200D\uD83D\uDE92" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "woman firefighter: medium-light skin tone", "code": "\uD83D\uDC69\uD83C\uDFFC\u200D\uD83D\uDE92" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "woman firefighter: medium skin tone", "code": "\uD83D\uDC69\uD83C\uDFFD\u200D\uD83D\uDE92" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "woman firefighter: medium-dark skin tone", "code": "\uD83D\uDC69\uD83C\uDFFE\u200D\uD83D\uDE92" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "woman firefighter: dark skin tone", "code": "\uD83D\uDC69\uD83C\uDFFF\u200D\uD83D\uDE92" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "police officer", "code": "\uD83D\uDC6E" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "police officer: light skin tone", "code": "\uD83D\uDC6E\uD83C\uDFFB" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "police officer: medium-light skin tone", "code": "\uD83D\uDC6E\uD83C\uDFFC" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "police officer: medium skin tone", "code": "\uD83D\uDC6E\uD83C\uDFFD" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "police officer: medium-dark skin tone", "code": "\uD83D\uDC6E\uD83C\uDFFE" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "police officer: dark skin tone", "code": "\uD83D\uDC6E\uD83C\uDFFF" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "man police officer", "code": "\uD83D\uDC6E\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "man police officer: light skin tone", "code": "\uD83D\uDC6E\uD83C\uDFFB\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "man police officer: medium-light skin tone", "code": "\uD83D\uDC6E\uD83C\uDFFC\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "man police officer: medium skin tone", "code": "\uD83D\uDC6E\uD83C\uDFFD\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "man police officer: medium-dark skin tone", "code": "\uD83D\uDC6E\uD83C\uDFFE\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "man police officer: dark skin tone", "code": "\uD83D\uDC6E\uD83C\uDFFF\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "woman police officer", "code": "\uD83D\uDC6E\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "woman police officer: light skin tone", "code": "\uD83D\uDC6E\uD83C\uDFFB\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "woman police officer: medium-light skin tone", "code": "\uD83D\uDC6E\uD83C\uDFFC\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "woman police officer: medium skin tone", "code": "\uD83D\uDC6E\uD83C\uDFFD\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "woman police officer: medium-dark skin tone", "code": "\uD83D\uDC6E\uD83C\uDFFE\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "woman police officer: dark skin tone", "code": "\uD83D\uDC6E\uD83C\uDFFF\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "detective", "code": "\uD83D\uDD75\uFE0F" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "detective: light skin tone", "code": "\uD83D\uDD75\uD83C\uDFFB" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "detective: medium-light skin tone", "code": "\uD83D\uDD75\uD83C\uDFFC" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "detective: medium skin tone", "code": "\uD83D\uDD75\uD83C\uDFFD" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "detective: medium-dark skin tone", "code": "\uD83D\uDD75\uD83C\uDFFE" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "detective: dark skin tone", "code": "\uD83D\uDD75\uD83C\uDFFF" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "man detective", "code": "\uD83D\uDD75\uFE0F\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "man detective: light skin tone", "code": "\uD83D\uDD75\uD83C\uDFFB\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "man detective: medium-light skin tone", "code": "\uD83D\uDD75\uD83C\uDFFC\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "man detective: medium skin tone", "code": "\uD83D\uDD75\uD83C\uDFFD\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "man detective: medium-dark skin tone", "code": "\uD83D\uDD75\uD83C\uDFFE\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "man detective: dark skin tone", "code": "\uD83D\uDD75\uD83C\uDFFF\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "woman detective", "code": "\uD83D\uDD75\uFE0F\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "woman detective: light skin tone", "code": "\uD83D\uDD75\uD83C\uDFFB\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "woman detective: medium-light skin tone", "code": "\uD83D\uDD75\uD83C\uDFFC\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "woman detective: medium skin tone", "code": "\uD83D\uDD75\uD83C\uDFFD\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "woman detective: medium-dark skin tone", "code": "\uD83D\uDD75\uD83C\uDFFE\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "woman detective: dark skin tone", "code": "\uD83D\uDD75\uD83C\uDFFF\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "guard", "code": "\uD83D\uDC82" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "guard: light skin tone", "code": "\uD83D\uDC82\uD83C\uDFFB" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "guard: medium-light skin tone", "code": "\uD83D\uDC82\uD83C\uDFFC" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "guard: medium skin tone", "code": "\uD83D\uDC82\uD83C\uDFFD" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "guard: medium-dark skin tone", "code": "\uD83D\uDC82\uD83C\uDFFE" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "guard: dark skin tone", "code": "\uD83D\uDC82\uD83C\uDFFF" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "man guard", "code": "\uD83D\uDC82\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "man guard: light skin tone", "code": "\uD83D\uDC82\uD83C\uDFFB\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "man guard: medium-light skin tone", "code": "\uD83D\uDC82\uD83C\uDFFC\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "man guard: medium skin tone", "code": "\uD83D\uDC82\uD83C\uDFFD\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "man guard: medium-dark skin tone", "code": "\uD83D\uDC82\uD83C\uDFFE\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "man guard: dark skin tone", "code": "\uD83D\uDC82\uD83C\uDFFF\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "woman guard", "code": "\uD83D\uDC82\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "woman guard: light skin tone", "code": "\uD83D\uDC82\uD83C\uDFFB\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "woman guard: medium-light skin tone", "code": "\uD83D\uDC82\uD83C\uDFFC\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "woman guard: medium skin tone", "code": "\uD83D\uDC82\uD83C\uDFFD\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "woman guard: medium-dark skin tone", "code": "\uD83D\uDC82\uD83C\uDFFE\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "woman guard: dark skin tone", "code": "\uD83D\uDC82\uD83C\uDFFF\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "construction worker", "code": "\uD83D\uDC77" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "construction worker: light skin tone", "code": "\uD83D\uDC77\uD83C\uDFFB" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "construction worker: medium-light skin tone", "code": "\uD83D\uDC77\uD83C\uDFFC" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "construction worker: medium skin tone", "code": "\uD83D\uDC77\uD83C\uDFFD" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "construction worker: medium-dark skin tone", "code": "\uD83D\uDC77\uD83C\uDFFE" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "construction worker: dark skin tone", "code": "\uD83D\uDC77\uD83C\uDFFF" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "man construction worker", "code": "\uD83D\uDC77\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "man construction worker: light skin tone", "code": "\uD83D\uDC77\uD83C\uDFFB\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "man construction worker: medium-light skin tone", "code": "\uD83D\uDC77\uD83C\uDFFC\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "man construction worker: medium skin tone", "code": "\uD83D\uDC77\uD83C\uDFFD\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "man construction worker: medium-dark skin tone", "code": "\uD83D\uDC77\uD83C\uDFFE\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "man construction worker: dark skin tone", "code": "\uD83D\uDC77\uD83C\uDFFF\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "woman construction worker", "code": "\uD83D\uDC77\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "woman construction worker: light skin tone", "code": "\uD83D\uDC77\uD83C\uDFFB\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "woman construction worker: medium-light skin tone", "code": "\uD83D\uDC77\uD83C\uDFFC\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "woman construction worker: medium skin tone", "code": "\uD83D\uDC77\uD83C\uDFFD\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "woman construction worker: medium-dark skin tone", "code": "\uD83D\uDC77\uD83C\uDFFE\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "woman construction worker: dark skin tone", "code": "\uD83D\uDC77\uD83C\uDFFF\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "prince", "code": "\uD83E\uDD34" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "prince: light skin tone", "code": "\uD83E\uDD34\uD83C\uDFFB" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "prince: medium-light skin tone", "code": "\uD83E\uDD34\uD83C\uDFFC" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "prince: medium skin tone", "code": "\uD83E\uDD34\uD83C\uDFFD" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "prince: medium-dark skin tone", "code": "\uD83E\uDD34\uD83C\uDFFE" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "prince: dark skin tone", "code": "\uD83E\uDD34\uD83C\uDFFF" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "princess", "code": "\uD83D\uDC78" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "princess: light skin tone", "code": "\uD83D\uDC78\uD83C\uDFFB" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "princess: medium-light skin tone", "code": "\uD83D\uDC78\uD83C\uDFFC" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "princess: medium skin tone", "code": "\uD83D\uDC78\uD83C\uDFFD" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "princess: medium-dark skin tone", "code": "\uD83D\uDC78\uD83C\uDFFE" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "princess: dark skin tone", "code": "\uD83D\uDC78\uD83C\uDFFF" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "person wearing turban", "code": "\uD83D\uDC73" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "person wearing turban: light skin tone", "code": "\uD83D\uDC73\uD83C\uDFFB" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "person wearing turban: medium-light skin tone", "code": "\uD83D\uDC73\uD83C\uDFFC" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "person wearing turban: medium skin tone", "code": "\uD83D\uDC73\uD83C\uDFFD" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "person wearing turban: medium-dark skin tone", "code": "\uD83D\uDC73\uD83C\uDFFE" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "person wearing turban: dark skin tone", "code": "\uD83D\uDC73\uD83C\uDFFF" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "man wearing turban", "code": "\uD83D\uDC73\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "man wearing turban: light skin tone", "code": "\uD83D\uDC73\uD83C\uDFFB\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "man wearing turban: medium-light skin tone", "code": "\uD83D\uDC73\uD83C\uDFFC\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "man wearing turban: medium skin tone", "code": "\uD83D\uDC73\uD83C\uDFFD\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "man wearing turban: medium-dark skin tone", "code": "\uD83D\uDC73\uD83C\uDFFE\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "man wearing turban: dark skin tone", "code": "\uD83D\uDC73\uD83C\uDFFF\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "woman wearing turban", "code": "\uD83D\uDC73\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "woman wearing turban: light skin tone", "code": "\uD83D\uDC73\uD83C\uDFFB\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "woman wearing turban: medium-light skin tone", "code": "\uD83D\uDC73\uD83C\uDFFC\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "woman wearing turban: medium skin tone", "code": "\uD83D\uDC73\uD83C\uDFFD\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "woman wearing turban: medium-dark skin tone", "code": "\uD83D\uDC73\uD83C\uDFFE\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "woman wearing turban: dark skin tone", "code": "\uD83D\uDC73\uD83C\uDFFF\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "man with Chinese cap", "code": "\uD83D\uDC72" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "man with Chinese cap: light skin tone", "code": "\uD83D\uDC72\uD83C\uDFFB" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "man with Chinese cap: medium-light skin tone", "code": "\uD83D\uDC72\uD83C\uDFFC" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "man with Chinese cap: medium skin tone", "code": "\uD83D\uDC72\uD83C\uDFFD" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "man with Chinese cap: medium-dark skin tone", "code": "\uD83D\uDC72\uD83C\uDFFE" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "man with Chinese cap: dark skin tone", "code": "\uD83D\uDC72\uD83C\uDFFF" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "woman with headscarf", "code": "\uD83E\uDDD5" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "woman with headscarf: light skin tone", "code": "\uD83E\uDDD5\uD83C\uDFFB" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "woman with headscarf: medium-light skin tone", "code": "\uD83E\uDDD5\uD83C\uDFFC" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "woman with headscarf: medium skin tone", "code": "\uD83E\uDDD5\uD83C\uDFFD" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "woman with headscarf: medium-dark skin tone", "code": "\uD83E\uDDD5\uD83C\uDFFE" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "woman with headscarf: dark skin tone", "code": "\uD83E\uDDD5\uD83C\uDFFF" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "bearded person", "code": "\uD83E\uDDD4" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "bearded person: light skin tone", "code": "\uD83E\uDDD4\uD83C\uDFFB" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "bearded person: medium-light skin tone", "code": "\uD83E\uDDD4\uD83C\uDFFC" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "bearded person: medium skin tone", "code": "\uD83E\uDDD4\uD83C\uDFFD" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "bearded person: medium-dark skin tone", "code": "\uD83E\uDDD4\uD83C\uDFFE" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "bearded person: dark skin tone", "code": "\uD83E\uDDD4\uD83C\uDFFF" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "blond-haired person", "code": "\uD83D\uDC71" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "blond-haired person: light skin tone", "code": "\uD83D\uDC71\uD83C\uDFFB" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "blond-haired person: medium-light skin tone", "code": "\uD83D\uDC71\uD83C\uDFFC" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "blond-haired person: medium skin tone", "code": "\uD83D\uDC71\uD83C\uDFFD" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "blond-haired person: medium-dark skin tone", "code": "\uD83D\uDC71\uD83C\uDFFE" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "blond-haired person: dark skin tone", "code": "\uD83D\uDC71\uD83C\uDFFF" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "blond-haired man", "code": "\uD83D\uDC71\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "blond-haired man: light skin tone", "code": "\uD83D\uDC71\uD83C\uDFFB\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "blond-haired man: medium-light skin tone", "code": "\uD83D\uDC71\uD83C\uDFFC\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "blond-haired man: medium skin tone", "code": "\uD83D\uDC71\uD83C\uDFFD\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "blond-haired man: medium-dark skin tone", "code": "\uD83D\uDC71\uD83C\uDFFE\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "blond-haired man: dark skin tone", "code": "\uD83D\uDC71\uD83C\uDFFF\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "blond-haired woman", "code": "\uD83D\uDC71\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "blond-haired woman: light skin tone", "code": "\uD83D\uDC71\uD83C\uDFFB\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "blond-haired woman: medium-light skin tone", "code": "\uD83D\uDC71\uD83C\uDFFC\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "blond-haired woman: medium skin tone", "code": "\uD83D\uDC71\uD83C\uDFFD\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "blond-haired woman: medium-dark skin tone", "code": "\uD83D\uDC71\uD83C\uDFFE\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "blond-haired woman: dark skin tone", "code": "\uD83D\uDC71\uD83C\uDFFF\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "man, red haired", "code": "\uD83D\uDC68\u200D\uD83E\uDDB0" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "man, red haired: light skin tone", "code": "\uD83D\uDC68\uD83C\uDFFB\u200D\uD83E\uDDB0" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "man, red haired: medium-light skin tone", "code": "\uD83D\uDC68\uD83C\uDFFC\u200D\uD83E\uDDB0" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "man, red haired: medium skin tone", "code": "\uD83D\uDC68\uD83C\uDFFD\u200D\uD83E\uDDB0" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "man, red haired: medium-dark skin tone", "code": "\uD83D\uDC68\uD83C\uDFFE\u200D\uD83E\uDDB0" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "man, red haired: dark skin tone", "code": "\uD83D\uDC68\uD83C\uDFFF\u200D\uD83E\uDDB0" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "woman, red haired", "code": "\uD83D\uDC69\u200D\uD83E\uDDB0" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "woman, red haired: light skin tone", "code": "\uD83D\uDC69\uD83C\uDFFB\u200D\uD83E\uDDB0" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "woman, red haired: medium-light skin tone", "code": "\uD83D\uDC69\uD83C\uDFFC\u200D\uD83E\uDDB0" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "woman, red haired: medium skin tone", "code": "\uD83D\uDC69\uD83C\uDFFD\u200D\uD83E\uDDB0" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "woman, red haired: medium-dark skin tone", "code": "\uD83D\uDC69\uD83C\uDFFE\u200D\uD83E\uDDB0" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "woman, red haired: dark skin tone", "code": "\uD83D\uDC69\uD83C\uDFFF\u200D\uD83E\uDDB0" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "man, curly haired", "code": "\uD83D\uDC68\u200D\uD83E\uDDB1" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "man, curly haired: light skin tone", "code": "\uD83D\uDC68\uD83C\uDFFB\u200D\uD83E\uDDB1" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "man, curly haired: medium-light skin tone", "code": "\uD83D\uDC68\uD83C\uDFFC\u200D\uD83E\uDDB1" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "man, curly haired: medium skin tone", "code": "\uD83D\uDC68\uD83C\uDFFD\u200D\uD83E\uDDB1" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "man, curly haired: medium-dark skin tone", "code": "\uD83D\uDC68\uD83C\uDFFE\u200D\uD83E\uDDB1" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "man, curly haired: dark skin tone", "code": "\uD83D\uDC68\uD83C\uDFFF\u200D\uD83E\uDDB1" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "woman, curly haired", "code": "\uD83D\uDC69\u200D\uD83E\uDDB1" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "woman, curly haired: light skin tone", "code": "\uD83D\uDC69\uD83C\uDFFB\u200D\uD83E\uDDB1" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "woman, curly haired: medium-light skin tone", "code": "\uD83D\uDC69\uD83C\uDFFC\u200D\uD83E\uDDB1" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "woman, curly haired: medium skin tone", "code": "\uD83D\uDC69\uD83C\uDFFD\u200D\uD83E\uDDB1" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "woman, curly haired: medium-dark skin tone", "code": "\uD83D\uDC69\uD83C\uDFFE\u200D\uD83E\uDDB1" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "woman, curly haired: dark skin tone", "code": "\uD83D\uDC69\uD83C\uDFFF\u200D\uD83E\uDDB1" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "man, bald", "code": "\uD83D\uDC68\u200D\uD83E\uDDB2" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "man, bald: light skin tone", "code": "\uD83D\uDC68\uD83C\uDFFB\u200D\uD83E\uDDB2" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "man, bald: medium-light skin tone", "code": "\uD83D\uDC68\uD83C\uDFFC\u200D\uD83E\uDDB2" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "man, bald: medium skin tone", "code": "\uD83D\uDC68\uD83C\uDFFD\u200D\uD83E\uDDB2" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "man, bald: medium-dark skin tone", "code": "\uD83D\uDC68\uD83C\uDFFE\u200D\uD83E\uDDB2" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "man, bald: dark skin tone", "code": "\uD83D\uDC68\uD83C\uDFFF\u200D\uD83E\uDDB2" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "woman, bald", "code": "\uD83D\uDC69\u200D\uD83E\uDDB2" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "woman, bald: light skin tone", "code": "\uD83D\uDC69\uD83C\uDFFB\u200D\uD83E\uDDB2" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "woman, bald: medium-light skin tone", "code": "\uD83D\uDC69\uD83C\uDFFC\u200D\uD83E\uDDB2" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "woman, bald: medium skin tone", "code": "\uD83D\uDC69\uD83C\uDFFD\u200D\uD83E\uDDB2" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "woman, bald: medium-dark skin tone", "code": "\uD83D\uDC69\uD83C\uDFFE\u200D\uD83E\uDDB2" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "woman, bald: dark skin tone", "code": "\uD83D\uDC69\uD83C\uDFFF\u200D\uD83E\uDDB2" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "man, white haired", "code": "\uD83D\uDC68\u200D\uD83E\uDDB3" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "man, white haired: light skin tone", "code": "\uD83D\uDC68\uD83C\uDFFB\u200D\uD83E\uDDB3" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "man, white haired: medium-light skin tone", "code": "\uD83D\uDC68\uD83C\uDFFC\u200D\uD83E\uDDB3" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "man, white haired: medium skin tone", "code": "\uD83D\uDC68\uD83C\uDFFD\u200D\uD83E\uDDB3" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "man, white haired: medium-dark skin tone", "code": "\uD83D\uDC68\uD83C\uDFFE\u200D\uD83E\uDDB3" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "man, white haired: dark skin tone", "code": "\uD83D\uDC68\uD83C\uDFFF\u200D\uD83E\uDDB3" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "woman, white haired", "code": "\uD83D\uDC69\u200D\uD83E\uDDB3" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "woman, white haired: light skin tone", "code": "\uD83D\uDC69\uD83C\uDFFB\u200D\uD83E\uDDB3" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "woman, white haired: medium-light skin tone", "code": "\uD83D\uDC69\uD83C\uDFFC\u200D\uD83E\uDDB3" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "woman, white haired: medium skin tone", "code": "\uD83D\uDC69\uD83C\uDFFD\u200D\uD83E\uDDB3" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "woman, white haired: medium-dark skin tone", "code": "\uD83D\uDC69\uD83C\uDFFE\u200D\uD83E\uDDB3" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "woman, white haired: dark skin tone", "code": "\uD83D\uDC69\uD83C\uDFFF\u200D\uD83E\uDDB3" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "man in tuxedo", "code": "\uD83E\uDD35" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "man in tuxedo: light skin tone", "code": "\uD83E\uDD35\uD83C\uDFFB" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "man in tuxedo: medium-light skin tone", "code": "\uD83E\uDD35\uD83C\uDFFC" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "man in tuxedo: medium skin tone", "code": "\uD83E\uDD35\uD83C\uDFFD" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "man in tuxedo: medium-dark skin tone", "code": "\uD83E\uDD35\uD83C\uDFFE" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "man in tuxedo: dark skin tone", "code": "\uD83E\uDD35\uD83C\uDFFF" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "bride with veil", "code": "\uD83D\uDC70" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "bride with veil: light skin tone", "code": "\uD83D\uDC70\uD83C\uDFFB" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "bride with veil: medium-light skin tone", "code": "\uD83D\uDC70\uD83C\uDFFC" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "bride with veil: medium skin tone", "code": "\uD83D\uDC70\uD83C\uDFFD" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "bride with veil: medium-dark skin tone", "code": "\uD83D\uDC70\uD83C\uDFFE" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "bride with veil: dark skin tone", "code": "\uD83D\uDC70\uD83C\uDFFF" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "pregnant woman", "code": "\uD83E\uDD30" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "pregnant woman: light skin tone", "code": "\uD83E\uDD30\uD83C\uDFFB" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "pregnant woman: medium-light skin tone", "code": "\uD83E\uDD30\uD83C\uDFFC" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "pregnant woman: medium skin tone", "code": "\uD83E\uDD30\uD83C\uDFFD" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "pregnant woman: medium-dark skin tone", "code": "\uD83E\uDD30\uD83C\uDFFE" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "pregnant woman: dark skin tone", "code": "\uD83E\uDD30\uD83C\uDFFF" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "breast-feeding", "code": "\uD83E\uDD31" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "breast-feeding: light skin tone", "code": "\uD83E\uDD31\uD83C\uDFFB" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "breast-feeding: medium-light skin tone", "code": "\uD83E\uDD31\uD83C\uDFFC" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "breast-feeding: medium skin tone", "code": "\uD83E\uDD31\uD83C\uDFFD" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "breast-feeding: medium-dark skin tone", "code": "\uD83E\uDD31\uD83C\uDFFE" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "breast-feeding: dark skin tone", "code": "\uD83E\uDD31\uD83C\uDFFF" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "baby angel", "code": "\uD83D\uDC7C" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "baby angel: light skin tone", "code": "\uD83D\uDC7C\uD83C\uDFFB" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "baby angel: medium-light skin tone", "code": "\uD83D\uDC7C\uD83C\uDFFC" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "baby angel: medium skin tone", "code": "\uD83D\uDC7C\uD83C\uDFFD" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "baby angel: medium-dark skin tone", "code": "\uD83D\uDC7C\uD83C\uDFFE" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "baby angel: dark skin tone", "code": "\uD83D\uDC7C\uD83C\uDFFF" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "Santa Claus", "code": "\uD83C\uDF85" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "Santa Claus: light skin tone", "code": "\uD83C\uDF85\uD83C\uDFFB" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "Santa Claus: medium-light skin tone", "code": "\uD83C\uDF85\uD83C\uDFFC" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "Santa Claus: medium skin tone", "code": "\uD83C\uDF85\uD83C\uDFFD" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "Santa Claus: medium-dark skin tone", "code": "\uD83C\uDF85\uD83C\uDFFE" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "Santa Claus: dark skin tone", "code": "\uD83C\uDF85\uD83C\uDFFF" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "Mrs. Claus", "code": "\uD83E\uDD36" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "Mrs. Claus: light skin tone", "code": "\uD83E\uDD36\uD83C\uDFFB" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "Mrs. Claus: medium-light skin tone", "code": "\uD83E\uDD36\uD83C\uDFFC" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "Mrs. Claus: medium skin tone", "code": "\uD83E\uDD36\uD83C\uDFFD" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "Mrs. Claus: medium-dark skin tone", "code": "\uD83E\uDD36\uD83C\uDFFE" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "Mrs. Claus: dark skin tone", "code": "\uD83E\uDD36\uD83C\uDFFF" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "superhero", "code": "\uD83E\uDDB8" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "superhero: light skin tone", "code": "\uD83E\uDDB8\uD83C\uDFFB" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "superhero: medium-light skin tone", "code": "\uD83E\uDDB8\uD83C\uDFFC" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "superhero: medium skin tone", "code": "\uD83E\uDDB8\uD83C\uDFFD" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "superhero: medium-dark skin tone", "code": "\uD83E\uDDB8\uD83C\uDFFE" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "superhero: dark skin tone", "code": "\uD83E\uDDB8\uD83C\uDFFF" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "woman superhero", "code": "\uD83E\uDDB8\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "woman superhero: light skin tone", "code": "\uD83E\uDDB8\uD83C\uDFFB\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "woman superhero: medium-light skin tone", "code": "\uD83E\uDDB8\uD83C\uDFFC\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "woman superhero: medium skin tone", "code": "\uD83E\uDDB8\uD83C\uDFFD\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "woman superhero: medium-dark skin tone", "code": "\uD83E\uDDB8\uD83C\uDFFE\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "woman superhero: dark skin tone", "code": "\uD83E\uDDB8\uD83C\uDFFF\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "man superhero", "code": "\uD83E\uDDB8\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "man superhero: light skin tone", "code": "\uD83E\uDDB8\uD83C\uDFFB\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "man superhero: medium-light skin tone", "code": "\uD83E\uDDB8\uD83C\uDFFC\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "man superhero: medium skin tone", "code": "\uD83E\uDDB8\uD83C\uDFFD\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "man superhero: medium-dark skin tone", "code": "\uD83E\uDDB8\uD83C\uDFFE\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "man superhero: dark skin tone", "code": "\uD83E\uDDB8\uD83C\uDFFF\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "supervillain", "code": "\uD83E\uDDB9" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "supervillain: light skin tone", "code": "\uD83E\uDDB9\uD83C\uDFFB" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "supervillain: medium-light skin tone", "code": "\uD83E\uDDB9\uD83C\uDFFC" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "supervillain: medium skin tone", "code": "\uD83E\uDDB9\uD83C\uDFFD" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "supervillain: medium-dark skin tone", "code": "\uD83E\uDDB9\uD83C\uDFFE" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "supervillain: dark skin tone", "code": "\uD83E\uDDB9\uD83C\uDFFF" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "woman supervillain", "code": "\uD83E\uDDB9\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "woman supervillain: light skin tone", "code": "\uD83E\uDDB9\uD83C\uDFFB\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "woman supervillain: medium-light skin tone", "code": "\uD83E\uDDB9\uD83C\uDFFC\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "woman supervillain: medium skin tone", "code": "\uD83E\uDDB9\uD83C\uDFFD\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "woman supervillain: medium-dark skin tone", "code": "\uD83E\uDDB9\uD83C\uDFFE\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "woman supervillain: dark skin tone", "code": "\uD83E\uDDB9\uD83C\uDFFF\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "man supervillain", "code": "\uD83E\uDDB9\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "man supervillain: light skin tone", "code": "\uD83E\uDDB9\uD83C\uDFFB\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "man supervillain: medium-light skin tone", "code": "\uD83E\uDDB9\uD83C\uDFFC\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "man supervillain: medium skin tone", "code": "\uD83E\uDDB9\uD83C\uDFFD\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "man supervillain: medium-dark skin tone", "code": "\uD83E\uDDB9\uD83C\uDFFE\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "man supervillain: dark skin tone", "code": "\uD83E\uDDB9\uD83C\uDFFF\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "mage", "code": "\uD83E\uDDD9" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "mage: light skin tone", "code": "\uD83E\uDDD9\uD83C\uDFFB" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "mage: medium-light skin tone", "code": "\uD83E\uDDD9\uD83C\uDFFC" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "mage: medium skin tone", "code": "\uD83E\uDDD9\uD83C\uDFFD" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "mage: medium-dark skin tone", "code": "\uD83E\uDDD9\uD83C\uDFFE" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "mage: dark skin tone", "code": "\uD83E\uDDD9\uD83C\uDFFF" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "woman mage", "code": "\uD83E\uDDD9\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "woman mage: light skin tone", "code": "\uD83E\uDDD9\uD83C\uDFFB\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "woman mage: medium-light skin tone", "code": "\uD83E\uDDD9\uD83C\uDFFC\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "woman mage: medium skin tone", "code": "\uD83E\uDDD9\uD83C\uDFFD\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "woman mage: medium-dark skin tone", "code": "\uD83E\uDDD9\uD83C\uDFFE\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "woman mage: dark skin tone", "code": "\uD83E\uDDD9\uD83C\uDFFF\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "man mage", "code": "\uD83E\uDDD9\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "man mage: light skin tone", "code": "\uD83E\uDDD9\uD83C\uDFFB\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "man mage: medium-light skin tone", "code": "\uD83E\uDDD9\uD83C\uDFFC\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "man mage: medium skin tone", "code": "\uD83E\uDDD9\uD83C\uDFFD\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "man mage: medium-dark skin tone", "code": "\uD83E\uDDD9\uD83C\uDFFE\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "man mage: dark skin tone", "code": "\uD83E\uDDD9\uD83C\uDFFF\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "fairy", "code": "\uD83E\uDDDA" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "fairy: light skin tone", "code": "\uD83E\uDDDA\uD83C\uDFFB" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "fairy: medium-light skin tone", "code": "\uD83E\uDDDA\uD83C\uDFFC" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "fairy: medium skin tone", "code": "\uD83E\uDDDA\uD83C\uDFFD" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "fairy: medium-dark skin tone", "code": "\uD83E\uDDDA\uD83C\uDFFE" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "fairy: dark skin tone", "code": "\uD83E\uDDDA\uD83C\uDFFF" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "woman fairy", "code": "\uD83E\uDDDA\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "woman fairy: light skin tone", "code": "\uD83E\uDDDA\uD83C\uDFFB\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "woman fairy: medium-light skin tone", "code": "\uD83E\uDDDA\uD83C\uDFFC\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "woman fairy: medium skin tone", "code": "\uD83E\uDDDA\uD83C\uDFFD\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "woman fairy: medium-dark skin tone", "code": "\uD83E\uDDDA\uD83C\uDFFE\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "woman fairy: dark skin tone", "code": "\uD83E\uDDDA\uD83C\uDFFF\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "man fairy", "code": "\uD83E\uDDDA\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "man fairy: light skin tone", "code": "\uD83E\uDDDA\uD83C\uDFFB\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "man fairy: medium-light skin tone", "code": "\uD83E\uDDDA\uD83C\uDFFC\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "man fairy: medium skin tone", "code": "\uD83E\uDDDA\uD83C\uDFFD\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "man fairy: medium-dark skin tone", "code": "\uD83E\uDDDA\uD83C\uDFFE\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "man fairy: dark skin tone", "code": "\uD83E\uDDDA\uD83C\uDFFF\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "vampire", "code": "\uD83E\uDDDB" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "vampire: light skin tone", "code": "\uD83E\uDDDB\uD83C\uDFFB" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "vampire: medium-light skin tone", "code": "\uD83E\uDDDB\uD83C\uDFFC" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "vampire: medium skin tone", "code": "\uD83E\uDDDB\uD83C\uDFFD" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "vampire: medium-dark skin tone", "code": "\uD83E\uDDDB\uD83C\uDFFE" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "vampire: dark skin tone", "code": "\uD83E\uDDDB\uD83C\uDFFF" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "woman vampire", "code": "\uD83E\uDDDB\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "woman vampire: light skin tone", "code": "\uD83E\uDDDB\uD83C\uDFFB\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "woman vampire: medium-light skin tone", "code": "\uD83E\uDDDB\uD83C\uDFFC\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "woman vampire: medium skin tone", "code": "\uD83E\uDDDB\uD83C\uDFFD\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "woman vampire: medium-dark skin tone", "code": "\uD83E\uDDDB\uD83C\uDFFE\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "woman vampire: dark skin tone", "code": "\uD83E\uDDDB\uD83C\uDFFF\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "man vampire", "code": "\uD83E\uDDDB\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "man vampire: light skin tone", "code": "\uD83E\uDDDB\uD83C\uDFFB\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "man vampire: medium-light skin tone", "code": "\uD83E\uDDDB\uD83C\uDFFC\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "man vampire: medium skin tone", "code": "\uD83E\uDDDB\uD83C\uDFFD\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "man vampire: medium-dark skin tone", "code": "\uD83E\uDDDB\uD83C\uDFFE\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "man vampire: dark skin tone", "code": "\uD83E\uDDDB\uD83C\uDFFF\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "merperson", "code": "\uD83E\uDDDC" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "merperson: light skin tone", "code": "\uD83E\uDDDC\uD83C\uDFFB" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "merperson: medium-light skin tone", "code": "\uD83E\uDDDC\uD83C\uDFFC" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "merperson: medium skin tone", "code": "\uD83E\uDDDC\uD83C\uDFFD" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "merperson: medium-dark skin tone", "code": "\uD83E\uDDDC\uD83C\uDFFE" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "merperson: dark skin tone", "code": "\uD83E\uDDDC\uD83C\uDFFF" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "mermaid", "code": "\uD83E\uDDDC\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "mermaid: light skin tone", "code": "\uD83E\uDDDC\uD83C\uDFFB\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "mermaid: medium-light skin tone", "code": "\uD83E\uDDDC\uD83C\uDFFC\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "mermaid: medium skin tone", "code": "\uD83E\uDDDC\uD83C\uDFFD\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "mermaid: medium-dark skin tone", "code": "\uD83E\uDDDC\uD83C\uDFFE\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "mermaid: dark skin tone", "code": "\uD83E\uDDDC\uD83C\uDFFF\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "merman", "code": "\uD83E\uDDDC\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "merman: light skin tone", "code": "\uD83E\uDDDC\uD83C\uDFFB\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "merman: medium-light skin tone", "code": "\uD83E\uDDDC\uD83C\uDFFC\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "merman: medium skin tone", "code": "\uD83E\uDDDC\uD83C\uDFFD\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "merman: medium-dark skin tone", "code": "\uD83E\uDDDC\uD83C\uDFFE\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "merman: dark skin tone", "code": "\uD83E\uDDDC\uD83C\uDFFF\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "elf", "code": "\uD83E\uDDDD" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "elf: light skin tone", "code": "\uD83E\uDDDD\uD83C\uDFFB" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "elf: medium-light skin tone", "code": "\uD83E\uDDDD\uD83C\uDFFC" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "elf: medium skin tone", "code": "\uD83E\uDDDD\uD83C\uDFFD" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "elf: medium-dark skin tone", "code": "\uD83E\uDDDD\uD83C\uDFFE" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "elf: dark skin tone", "code": "\uD83E\uDDDD\uD83C\uDFFF" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "woman elf", "code": "\uD83E\uDDDD\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "woman elf: light skin tone", "code": "\uD83E\uDDDD\uD83C\uDFFB\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "woman elf: medium-light skin tone", "code": "\uD83E\uDDDD\uD83C\uDFFC\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "woman elf: medium skin tone", "code": "\uD83E\uDDDD\uD83C\uDFFD\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "woman elf: medium-dark skin tone", "code": "\uD83E\uDDDD\uD83C\uDFFE\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "woman elf: dark skin tone", "code": "\uD83E\uDDDD\uD83C\uDFFF\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "man elf", "code": "\uD83E\uDDDD\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "man elf: light skin tone", "code": "\uD83E\uDDDD\uD83C\uDFFB\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "man elf: medium-light skin tone", "code": "\uD83E\uDDDD\uD83C\uDFFC\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "man elf: medium skin tone", "code": "\uD83E\uDDDD\uD83C\uDFFD\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "man elf: medium-dark skin tone", "code": "\uD83E\uDDDD\uD83C\uDFFE\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "man elf: dark skin tone", "code": "\uD83E\uDDDD\uD83C\uDFFF\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "keyword": "genie", "code": "\uD83E\uDDDE" },
            { "category": "smileys_n_people", "keyword": "woman genie", "code": "\uD83E\uDDDE\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "keyword": "man genie", "code": "\uD83E\uDDDE\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "keyword": "zombie", "code": "\uD83E\uDDDF" },
            { "category": "smileys_n_people", "keyword": "woman zombie", "code": "\uD83E\uDDDF\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "keyword": "man zombie", "code": "\uD83E\uDDDF\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "person frowning", "code": "\uD83D\uDE4D" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "person frowning: light skin tone", "code": "\uD83D\uDE4D\uD83C\uDFFB" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "person frowning: medium-light skin tone", "code": "\uD83D\uDE4D\uD83C\uDFFC" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "person frowning: medium skin tone", "code": "\uD83D\uDE4D\uD83C\uDFFD" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "person frowning: medium-dark skin tone", "code": "\uD83D\uDE4D\uD83C\uDFFE" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "person frowning: dark skin tone", "code": "\uD83D\uDE4D\uD83C\uDFFF" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "man frowning", "code": "\uD83D\uDE4D\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "man frowning: light skin tone", "code": "\uD83D\uDE4D\uD83C\uDFFB\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "man frowning: medium-light skin tone", "code": "\uD83D\uDE4D\uD83C\uDFFC\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "man frowning: medium skin tone", "code": "\uD83D\uDE4D\uD83C\uDFFD\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "man frowning: medium-dark skin tone", "code": "\uD83D\uDE4D\uD83C\uDFFE\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "man frowning: dark skin tone", "code": "\uD83D\uDE4D\uD83C\uDFFF\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "woman frowning", "code": "\uD83D\uDE4D\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "woman frowning: light skin tone", "code": "\uD83D\uDE4D\uD83C\uDFFB\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "woman frowning: medium-light skin tone", "code": "\uD83D\uDE4D\uD83C\uDFFC\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "woman frowning: medium skin tone", "code": "\uD83D\uDE4D\uD83C\uDFFD\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "woman frowning: medium-dark skin tone", "code": "\uD83D\uDE4D\uD83C\uDFFE\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "woman frowning: dark skin tone", "code": "\uD83D\uDE4D\uD83C\uDFFF\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "person pouting", "code": "\uD83D\uDE4E" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "person pouting: light skin tone", "code": "\uD83D\uDE4E\uD83C\uDFFB" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "person pouting: medium-light skin tone", "code": "\uD83D\uDE4E\uD83C\uDFFC" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "person pouting: medium skin tone", "code": "\uD83D\uDE4E\uD83C\uDFFD" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "person pouting: medium-dark skin tone", "code": "\uD83D\uDE4E\uD83C\uDFFE" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "person pouting: dark skin tone", "code": "\uD83D\uDE4E\uD83C\uDFFF" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "man pouting", "code": "\uD83D\uDE4E\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "man pouting: light skin tone", "code": "\uD83D\uDE4E\uD83C\uDFFB\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "man pouting: medium-light skin tone", "code": "\uD83D\uDE4E\uD83C\uDFFC\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "man pouting: medium skin tone", "code": "\uD83D\uDE4E\uD83C\uDFFD\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "man pouting: medium-dark skin tone", "code": "\uD83D\uDE4E\uD83C\uDFFE\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "man pouting: dark skin tone", "code": "\uD83D\uDE4E\uD83C\uDFFF\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "woman pouting", "code": "\uD83D\uDE4E\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "woman pouting: light skin tone", "code": "\uD83D\uDE4E\uD83C\uDFFB\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "woman pouting: medium-light skin tone", "code": "\uD83D\uDE4E\uD83C\uDFFC\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "woman pouting: medium skin tone", "code": "\uD83D\uDE4E\uD83C\uDFFD\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "woman pouting: medium-dark skin tone", "code": "\uD83D\uDE4E\uD83C\uDFFE\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "woman pouting: dark skin tone", "code": "\uD83D\uDE4E\uD83C\uDFFF\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "person gesturing NO", "code": "\uD83D\uDE45" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "person gesturing NO: light skin tone", "code": "\uD83D\uDE45\uD83C\uDFFB" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "person gesturing NO: medium-light skin tone", "code": "\uD83D\uDE45\uD83C\uDFFC" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "person gesturing NO: medium skin tone", "code": "\uD83D\uDE45\uD83C\uDFFD" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "person gesturing NO: medium-dark skin tone", "code": "\uD83D\uDE45\uD83C\uDFFE" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "person gesturing NO: dark skin tone", "code": "\uD83D\uDE45\uD83C\uDFFF" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "man gesturing NO", "code": "\uD83D\uDE45\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "man gesturing NO: light skin tone", "code": "\uD83D\uDE45\uD83C\uDFFB\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "man gesturing NO: medium-light skin tone", "code": "\uD83D\uDE45\uD83C\uDFFC\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "man gesturing NO: medium skin tone", "code": "\uD83D\uDE45\uD83C\uDFFD\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "man gesturing NO: medium-dark skin tone", "code": "\uD83D\uDE45\uD83C\uDFFE\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "man gesturing NO: dark skin tone", "code": "\uD83D\uDE45\uD83C\uDFFF\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "woman gesturing NO", "code": "\uD83D\uDE45\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "woman gesturing NO: light skin tone", "code": "\uD83D\uDE45\uD83C\uDFFB\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "woman gesturing NO: medium-light skin tone", "code": "\uD83D\uDE45\uD83C\uDFFC\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "woman gesturing NO: medium skin tone", "code": "\uD83D\uDE45\uD83C\uDFFD\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "woman gesturing NO: medium-dark skin tone", "code": "\uD83D\uDE45\uD83C\uDFFE\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "woman gesturing NO: dark skin tone", "code": "\uD83D\uDE45\uD83C\uDFFF\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "person gesturing OK", "code": "\uD83D\uDE46" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "person gesturing OK: light skin tone", "code": "\uD83D\uDE46\uD83C\uDFFB" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "person gesturing OK: medium-light skin tone", "code": "\uD83D\uDE46\uD83C\uDFFC" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "person gesturing OK: medium skin tone", "code": "\uD83D\uDE46\uD83C\uDFFD" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "person gesturing OK: medium-dark skin tone", "code": "\uD83D\uDE46\uD83C\uDFFE" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "person gesturing OK: dark skin tone", "code": "\uD83D\uDE46\uD83C\uDFFF" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "man gesturing OK", "code": "\uD83D\uDE46\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "man gesturing OK: light skin tone", "code": "\uD83D\uDE46\uD83C\uDFFB\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "man gesturing OK: medium-light skin tone", "code": "\uD83D\uDE46\uD83C\uDFFC\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "man gesturing OK: medium skin tone", "code": "\uD83D\uDE46\uD83C\uDFFD\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "man gesturing OK: medium-dark skin tone", "code": "\uD83D\uDE46\uD83C\uDFFE\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "man gesturing OK: dark skin tone", "code": "\uD83D\uDE46\uD83C\uDFFF\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "woman gesturing OK", "code": "\uD83D\uDE46\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "woman gesturing OK: light skin tone", "code": "\uD83D\uDE46\uD83C\uDFFB\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "woman gesturing OK: medium-light skin tone", "code": "\uD83D\uDE46\uD83C\uDFFC\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "woman gesturing OK: medium skin tone", "code": "\uD83D\uDE46\uD83C\uDFFD\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "woman gesturing OK: medium-dark skin tone", "code": "\uD83D\uDE46\uD83C\uDFFE\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "woman gesturing OK: dark skin tone", "code": "\uD83D\uDE46\uD83C\uDFFF\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "person tipping hand", "code": "\uD83D\uDC81" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "person tipping hand: light skin tone", "code": "\uD83D\uDC81\uD83C\uDFFB" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "person tipping hand: medium-light skin tone", "code": "\uD83D\uDC81\uD83C\uDFFC" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "person tipping hand: medium skin tone", "code": "\uD83D\uDC81\uD83C\uDFFD" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "person tipping hand: medium-dark skin tone", "code": "\uD83D\uDC81\uD83C\uDFFE" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "person tipping hand: dark skin tone", "code": "\uD83D\uDC81\uD83C\uDFFF" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "man tipping hand", "code": "\uD83D\uDC81\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "man tipping hand: light skin tone", "code": "\uD83D\uDC81\uD83C\uDFFB\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "man tipping hand: medium-light skin tone", "code": "\uD83D\uDC81\uD83C\uDFFC\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "man tipping hand: medium skin tone", "code": "\uD83D\uDC81\uD83C\uDFFD\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "man tipping hand: medium-dark skin tone", "code": "\uD83D\uDC81\uD83C\uDFFE\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "man tipping hand: dark skin tone", "code": "\uD83D\uDC81\uD83C\uDFFF\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "woman tipping hand", "code": "\uD83D\uDC81\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "woman tipping hand: light skin tone", "code": "\uD83D\uDC81\uD83C\uDFFB\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "woman tipping hand: medium-light skin tone", "code": "\uD83D\uDC81\uD83C\uDFFC\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "woman tipping hand: medium skin tone", "code": "\uD83D\uDC81\uD83C\uDFFD\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "woman tipping hand: medium-dark skin tone", "code": "\uD83D\uDC81\uD83C\uDFFE\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "woman tipping hand: dark skin tone", "code": "\uD83D\uDC81\uD83C\uDFFF\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "person raising hand", "code": "\uD83D\uDE4B" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "person raising hand: light skin tone", "code": "\uD83D\uDE4B\uD83C\uDFFB" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "person raising hand: medium-light skin tone", "code": "\uD83D\uDE4B\uD83C\uDFFC" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "person raising hand: medium skin tone", "code": "\uD83D\uDE4B\uD83C\uDFFD" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "person raising hand: medium-dark skin tone", "code": "\uD83D\uDE4B\uD83C\uDFFE" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "person raising hand: dark skin tone", "code": "\uD83D\uDE4B\uD83C\uDFFF" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "man raising hand", "code": "\uD83D\uDE4B\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "man raising hand: light skin tone", "code": "\uD83D\uDE4B\uD83C\uDFFB\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "man raising hand: medium-light skin tone", "code": "\uD83D\uDE4B\uD83C\uDFFC\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "man raising hand: medium skin tone", "code": "\uD83D\uDE4B\uD83C\uDFFD\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "man raising hand: medium-dark skin tone", "code": "\uD83D\uDE4B\uD83C\uDFFE\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "man raising hand: dark skin tone", "code": "\uD83D\uDE4B\uD83C\uDFFF\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "woman raising hand", "code": "\uD83D\uDE4B\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "woman raising hand: light skin tone", "code": "\uD83D\uDE4B\uD83C\uDFFB\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "woman raising hand: medium-light skin tone", "code": "\uD83D\uDE4B\uD83C\uDFFC\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "woman raising hand: medium skin tone", "code": "\uD83D\uDE4B\uD83C\uDFFD\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "woman raising hand: medium-dark skin tone", "code": "\uD83D\uDE4B\uD83C\uDFFE\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "woman raising hand: dark skin tone", "code": "\uD83D\uDE4B\uD83C\uDFFF\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "person bowing", "code": "\uD83D\uDE47" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "person bowing: light skin tone", "code": "\uD83D\uDE47\uD83C\uDFFB" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "person bowing: medium-light skin tone", "code": "\uD83D\uDE47\uD83C\uDFFC" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "person bowing: medium skin tone", "code": "\uD83D\uDE47\uD83C\uDFFD" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "person bowing: medium-dark skin tone", "code": "\uD83D\uDE47\uD83C\uDFFE" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "person bowing: dark skin tone", "code": "\uD83D\uDE47\uD83C\uDFFF" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "man bowing", "code": "\uD83D\uDE47\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "man bowing: light skin tone", "code": "\uD83D\uDE47\uD83C\uDFFB\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "man bowing: medium-light skin tone", "code": "\uD83D\uDE47\uD83C\uDFFC\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "man bowing: medium skin tone", "code": "\uD83D\uDE47\uD83C\uDFFD\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "man bowing: medium-dark skin tone", "code": "\uD83D\uDE47\uD83C\uDFFE\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "man bowing: dark skin tone", "code": "\uD83D\uDE47\uD83C\uDFFF\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "woman bowing", "code": "\uD83D\uDE47\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "woman bowing: light skin tone", "code": "\uD83D\uDE47\uD83C\uDFFB\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "woman bowing: medium-light skin tone", "code": "\uD83D\uDE47\uD83C\uDFFC\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "woman bowing: medium skin tone", "code": "\uD83D\uDE47\uD83C\uDFFD\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "woman bowing: medium-dark skin tone", "code": "\uD83D\uDE47\uD83C\uDFFE\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "woman bowing: dark skin tone", "code": "\uD83D\uDE47\uD83C\uDFFF\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "person facepalming", "code": "\uD83E\uDD26" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "person facepalming: light skin tone", "code": "\uD83E\uDD26\uD83C\uDFFB" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "person facepalming: medium-light skin tone", "code": "\uD83E\uDD26\uD83C\uDFFC" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "person facepalming: medium skin tone", "code": "\uD83E\uDD26\uD83C\uDFFD" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "person facepalming: medium-dark skin tone", "code": "\uD83E\uDD26\uD83C\uDFFE" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "person facepalming: dark skin tone", "code": "\uD83E\uDD26\uD83C\uDFFF" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "man facepalming", "code": "\uD83E\uDD26\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "man facepalming: light skin tone", "code": "\uD83E\uDD26\uD83C\uDFFB\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "man facepalming: medium-light skin tone", "code": "\uD83E\uDD26\uD83C\uDFFC\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "man facepalming: medium skin tone", "code": "\uD83E\uDD26\uD83C\uDFFD\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "man facepalming: medium-dark skin tone", "code": "\uD83E\uDD26\uD83C\uDFFE\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "man facepalming: dark skin tone", "code": "\uD83E\uDD26\uD83C\uDFFF\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "woman facepalming", "code": "\uD83E\uDD26\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "woman facepalming: light skin tone", "code": "\uD83E\uDD26\uD83C\uDFFB\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "woman facepalming: medium-light skin tone", "code": "\uD83E\uDD26\uD83C\uDFFC\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "woman facepalming: medium skin tone", "code": "\uD83E\uDD26\uD83C\uDFFD\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "woman facepalming: medium-dark skin tone", "code": "\uD83E\uDD26\uD83C\uDFFE\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "woman facepalming: dark skin tone", "code": "\uD83E\uDD26\uD83C\uDFFF\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "person shrugging", "code": "\uD83E\uDD37" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "person shrugging: light skin tone", "code": "\uD83E\uDD37\uD83C\uDFFB" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "person shrugging: medium-light skin tone", "code": "\uD83E\uDD37\uD83C\uDFFC" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "person shrugging: medium skin tone", "code": "\uD83E\uDD37\uD83C\uDFFD" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "person shrugging: medium-dark skin tone", "code": "\uD83E\uDD37\uD83C\uDFFE" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "person shrugging: dark skin tone", "code": "\uD83E\uDD37\uD83C\uDFFF" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "man shrugging", "code": "\uD83E\uDD37\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "man shrugging: light skin tone", "code": "\uD83E\uDD37\uD83C\uDFFB\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "man shrugging: medium-light skin tone", "code": "\uD83E\uDD37\uD83C\uDFFC\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "man shrugging: medium skin tone", "code": "\uD83E\uDD37\uD83C\uDFFD\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "man shrugging: medium-dark skin tone", "code": "\uD83E\uDD37\uD83C\uDFFE\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "man shrugging: dark skin tone", "code": "\uD83E\uDD37\uD83C\uDFFF\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "woman shrugging", "code": "\uD83E\uDD37\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "woman shrugging: light skin tone", "code": "\uD83E\uDD37\uD83C\uDFFB\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "woman shrugging: medium-light skin tone", "code": "\uD83E\uDD37\uD83C\uDFFC\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "woman shrugging: medium skin tone", "code": "\uD83E\uDD37\uD83C\uDFFD\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "woman shrugging: medium-dark skin tone", "code": "\uD83E\uDD37\uD83C\uDFFE\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "woman shrugging: dark skin tone", "code": "\uD83E\uDD37\uD83C\uDFFF\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "person getting massage", "code": "\uD83D\uDC86" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "person getting massage: light skin tone", "code": "\uD83D\uDC86\uD83C\uDFFB" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "person getting massage: medium-light skin tone", "code": "\uD83D\uDC86\uD83C\uDFFC" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "person getting massage: medium skin tone", "code": "\uD83D\uDC86\uD83C\uDFFD" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "person getting massage: medium-dark skin tone", "code": "\uD83D\uDC86\uD83C\uDFFE" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "person getting massage: dark skin tone", "code": "\uD83D\uDC86\uD83C\uDFFF" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "man getting massage", "code": "\uD83D\uDC86\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "man getting massage: light skin tone", "code": "\uD83D\uDC86\uD83C\uDFFB\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "man getting massage: medium-light skin tone", "code": "\uD83D\uDC86\uD83C\uDFFC\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "man getting massage: medium skin tone", "code": "\uD83D\uDC86\uD83C\uDFFD\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "man getting massage: medium-dark skin tone", "code": "\uD83D\uDC86\uD83C\uDFFE\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "man getting massage: dark skin tone", "code": "\uD83D\uDC86\uD83C\uDFFF\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "woman getting massage", "code": "\uD83D\uDC86\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "woman getting massage: light skin tone", "code": "\uD83D\uDC86\uD83C\uDFFB\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "woman getting massage: medium-light skin tone", "code": "\uD83D\uDC86\uD83C\uDFFC\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "woman getting massage: medium skin tone", "code": "\uD83D\uDC86\uD83C\uDFFD\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "woman getting massage: medium-dark skin tone", "code": "\uD83D\uDC86\uD83C\uDFFE\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "woman getting massage: dark skin tone", "code": "\uD83D\uDC86\uD83C\uDFFF\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "person getting haircut", "code": "\uD83D\uDC87" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "person getting haircut: light skin tone", "code": "\uD83D\uDC87\uD83C\uDFFB" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "person getting haircut: medium-light skin tone", "code": "\uD83D\uDC87\uD83C\uDFFC" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "person getting haircut: medium skin tone", "code": "\uD83D\uDC87\uD83C\uDFFD" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "person getting haircut: medium-dark skin tone", "code": "\uD83D\uDC87\uD83C\uDFFE" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "person getting haircut: dark skin tone", "code": "\uD83D\uDC87\uD83C\uDFFF" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "man getting haircut", "code": "\uD83D\uDC87\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "man getting haircut: light skin tone", "code": "\uD83D\uDC87\uD83C\uDFFB\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "man getting haircut: medium-light skin tone", "code": "\uD83D\uDC87\uD83C\uDFFC\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "man getting haircut: medium skin tone", "code": "\uD83D\uDC87\uD83C\uDFFD\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "man getting haircut: medium-dark skin tone", "code": "\uD83D\uDC87\uD83C\uDFFE\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "man getting haircut: dark skin tone", "code": "\uD83D\uDC87\uD83C\uDFFF\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "woman getting haircut", "code": "\uD83D\uDC87\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "woman getting haircut: light skin tone", "code": "\uD83D\uDC87\uD83C\uDFFB\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "woman getting haircut: medium-light skin tone", "code": "\uD83D\uDC87\uD83C\uDFFC\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "woman getting haircut: medium skin tone", "code": "\uD83D\uDC87\uD83C\uDFFD\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "woman getting haircut: medium-dark skin tone", "code": "\uD83D\uDC87\uD83C\uDFFE\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "woman getting haircut: dark skin tone", "code": "\uD83D\uDC87\uD83C\uDFFF\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "person walking", "code": "\uD83D\uDEB6" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "person walking: light skin tone", "code": "\uD83D\uDEB6\uD83C\uDFFB" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "person walking: medium-light skin tone", "code": "\uD83D\uDEB6\uD83C\uDFFC" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "person walking: medium skin tone", "code": "\uD83D\uDEB6\uD83C\uDFFD" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "person walking: medium-dark skin tone", "code": "\uD83D\uDEB6\uD83C\uDFFE" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "person walking: dark skin tone", "code": "\uD83D\uDEB6\uD83C\uDFFF" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "man walking", "code": "\uD83D\uDEB6\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "man walking: light skin tone", "code": "\uD83D\uDEB6\uD83C\uDFFB\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "man walking: medium-light skin tone", "code": "\uD83D\uDEB6\uD83C\uDFFC\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "man walking: medium skin tone", "code": "\uD83D\uDEB6\uD83C\uDFFD\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "man walking: medium-dark skin tone", "code": "\uD83D\uDEB6\uD83C\uDFFE\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "man walking: dark skin tone", "code": "\uD83D\uDEB6\uD83C\uDFFF\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "woman walking", "code": "\uD83D\uDEB6\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "woman walking: light skin tone", "code": "\uD83D\uDEB6\uD83C\uDFFB\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "woman walking: medium-light skin tone", "code": "\uD83D\uDEB6\uD83C\uDFFC\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "woman walking: medium skin tone", "code": "\uD83D\uDEB6\uD83C\uDFFD\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "woman walking: medium-dark skin tone", "code": "\uD83D\uDEB6\uD83C\uDFFE\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "woman walking: dark skin tone", "code": "\uD83D\uDEB6\uD83C\uDFFF\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "person running", "code": "\uD83C\uDFC3" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "person running: light skin tone", "code": "\uD83C\uDFC3\uD83C\uDFFB" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "person running: medium-light skin tone", "code": "\uD83C\uDFC3\uD83C\uDFFC" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "person running: medium skin tone", "code": "\uD83C\uDFC3\uD83C\uDFFD" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "person running: medium-dark skin tone", "code": "\uD83C\uDFC3\uD83C\uDFFE" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "person running: dark skin tone", "code": "\uD83C\uDFC3\uD83C\uDFFF" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "man running", "code": "\uD83C\uDFC3\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "man running: light skin tone", "code": "\uD83C\uDFC3\uD83C\uDFFB\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "man running: medium-light skin tone", "code": "\uD83C\uDFC3\uD83C\uDFFC\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "man running: medium skin tone", "code": "\uD83C\uDFC3\uD83C\uDFFD\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "man running: medium-dark skin tone", "code": "\uD83C\uDFC3\uD83C\uDFFE\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "man running: dark skin tone", "code": "\uD83C\uDFC3\uD83C\uDFFF\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "woman running", "code": "\uD83C\uDFC3\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "woman running: light skin tone", "code": "\uD83C\uDFC3\uD83C\uDFFB\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "woman running: medium-light skin tone", "code": "\uD83C\uDFC3\uD83C\uDFFC\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "woman running: medium skin tone", "code": "\uD83C\uDFC3\uD83C\uDFFD\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "woman running: medium-dark skin tone", "code": "\uD83C\uDFC3\uD83C\uDFFE\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "woman running: dark skin tone", "code": "\uD83C\uDFC3\uD83C\uDFFF\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "woman dancing", "code": "\uD83D\uDC83" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "woman dancing: light skin tone", "code": "\uD83D\uDC83\uD83C\uDFFB" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "woman dancing: medium-light skin tone", "code": "\uD83D\uDC83\uD83C\uDFFC" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "woman dancing: medium skin tone", "code": "\uD83D\uDC83\uD83C\uDFFD" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "woman dancing: medium-dark skin tone", "code": "\uD83D\uDC83\uD83C\uDFFE" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "woman dancing: dark skin tone", "code": "\uD83D\uDC83\uD83C\uDFFF" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "man dancing", "code": "\uD83D\uDD7A" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "man dancing: light skin tone", "code": "\uD83D\uDD7A\uD83C\uDFFB" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "man dancing: medium-light skin tone", "code": "\uD83D\uDD7A\uD83C\uDFFC" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "man dancing: medium skin tone", "code": "\uD83D\uDD7A\uD83C\uDFFD" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "man dancing: medium-dark skin tone", "code": "\uD83D\uDD7A\uD83C\uDFFE" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "man dancing: dark skin tone", "code": "\uD83D\uDD7A\uD83C\uDFFF" },
            { "category": "smileys_n_people", "keyword": "people with bunny ears", "code": "\uD83D\uDC6F" },
            { "category": "smileys_n_people", "keyword": "men with bunny ears", "code": "\uD83D\uDC6F\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "keyword": "women with bunny ears", "code": "\uD83D\uDC6F\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "person in steamy room", "code": "\uD83E\uDDD6" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "person in steamy room: light skin tone", "code": "\uD83E\uDDD6\uD83C\uDFFB" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "person in steamy room: medium-light skin tone", "code": "\uD83E\uDDD6\uD83C\uDFFC" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "person in steamy room: medium skin tone", "code": "\uD83E\uDDD6\uD83C\uDFFD" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "person in steamy room: medium-dark skin tone", "code": "\uD83E\uDDD6\uD83C\uDFFE" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "person in steamy room: dark skin tone", "code": "\uD83E\uDDD6\uD83C\uDFFF" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "woman in steamy room", "code": "\uD83E\uDDD6\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "woman in steamy room: light skin tone", "code": "\uD83E\uDDD6\uD83C\uDFFB\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "woman in steamy room: medium-light skin tone", "code": "\uD83E\uDDD6\uD83C\uDFFC\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "woman in steamy room: medium skin tone", "code": "\uD83E\uDDD6\uD83C\uDFFD\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "woman in steamy room: medium-dark skin tone", "code": "\uD83E\uDDD6\uD83C\uDFFE\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "woman in steamy room: dark skin tone", "code": "\uD83E\uDDD6\uD83C\uDFFF\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "man in steamy room", "code": "\uD83E\uDDD6\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "man in steamy room: light skin tone", "code": "\uD83E\uDDD6\uD83C\uDFFB\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "man in steamy room: medium-light skin tone", "code": "\uD83E\uDDD6\uD83C\uDFFC\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "man in steamy room: medium skin tone", "code": "\uD83E\uDDD6\uD83C\uDFFD\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "man in steamy room: medium-dark skin tone", "code": "\uD83E\uDDD6\uD83C\uDFFE\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "man in steamy room: dark skin tone", "code": "\uD83E\uDDD6\uD83C\uDFFF\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "person climbing", "code": "\uD83E\uDDD7" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "person climbing: light skin tone", "code": "\uD83E\uDDD7\uD83C\uDFFB" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "person climbing: medium-light skin tone", "code": "\uD83E\uDDD7\uD83C\uDFFC" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "person climbing: medium skin tone", "code": "\uD83E\uDDD7\uD83C\uDFFD" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "person climbing: medium-dark skin tone", "code": "\uD83E\uDDD7\uD83C\uDFFE" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "person climbing: dark skin tone", "code": "\uD83E\uDDD7\uD83C\uDFFF" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "woman climbing", "code": "\uD83E\uDDD7\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "woman climbing: light skin tone", "code": "\uD83E\uDDD7\uD83C\uDFFB\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "woman climbing: medium-light skin tone", "code": "\uD83E\uDDD7\uD83C\uDFFC\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "woman climbing: medium skin tone", "code": "\uD83E\uDDD7\uD83C\uDFFD\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "woman climbing: medium-dark skin tone", "code": "\uD83E\uDDD7\uD83C\uDFFE\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "woman climbing: dark skin tone", "code": "\uD83E\uDDD7\uD83C\uDFFF\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "man climbing", "code": "\uD83E\uDDD7\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "man climbing: light skin tone", "code": "\uD83E\uDDD7\uD83C\uDFFB\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "man climbing: medium-light skin tone", "code": "\uD83E\uDDD7\uD83C\uDFFC\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "man climbing: medium skin tone", "code": "\uD83E\uDDD7\uD83C\uDFFD\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "man climbing: medium-dark skin tone", "code": "\uD83E\uDDD7\uD83C\uDFFE\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "man climbing: dark skin tone", "code": "\uD83E\uDDD7\uD83C\uDFFF\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "person in lotus position", "code": "\uD83E\uDDD8" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "person in lotus position: light skin tone", "code": "\uD83E\uDDD8\uD83C\uDFFB" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "person in lotus position: medium-light skin tone", "code": "\uD83E\uDDD8\uD83C\uDFFC" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "person in lotus position: medium skin tone", "code": "\uD83E\uDDD8\uD83C\uDFFD" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "person in lotus position: medium-dark skin tone", "code": "\uD83E\uDDD8\uD83C\uDFFE" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "person in lotus position: dark skin tone", "code": "\uD83E\uDDD8\uD83C\uDFFF" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "woman in lotus position", "code": "\uD83E\uDDD8\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "woman in lotus position: light skin tone", "code": "\uD83E\uDDD8\uD83C\uDFFB\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "woman in lotus position: medium-light skin tone", "code": "\uD83E\uDDD8\uD83C\uDFFC\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "woman in lotus position: medium skin tone", "code": "\uD83E\uDDD8\uD83C\uDFFD\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "woman in lotus position: medium-dark skin tone", "code": "\uD83E\uDDD8\uD83C\uDFFE\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "woman in lotus position: dark skin tone", "code": "\uD83E\uDDD8\uD83C\uDFFF\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "man in lotus position", "code": "\uD83E\uDDD8\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "man in lotus position: light skin tone", "code": "\uD83E\uDDD8\uD83C\uDFFB\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "man in lotus position: medium-light skin tone", "code": "\uD83E\uDDD8\uD83C\uDFFC\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "man in lotus position: medium skin tone", "code": "\uD83E\uDDD8\uD83C\uDFFD\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "man in lotus position: medium-dark skin tone", "code": "\uD83E\uDDD8\uD83C\uDFFE\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "man in lotus position: dark skin tone", "code": "\uD83E\uDDD8\uD83C\uDFFF\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "person taking bath", "code": "\uD83D\uDEC0" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "person taking bath: light skin tone", "code": "\uD83D\uDEC0\uD83C\uDFFB" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "person taking bath: medium-light skin tone", "code": "\uD83D\uDEC0\uD83C\uDFFC" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "person taking bath: medium skin tone", "code": "\uD83D\uDEC0\uD83C\uDFFD" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "person taking bath: medium-dark skin tone", "code": "\uD83D\uDEC0\uD83C\uDFFE" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "person taking bath: dark skin tone", "code": "\uD83D\uDEC0\uD83C\uDFFF" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "person in bed", "code": "\uD83D\uDECC" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "person in bed: light skin tone", "code": "\uD83D\uDECC\uD83C\uDFFB" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "person in bed: medium-light skin tone", "code": "\uD83D\uDECC\uD83C\uDFFC" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "person in bed: medium skin tone", "code": "\uD83D\uDECC\uD83C\uDFFD" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "person in bed: medium-dark skin tone", "code": "\uD83D\uDECC\uD83C\uDFFE" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "person in bed: dark skin tone", "code": "\uD83D\uDECC\uD83C\uDFFF" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "man in suit levitating", "code": "\uD83D\uDD74\uFE0F" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "man in suit levitating: light skin tone", "code": "\uD83D\uDD74\uD83C\uDFFB" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "man in suit levitating: medium-light skin tone", "code": "\uD83D\uDD74\uD83C\uDFFC" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "man in suit levitating: medium skin tone", "code": "\uD83D\uDD74\uD83C\uDFFD" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "man in suit levitating: medium-dark skin tone", "code": "\uD83D\uDD74\uD83C\uDFFE" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "man in suit levitating: dark skin tone", "code": "\uD83D\uDD74\uD83C\uDFFF" },
            { "category": "smileys_n_people", "keyword": "speaking head", "code": "\uD83D\uDDE3\uFE0F" },
            { "category": "smileys_n_people", "keyword": "bust in silhouette", "code": "\uD83D\uDC64" },
            { "category": "smileys_n_people", "keyword": "busts in silhouette", "code": "\uD83D\uDC65" },
            { "category": "smileys_n_people", "keyword": "person fencing", "code": "\uD83E\uDD3A" },
            { "category": "smileys_n_people", "keyword": "horse racing", "code": "\uD83C\uDFC7" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "horse racing: light skin tone", "code": "\uD83C\uDFC7\uD83C\uDFFB" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "horse racing: medium-light skin tone", "code": "\uD83C\uDFC7\uD83C\uDFFC" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "horse racing: medium skin tone", "code": "\uD83C\uDFC7\uD83C\uDFFD" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "horse racing: medium-dark skin tone", "code": "\uD83C\uDFC7\uD83C\uDFFE" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "horse racing: dark skin tone", "code": "\uD83C\uDFC7\uD83C\uDFFF" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "skier", "code": "\u26F7\uFE0F" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "snowboarder", "code": "\uD83C\uDFC2" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "snowboarder: light skin tone", "code": "\uD83C\uDFC2\uD83C\uDFFB" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "snowboarder: medium-light skin tone", "code": "\uD83C\uDFC2\uD83C\uDFFC" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "snowboarder: medium skin tone", "code": "\uD83C\uDFC2\uD83C\uDFFD" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "snowboarder: medium-dark skin tone", "code": "\uD83C\uDFC2\uD83C\uDFFE" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "snowboarder: dark skin tone", "code": "\uD83C\uDFC2\uD83C\uDFFF" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "person golfing", "code": "\uD83C\uDFCC\uFE0F" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "person golfing: light skin tone", "code": "\uD83C\uDFCC\uD83C\uDFFB" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "person golfing: medium-light skin tone", "code": "\uD83C\uDFCC\uD83C\uDFFC" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "person golfing: medium skin tone", "code": "\uD83C\uDFCC\uD83C\uDFFD" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "person golfing: medium-dark skin tone", "code": "\uD83C\uDFCC\uD83C\uDFFE" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "person golfing: dark skin tone", "code": "\uD83C\uDFCC\uD83C\uDFFF" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "man golfing", "code": "\uD83C\uDFCC\uFE0F\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "man golfing: light skin tone", "code": "\uD83C\uDFCC\uD83C\uDFFB\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "man golfing: medium-light skin tone", "code": "\uD83C\uDFCC\uD83C\uDFFC\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "man golfing: medium skin tone", "code": "\uD83C\uDFCC\uD83C\uDFFD\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "man golfing: medium-dark skin tone", "code": "\uD83C\uDFCC\uD83C\uDFFE\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "man golfing: dark skin tone", "code": "\uD83C\uDFCC\uD83C\uDFFF\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "woman golfing", "code": "\uD83C\uDFCC\uFE0F\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "woman golfing: light skin tone", "code": "\uD83C\uDFCC\uD83C\uDFFB\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "woman golfing: medium-light skin tone", "code": "\uD83C\uDFCC\uD83C\uDFFC\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "woman golfing: medium skin tone", "code": "\uD83C\uDFCC\uD83C\uDFFD\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "woman golfing: medium-dark skin tone", "code": "\uD83C\uDFCC\uD83C\uDFFE\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "woman golfing: dark skin tone", "code": "\uD83C\uDFCC\uD83C\uDFFF\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "person surfing", "code": "\uD83C\uDFC4" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "person surfing: light skin tone", "code": "\uD83C\uDFC4\uD83C\uDFFB" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "person surfing: medium-light skin tone", "code": "\uD83C\uDFC4\uD83C\uDFFC" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "person surfing: medium skin tone", "code": "\uD83C\uDFC4\uD83C\uDFFD" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "person surfing: medium-dark skin tone", "code": "\uD83C\uDFC4\uD83C\uDFFE" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "person surfing: dark skin tone", "code": "\uD83C\uDFC4\uD83C\uDFFF" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "man surfing", "code": "\uD83C\uDFC4\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "man surfing: light skin tone", "code": "\uD83C\uDFC4\uD83C\uDFFB\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "man surfing: medium-light skin tone", "code": "\uD83C\uDFC4\uD83C\uDFFC\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "man surfing: medium skin tone", "code": "\uD83C\uDFC4\uD83C\uDFFD\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "man surfing: medium-dark skin tone", "code": "\uD83C\uDFC4\uD83C\uDFFE\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "man surfing: dark skin tone", "code": "\uD83C\uDFC4\uD83C\uDFFF\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "woman surfing", "code": "\uD83C\uDFC4\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "woman surfing: light skin tone", "code": "\uD83C\uDFC4\uD83C\uDFFB\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "woman surfing: medium-light skin tone", "code": "\uD83C\uDFC4\uD83C\uDFFC\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "woman surfing: medium skin tone", "code": "\uD83C\uDFC4\uD83C\uDFFD\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "woman surfing: medium-dark skin tone", "code": "\uD83C\uDFC4\uD83C\uDFFE\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "woman surfing: dark skin tone", "code": "\uD83C\uDFC4\uD83C\uDFFF\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "person rowing boat", "code": "\uD83D\uDEA3" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "person rowing boat: light skin tone", "code": "\uD83D\uDEA3\uD83C\uDFFB" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "person rowing boat: medium-light skin tone", "code": "\uD83D\uDEA3\uD83C\uDFFC" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "person rowing boat: medium skin tone", "code": "\uD83D\uDEA3\uD83C\uDFFD" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "person rowing boat: medium-dark skin tone", "code": "\uD83D\uDEA3\uD83C\uDFFE" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "person rowing boat: dark skin tone", "code": "\uD83D\uDEA3\uD83C\uDFFF" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "man rowing boat", "code": "\uD83D\uDEA3\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "man rowing boat: light skin tone", "code": "\uD83D\uDEA3\uD83C\uDFFB\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "man rowing boat: medium-light skin tone", "code": "\uD83D\uDEA3\uD83C\uDFFC\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "man rowing boat: medium skin tone", "code": "\uD83D\uDEA3\uD83C\uDFFD\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "man rowing boat: medium-dark skin tone", "code": "\uD83D\uDEA3\uD83C\uDFFE\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "man rowing boat: dark skin tone", "code": "\uD83D\uDEA3\uD83C\uDFFF\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "woman rowing boat", "code": "\uD83D\uDEA3\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "woman rowing boat: light skin tone", "code": "\uD83D\uDEA3\uD83C\uDFFB\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "woman rowing boat: medium-light skin tone", "code": "\uD83D\uDEA3\uD83C\uDFFC\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "woman rowing boat: medium skin tone", "code": "\uD83D\uDEA3\uD83C\uDFFD\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "woman rowing boat: medium-dark skin tone", "code": "\uD83D\uDEA3\uD83C\uDFFE\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "woman rowing boat: dark skin tone", "code": "\uD83D\uDEA3\uD83C\uDFFF\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "person swimming", "code": "\uD83C\uDFCA" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "person swimming: light skin tone", "code": "\uD83C\uDFCA\uD83C\uDFFB" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "person swimming: medium-light skin tone", "code": "\uD83C\uDFCA\uD83C\uDFFC" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "person swimming: medium skin tone", "code": "\uD83C\uDFCA\uD83C\uDFFD" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "person swimming: medium-dark skin tone", "code": "\uD83C\uDFCA\uD83C\uDFFE" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "person swimming: dark skin tone", "code": "\uD83C\uDFCA\uD83C\uDFFF" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "man swimming", "code": "\uD83C\uDFCA\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "man swimming: light skin tone", "code": "\uD83C\uDFCA\uD83C\uDFFB\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "man swimming: medium-light skin tone", "code": "\uD83C\uDFCA\uD83C\uDFFC\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "man swimming: medium skin tone", "code": "\uD83C\uDFCA\uD83C\uDFFD\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "man swimming: medium-dark skin tone", "code": "\uD83C\uDFCA\uD83C\uDFFE\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "man swimming: dark skin tone", "code": "\uD83C\uDFCA\uD83C\uDFFF\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "woman swimming", "code": "\uD83C\uDFCA\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "woman swimming: light skin tone", "code": "\uD83C\uDFCA\uD83C\uDFFB\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "woman swimming: medium-light skin tone", "code": "\uD83C\uDFCA\uD83C\uDFFC\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "woman swimming: medium skin tone", "code": "\uD83C\uDFCA\uD83C\uDFFD\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "woman swimming: medium-dark skin tone", "code": "\uD83C\uDFCA\uD83C\uDFFE\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "woman swimming: dark skin tone", "code": "\uD83C\uDFCA\uD83C\uDFFF\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "person bouncing ball", "code": "\u26F9\uFE0F" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "person bouncing ball: light skin tone", "code": "\u26F9\uD83C\uDFFB" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "person bouncing ball: medium-light skin tone", "code": "\u26F9\uD83C\uDFFC" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "person bouncing ball: medium skin tone", "code": "\u26F9\uD83C\uDFFD" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "person bouncing ball: medium-dark skin tone", "code": "\u26F9\uD83C\uDFFE" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "person bouncing ball: dark skin tone", "code": "\u26F9\uD83C\uDFFF" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "man bouncing ball", "code": "\u26F9\uFE0F\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "man bouncing ball: light skin tone", "code": "\u26F9\uD83C\uDFFB\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "man bouncing ball: medium-light skin tone", "code": "\u26F9\uD83C\uDFFC\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "man bouncing ball: medium skin tone", "code": "\u26F9\uD83C\uDFFD\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "man bouncing ball: medium-dark skin tone", "code": "\u26F9\uD83C\uDFFE\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "man bouncing ball: dark skin tone", "code": "\u26F9\uD83C\uDFFF\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "woman bouncing ball", "code": "\u26F9\uFE0F\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "woman bouncing ball: light skin tone", "code": "\u26F9\uD83C\uDFFB\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "woman bouncing ball: medium-light skin tone", "code": "\u26F9\uD83C\uDFFC\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "woman bouncing ball: medium skin tone", "code": "\u26F9\uD83C\uDFFD\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "woman bouncing ball: medium-dark skin tone", "code": "\u26F9\uD83C\uDFFE\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "woman bouncing ball: dark skin tone", "code": "\u26F9\uD83C\uDFFF\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "person lifting weights", "code": "\uD83C\uDFCB\uFE0F" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "person lifting weights: light skin tone", "code": "\uD83C\uDFCB\uD83C\uDFFB" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "person lifting weights: medium-light skin tone", "code": "\uD83C\uDFCB\uD83C\uDFFC" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "person lifting weights: medium skin tone", "code": "\uD83C\uDFCB\uD83C\uDFFD" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "person lifting weights: medium-dark skin tone", "code": "\uD83C\uDFCB\uD83C\uDFFE" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "person lifting weights: dark skin tone", "code": "\uD83C\uDFCB\uD83C\uDFFF" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "man lifting weights", "code": "\uD83C\uDFCB\uFE0F\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "man lifting weights: light skin tone", "code": "\uD83C\uDFCB\uD83C\uDFFB\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "man lifting weights: medium-light skin tone", "code": "\uD83C\uDFCB\uD83C\uDFFC\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "man lifting weights: medium skin tone", "code": "\uD83C\uDFCB\uD83C\uDFFD\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "man lifting weights: medium-dark skin tone", "code": "\uD83C\uDFCB\uD83C\uDFFE\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "man lifting weights: dark skin tone", "code": "\uD83C\uDFCB\uD83C\uDFFF\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "woman lifting weights", "code": "\uD83C\uDFCB\uFE0F\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "woman lifting weights: light skin tone", "code": "\uD83C\uDFCB\uD83C\uDFFB\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "woman lifting weights: medium-light skin tone", "code": "\uD83C\uDFCB\uD83C\uDFFC\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "woman lifting weights: medium skin tone", "code": "\uD83C\uDFCB\uD83C\uDFFD\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "woman lifting weights: medium-dark skin tone", "code": "\uD83C\uDFCB\uD83C\uDFFE\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "woman lifting weights: dark skin tone", "code": "\uD83C\uDFCB\uD83C\uDFFF\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "person biking", "code": "\uD83D\uDEB4" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "person biking: light skin tone", "code": "\uD83D\uDEB4\uD83C\uDFFB" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "person biking: medium-light skin tone", "code": "\uD83D\uDEB4\uD83C\uDFFC" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "person biking: medium skin tone", "code": "\uD83D\uDEB4\uD83C\uDFFD" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "person biking: medium-dark skin tone", "code": "\uD83D\uDEB4\uD83C\uDFFE" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "person biking: dark skin tone", "code": "\uD83D\uDEB4\uD83C\uDFFF" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "man biking", "code": "\uD83D\uDEB4\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "man biking: light skin tone", "code": "\uD83D\uDEB4\uD83C\uDFFB\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "man biking: medium-light skin tone", "code": "\uD83D\uDEB4\uD83C\uDFFC\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "man biking: medium skin tone", "code": "\uD83D\uDEB4\uD83C\uDFFD\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "man biking: medium-dark skin tone", "code": "\uD83D\uDEB4\uD83C\uDFFE\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "man biking: dark skin tone", "code": "\uD83D\uDEB4\uD83C\uDFFF\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "woman biking", "code": "\uD83D\uDEB4\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "woman biking: light skin tone", "code": "\uD83D\uDEB4\uD83C\uDFFB\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "woman biking: medium-light skin tone", "code": "\uD83D\uDEB4\uD83C\uDFFC\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "woman biking: medium skin tone", "code": "\uD83D\uDEB4\uD83C\uDFFD\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "woman biking: medium-dark skin tone", "code": "\uD83D\uDEB4\uD83C\uDFFE\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "woman biking: dark skin tone", "code": "\uD83D\uDEB4\uD83C\uDFFF\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "person mountain biking", "code": "\uD83D\uDEB5" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "person mountain biking: light skin tone", "code": "\uD83D\uDEB5\uD83C\uDFFB" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "person mountain biking: medium-light skin tone", "code": "\uD83D\uDEB5\uD83C\uDFFC" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "person mountain biking: medium skin tone", "code": "\uD83D\uDEB5\uD83C\uDFFD" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "person mountain biking: medium-dark skin tone", "code": "\uD83D\uDEB5\uD83C\uDFFE" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "person mountain biking: dark skin tone", "code": "\uD83D\uDEB5\uD83C\uDFFF" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "man mountain biking", "code": "\uD83D\uDEB5\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "man mountain biking: light skin tone", "code": "\uD83D\uDEB5\uD83C\uDFFB\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "man mountain biking: medium-light skin tone", "code": "\uD83D\uDEB5\uD83C\uDFFC\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "man mountain biking: medium skin tone", "code": "\uD83D\uDEB5\uD83C\uDFFD\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "man mountain biking: medium-dark skin tone", "code": "\uD83D\uDEB5\uD83C\uDFFE\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "man mountain biking: dark skin tone", "code": "\uD83D\uDEB5\uD83C\uDFFF\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "woman mountain biking", "code": "\uD83D\uDEB5\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "woman mountain biking: light skin tone", "code": "\uD83D\uDEB5\uD83C\uDFFB\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "woman mountain biking: medium-light skin tone", "code": "\uD83D\uDEB5\uD83C\uDFFC\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "woman mountain biking: medium skin tone", "code": "\uD83D\uDEB5\uD83C\uDFFD\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "woman mountain biking: medium-dark skin tone", "code": "\uD83D\uDEB5\uD83C\uDFFE\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "woman mountain biking: dark skin tone", "code": "\uD83D\uDEB5\uD83C\uDFFF\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "racing car", "code": "\uD83C\uDFCE\uFE0F" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "motorcycle", "code": "\uD83C\uDFCD\uFE0F" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "person cartwheeling", "code": "\uD83E\uDD38" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "person cartwheeling: light skin tone", "code": "\uD83E\uDD38\uD83C\uDFFB" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "person cartwheeling: medium-light skin tone", "code": "\uD83E\uDD38\uD83C\uDFFC" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "person cartwheeling: medium skin tone", "code": "\uD83E\uDD38\uD83C\uDFFD" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "person cartwheeling: medium-dark skin tone", "code": "\uD83E\uDD38\uD83C\uDFFE" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "person cartwheeling: dark skin tone", "code": "\uD83E\uDD38\uD83C\uDFFF" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "man cartwheeling", "code": "\uD83E\uDD38\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "man cartwheeling: light skin tone", "code": "\uD83E\uDD38\uD83C\uDFFB\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "man cartwheeling: medium-light skin tone", "code": "\uD83E\uDD38\uD83C\uDFFC\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "man cartwheeling: medium skin tone", "code": "\uD83E\uDD38\uD83C\uDFFD\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "man cartwheeling: medium-dark skin tone", "code": "\uD83E\uDD38\uD83C\uDFFE\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "man cartwheeling: dark skin tone", "code": "\uD83E\uDD38\uD83C\uDFFF\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "woman cartwheeling", "code": "\uD83E\uDD38\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "woman cartwheeling: light skin tone", "code": "\uD83E\uDD38\uD83C\uDFFB\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "woman cartwheeling: medium-light skin tone", "code": "\uD83E\uDD38\uD83C\uDFFC\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "woman cartwheeling: medium skin tone", "code": "\uD83E\uDD38\uD83C\uDFFD\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "woman cartwheeling: medium-dark skin tone", "code": "\uD83E\uDD38\uD83C\uDFFE\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "woman cartwheeling: dark skin tone", "code": "\uD83E\uDD38\uD83C\uDFFF\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "keyword": "people wrestling", "code": "\uD83E\uDD3C" },
            { "category": "smileys_n_people", "keyword": "men wrestling", "code": "\uD83E\uDD3C\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "keyword": "women wrestling", "code": "\uD83E\uDD3C\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "person playing water polo", "code": "\uD83E\uDD3D" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "person playing water polo: light skin tone", "code": "\uD83E\uDD3D\uD83C\uDFFB" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "person playing water polo: medium-light skin tone", "code": "\uD83E\uDD3D\uD83C\uDFFC" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "person playing water polo: medium skin tone", "code": "\uD83E\uDD3D\uD83C\uDFFD" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "person playing water polo: medium-dark skin tone", "code": "\uD83E\uDD3D\uD83C\uDFFE" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "person playing water polo: dark skin tone", "code": "\uD83E\uDD3D\uD83C\uDFFF" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "man playing water polo", "code": "\uD83E\uDD3D\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "man playing water polo: light skin tone", "code": "\uD83E\uDD3D\uD83C\uDFFB\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "man playing water polo: medium-light skin tone", "code": "\uD83E\uDD3D\uD83C\uDFFC\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "man playing water polo: medium skin tone", "code": "\uD83E\uDD3D\uD83C\uDFFD\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "man playing water polo: medium-dark skin tone", "code": "\uD83E\uDD3D\uD83C\uDFFE\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "man playing water polo: dark skin tone", "code": "\uD83E\uDD3D\uD83C\uDFFF\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "woman playing water polo", "code": "\uD83E\uDD3D\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "woman playing water polo: light skin tone", "code": "\uD83E\uDD3D\uD83C\uDFFB\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "woman playing water polo: medium-light skin tone", "code": "\uD83E\uDD3D\uD83C\uDFFC\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "woman playing water polo: medium skin tone", "code": "\uD83E\uDD3D\uD83C\uDFFD\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "woman playing water polo: medium-dark skin tone", "code": "\uD83E\uDD3D\uD83C\uDFFE\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "woman playing water polo: dark skin tone", "code": "\uD83E\uDD3D\uD83C\uDFFF\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "person playing handball", "code": "\uD83E\uDD3E" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "person playing handball: light skin tone", "code": "\uD83E\uDD3E\uD83C\uDFFB" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "person playing handball: medium-light skin tone", "code": "\uD83E\uDD3E\uD83C\uDFFC" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "person playing handball: medium skin tone", "code": "\uD83E\uDD3E\uD83C\uDFFD" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "person playing handball: medium-dark skin tone", "code": "\uD83E\uDD3E\uD83C\uDFFE" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "person playing handball: dark skin tone", "code": "\uD83E\uDD3E\uD83C\uDFFF" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "man playing handball", "code": "\uD83E\uDD3E\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "man playing handball: light skin tone", "code": "\uD83E\uDD3E\uD83C\uDFFB\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "man playing handball: medium-light skin tone", "code": "\uD83E\uDD3E\uD83C\uDFFC\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "man playing handball: medium skin tone", "code": "\uD83E\uDD3E\uD83C\uDFFD\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "man playing handball: medium-dark skin tone", "code": "\uD83E\uDD3E\uD83C\uDFFE\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "man playing handball: dark skin tone", "code": "\uD83E\uDD3E\uD83C\uDFFF\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "woman playing handball", "code": "\uD83E\uDD3E\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "woman playing handball: light skin tone", "code": "\uD83E\uDD3E\uD83C\uDFFB\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "woman playing handball: medium-light skin tone", "code": "\uD83E\uDD3E\uD83C\uDFFC\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "woman playing handball: medium skin tone", "code": "\uD83E\uDD3E\uD83C\uDFFD\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "woman playing handball: medium-dark skin tone", "code": "\uD83E\uDD3E\uD83C\uDFFE\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "woman playing handball: dark skin tone", "code": "\uD83E\uDD3E\uD83C\uDFFF\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "person juggling", "code": "\uD83E\uDD39" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "person juggling: light skin tone", "code": "\uD83E\uDD39\uD83C\uDFFB" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "person juggling: medium-light skin tone", "code": "\uD83E\uDD39\uD83C\uDFFC" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "person juggling: medium skin tone", "code": "\uD83E\uDD39\uD83C\uDFFD" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "person juggling: medium-dark skin tone", "code": "\uD83E\uDD39\uD83C\uDFFE" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "person juggling: dark skin tone", "code": "\uD83E\uDD39\uD83C\uDFFF" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "man juggling", "code": "\uD83E\uDD39\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "man juggling: light skin tone", "code": "\uD83E\uDD39\uD83C\uDFFB\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "man juggling: medium-light skin tone", "code": "\uD83E\uDD39\uD83C\uDFFC\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "man juggling: medium skin tone", "code": "\uD83E\uDD39\uD83C\uDFFD\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "man juggling: medium-dark skin tone", "code": "\uD83E\uDD39\uD83C\uDFFE\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "man juggling: dark skin tone", "code": "\uD83E\uDD39\uD83C\uDFFF\u200D\u2642\uFE0F" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "woman juggling", "code": "\uD83E\uDD39\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "woman juggling: light skin tone", "code": "\uD83E\uDD39\uD83C\uDFFB\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "woman juggling: medium-light skin tone", "code": "\uD83E\uDD39\uD83C\uDFFC\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "woman juggling: medium skin tone", "code": "\uD83E\uDD39\uD83C\uDFFD\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "woman juggling: medium-dark skin tone", "code": "\uD83E\uDD39\uD83C\uDFFE\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "woman juggling: dark skin tone", "code": "\uD83E\uDD39\uD83C\uDFFF\u200D\u2640\uFE0F" },
            { "category": "smileys_n_people", "keyword": "man and woman holding hands", "code": "\uD83D\uDC6B" },
            { "category": "smileys_n_people", "keyword": "two men holding hands", "code": "\uD83D\uDC6C" },
            { "category": "smileys_n_people", "keyword": "two women holding hands", "code": "\uD83D\uDC6D" },
            { "category": "smileys_n_people", "keyword": "kiss", "code": "\uD83D\uDC8F" },
            { "category": "smileys_n_people", "keyword": "kiss: woman, man", "code": "\uD83D\uDC69\u200D\u2764\uFE0F\u200D\uD83D\uDC8B\u200D\uD83D\uDC68" },
            { "category": "smileys_n_people", "keyword": "kiss: man, man", "code": "\uD83D\uDC68\u200D\u2764\uFE0F\u200D\uD83D\uDC8B\u200D\uD83D\uDC68" },
            { "category": "smileys_n_people", "keyword": "kiss: woman, woman", "code": "\uD83D\uDC69\u200D\u2764\uFE0F\u200D\uD83D\uDC8B\u200D\uD83D\uDC69" },
            { "category": "smileys_n_people", "keyword": "couple with heart", "code": "\uD83D\uDC91" },
            { "category": "smileys_n_people", "keyword": "couple with heart: woman, man", "code": "\uD83D\uDC69\u200D\u2764\uFE0F\u200D\uD83D\uDC68" },
            { "category": "smileys_n_people", "keyword": "couple with heart: man, man", "code": "\uD83D\uDC68\u200D\u2764\uFE0F\u200D\uD83D\uDC68" },
            { "category": "smileys_n_people", "keyword": "couple with heart: woman, woman", "code": "\uD83D\uDC69\u200D\u2764\uFE0F\u200D\uD83D\uDC69" },
            { "category": "smileys_n_people", "keyword": "family", "code": "\uD83D\uDC6A" },
            { "category": "smileys_n_people", "keyword": "family: man, woman, boy", "code": "\uD83D\uDC68\u200D\uD83D\uDC69\u200D\uD83D\uDC66" },
            { "category": "smileys_n_people", "keyword": "family: man, woman, girl", "code": "\uD83D\uDC68\u200D\uD83D\uDC69\u200D\uD83D\uDC67" },
            { "category": "smileys_n_people", "keyword": "family: man, woman, girl, boy", "code": "\uD83D\uDC68\u200D\uD83D\uDC69\u200D\uD83D\uDC67\u200D\uD83D\uDC66" },
            { "category": "smileys_n_people", "keyword": "family: man, woman, boy, boy", "code": "\uD83D\uDC68\u200D\uD83D\uDC69\u200D\uD83D\uDC66\u200D\uD83D\uDC66" },
            { "category": "smileys_n_people", "keyword": "family: man, woman, girl, girl", "code": "\uD83D\uDC68\u200D\uD83D\uDC69\u200D\uD83D\uDC67\u200D\uD83D\uDC67" },
            { "category": "smileys_n_people", "keyword": "family: man, man, boy", "code": "\uD83D\uDC68\u200D\uD83D\uDC68\u200D\uD83D\uDC66" },
            { "category": "smileys_n_people", "keyword": "family: man, man, girl", "code": "\uD83D\uDC68\u200D\uD83D\uDC68\u200D\uD83D\uDC67" },
            { "category": "smileys_n_people", "keyword": "family: man, man, girl, boy", "code": "\uD83D\uDC68\u200D\uD83D\uDC68\u200D\uD83D\uDC67\u200D\uD83D\uDC66" },
            { "category": "smileys_n_people", "keyword": "family: man, man, boy, boy", "code": "\uD83D\uDC68\u200D\uD83D\uDC68\u200D\uD83D\uDC66\u200D\uD83D\uDC66" },
            { "category": "smileys_n_people", "keyword": "family: man, man, girl, girl", "code": "\uD83D\uDC68\u200D\uD83D\uDC68\u200D\uD83D\uDC67\u200D\uD83D\uDC67" },
            { "category": "smileys_n_people", "keyword": "family: woman, woman, boy", "code": "\uD83D\uDC69\u200D\uD83D\uDC69\u200D\uD83D\uDC66" },
            { "category": "smileys_n_people", "keyword": "family: woman, woman, girl", "code": "\uD83D\uDC69\u200D\uD83D\uDC69\u200D\uD83D\uDC67" },
            { "category": "smileys_n_people", "keyword": "family: woman, woman, girl, boy", "code": "\uD83D\uDC69\u200D\uD83D\uDC69\u200D\uD83D\uDC67\u200D\uD83D\uDC66" },
            { "category": "smileys_n_people", "keyword": "family: woman, woman, boy, boy", "code": "\uD83D\uDC69\u200D\uD83D\uDC69\u200D\uD83D\uDC66\u200D\uD83D\uDC66" },
            { "category": "smileys_n_people", "keyword": "family: woman, woman, girl, girl", "code": "\uD83D\uDC69\u200D\uD83D\uDC69\u200D\uD83D\uDC67\u200D\uD83D\uDC67" },
            { "category": "smileys_n_people", "keyword": "family: man, boy", "code": "\uD83D\uDC68\u200D\uD83D\uDC66" },
            { "category": "smileys_n_people", "keyword": "family: man, boy, boy", "code": "\uD83D\uDC68\u200D\uD83D\uDC66\u200D\uD83D\uDC66" },
            { "category": "smileys_n_people", "keyword": "family: man, girl", "code": "\uD83D\uDC68\u200D\uD83D\uDC67" },
            { "category": "smileys_n_people", "keyword": "family: man, girl, boy", "code": "\uD83D\uDC68\u200D\uD83D\uDC67\u200D\uD83D\uDC66" },
            { "category": "smileys_n_people", "keyword": "family: man, girl, girl", "code": "\uD83D\uDC68\u200D\uD83D\uDC67\u200D\uD83D\uDC67" },
            { "category": "smileys_n_people", "keyword": "family: woman, boy", "code": "\uD83D\uDC69\u200D\uD83D\uDC66" },
            { "category": "smileys_n_people", "keyword": "family: woman, boy, boy", "code": "\uD83D\uDC69\u200D\uD83D\uDC66\u200D\uD83D\uDC66" },
            { "category": "smileys_n_people", "keyword": "family: woman, girl", "code": "\uD83D\uDC69\u200D\uD83D\uDC67" },
            { "category": "smileys_n_people", "keyword": "family: woman, girl, boy", "code": "\uD83D\uDC69\u200D\uD83D\uDC67\u200D\uD83D\uDC66" },
            { "category": "smileys_n_people", "keyword": "family: woman, girl, girl", "code": "\uD83D\uDC69\u200D\uD83D\uDC67\u200D\uD83D\uDC67" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "selfie", "code": "\uD83E\uDD33" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "selfie: light skin tone", "code": "\uD83E\uDD33\uD83C\uDFFB" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "selfie: medium-light skin tone", "code": "\uD83E\uDD33\uD83C\uDFFC" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "selfie: medium skin tone", "code": "\uD83E\uDD33\uD83C\uDFFD" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "selfie: medium-dark skin tone", "code": "\uD83E\uDD33\uD83C\uDFFE" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "selfie: dark skin tone", "code": "\uD83E\uDD33\uD83C\uDFFF" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "flexed biceps", "code": "\uD83D\uDCAA" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "flexed biceps: light skin tone", "code": "\uD83D\uDCAA\uD83C\uDFFB" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "flexed biceps: medium-light skin tone", "code": "\uD83D\uDCAA\uD83C\uDFFC" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "flexed biceps: medium skin tone", "code": "\uD83D\uDCAA\uD83C\uDFFD" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "flexed biceps: medium-dark skin tone", "code": "\uD83D\uDCAA\uD83C\uDFFE" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "flexed biceps: dark skin tone", "code": "\uD83D\uDCAA\uD83C\uDFFF" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "leg", "code": "\uD83E\uDDB5" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "leg: light skin tone", "code": "\uD83E\uDDB5\uD83C\uDFFB" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "leg: medium-light skin tone", "code": "\uD83E\uDDB5\uD83C\uDFFC" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "leg: medium skin tone", "code": "\uD83E\uDDB5\uD83C\uDFFD" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "leg: medium-dark skin tone", "code": "\uD83E\uDDB5\uD83C\uDFFE" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "leg: dark skin tone", "code": "\uD83E\uDDB5\uD83C\uDFFF" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "foot", "code": "\uD83E\uDDB6" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "foot: light skin tone", "code": "\uD83E\uDDB6\uD83C\uDFFB" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "foot: medium-light skin tone", "code": "\uD83E\uDDB6\uD83C\uDFFC" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "foot: medium skin tone", "code": "\uD83E\uDDB6\uD83C\uDFFD" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "foot: medium-dark skin tone", "code": "\uD83E\uDDB6\uD83C\uDFFE" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "foot: dark skin tone", "code": "\uD83E\uDDB6\uD83C\uDFFF" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "backhand index pointing left", "code": "\uD83D\uDC48" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "backhand index pointing left: light skin tone", "code": "\uD83D\uDC48\uD83C\uDFFB" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "backhand index pointing left: medium-light skin tone", "code": "\uD83D\uDC48\uD83C\uDFFC" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "backhand index pointing left: medium skin tone", "code": "\uD83D\uDC48\uD83C\uDFFD" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "backhand index pointing left: medium-dark skin tone", "code": "\uD83D\uDC48\uD83C\uDFFE" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "backhand index pointing left: dark skin tone", "code": "\uD83D\uDC48\uD83C\uDFFF" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "backhand index pointing right", "code": "\uD83D\uDC49" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "backhand index pointing right: light skin tone", "code": "\uD83D\uDC49\uD83C\uDFFB" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "backhand index pointing right: medium-light skin tone", "code": "\uD83D\uDC49\uD83C\uDFFC" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "backhand index pointing right: medium skin tone", "code": "\uD83D\uDC49\uD83C\uDFFD" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "backhand index pointing right: medium-dark skin tone", "code": "\uD83D\uDC49\uD83C\uDFFE" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "backhand index pointing right: dark skin tone", "code": "\uD83D\uDC49\uD83C\uDFFF" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "index pointing up", "code": "\u261D\uFE0F" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "index pointing up: light skin tone", "code": "\u261D\uD83C\uDFFB" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "index pointing up: medium-light skin tone", "code": "\u261D\uD83C\uDFFC" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "index pointing up: medium skin tone", "code": "\u261D\uD83C\uDFFD" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "index pointing up: medium-dark skin tone", "code": "\u261D\uD83C\uDFFE" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "index pointing up: dark skin tone", "code": "\u261D\uD83C\uDFFF" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "backhand index pointing up", "code": "\uD83D\uDC46" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "backhand index pointing up: light skin tone", "code": "\uD83D\uDC46\uD83C\uDFFB" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "backhand index pointing up: medium-light skin tone", "code": "\uD83D\uDC46\uD83C\uDFFC" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "backhand index pointing up: medium skin tone", "code": "\uD83D\uDC46\uD83C\uDFFD" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "backhand index pointing up: medium-dark skin tone", "code": "\uD83D\uDC46\uD83C\uDFFE" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "backhand index pointing up: dark skin tone", "code": "\uD83D\uDC46\uD83C\uDFFF" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "middle finger", "code": "\uD83D\uDD95" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "middle finger: light skin tone", "code": "\uD83D\uDD95\uD83C\uDFFB" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "middle finger: medium-light skin tone", "code": "\uD83D\uDD95\uD83C\uDFFC" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "middle finger: medium skin tone", "code": "\uD83D\uDD95\uD83C\uDFFD" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "middle finger: medium-dark skin tone", "code": "\uD83D\uDD95\uD83C\uDFFE" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "middle finger: dark skin tone", "code": "\uD83D\uDD95\uD83C\uDFFF" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "backhand index pointing down", "code": "\uD83D\uDC47" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "backhand index pointing down: light skin tone", "code": "\uD83D\uDC47\uD83C\uDFFB" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "backhand index pointing down: medium-light skin tone", "code": "\uD83D\uDC47\uD83C\uDFFC" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "backhand index pointing down: medium skin tone", "code": "\uD83D\uDC47\uD83C\uDFFD" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "backhand index pointing down: medium-dark skin tone", "code": "\uD83D\uDC47\uD83C\uDFFE" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "backhand index pointing down: dark skin tone", "code": "\uD83D\uDC47\uD83C\uDFFF" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "victory hand", "code": "\u270C\uFE0F" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "victory hand: light skin tone", "code": "\u270C\uD83C\uDFFB" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "victory hand: medium-light skin tone", "code": "\u270C\uD83C\uDFFC" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "victory hand: medium skin tone", "code": "\u270C\uD83C\uDFFD" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "victory hand: medium-dark skin tone", "code": "\u270C\uD83C\uDFFE" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "victory hand: dark skin tone", "code": "\u270C\uD83C\uDFFF" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "crossed fingers", "code": "\uD83E\uDD1E" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "crossed fingers: light skin tone", "code": "\uD83E\uDD1E\uD83C\uDFFB" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "crossed fingers: medium-light skin tone", "code": "\uD83E\uDD1E\uD83C\uDFFC" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "crossed fingers: medium skin tone", "code": "\uD83E\uDD1E\uD83C\uDFFD" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "crossed fingers: medium-dark skin tone", "code": "\uD83E\uDD1E\uD83C\uDFFE" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "crossed fingers: dark skin tone", "code": "\uD83E\uDD1E\uD83C\uDFFF" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "vulcan salute", "code": "\uD83D\uDD96" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "vulcan salute: light skin tone", "code": "\uD83D\uDD96\uD83C\uDFFB" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "vulcan salute: medium-light skin tone", "code": "\uD83D\uDD96\uD83C\uDFFC" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "vulcan salute: medium skin tone", "code": "\uD83D\uDD96\uD83C\uDFFD" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "vulcan salute: medium-dark skin tone", "code": "\uD83D\uDD96\uD83C\uDFFE" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "vulcan salute: dark skin tone", "code": "\uD83D\uDD96\uD83C\uDFFF" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "sign of the horns", "code": "\uD83E\uDD18" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "sign of the horns: light skin tone", "code": "\uD83E\uDD18\uD83C\uDFFB" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "sign of the horns: medium-light skin tone", "code": "\uD83E\uDD18\uD83C\uDFFC" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "sign of the horns: medium skin tone", "code": "\uD83E\uDD18\uD83C\uDFFD" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "sign of the horns: medium-dark skin tone", "code": "\uD83E\uDD18\uD83C\uDFFE" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "sign of the horns: dark skin tone", "code": "\uD83E\uDD18\uD83C\uDFFF" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "call me hand", "code": "\uD83E\uDD19" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "call me hand: light skin tone", "code": "\uD83E\uDD19\uD83C\uDFFB" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "call me hand: medium-light skin tone", "code": "\uD83E\uDD19\uD83C\uDFFC" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "call me hand: medium skin tone", "code": "\uD83E\uDD19\uD83C\uDFFD" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "call me hand: medium-dark skin tone", "code": "\uD83E\uDD19\uD83C\uDFFE" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "call me hand: dark skin tone", "code": "\uD83E\uDD19\uD83C\uDFFF" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "hand with fingers splayed", "code": "\uD83D\uDD90\uFE0F" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "hand with fingers splayed: light skin tone", "code": "\uD83D\uDD90\uD83C\uDFFB" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "hand with fingers splayed: medium-light skin tone", "code": "\uD83D\uDD90\uD83C\uDFFC" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "hand with fingers splayed: medium skin tone", "code": "\uD83D\uDD90\uD83C\uDFFD" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "hand with fingers splayed: medium-dark skin tone", "code": "\uD83D\uDD90\uD83C\uDFFE" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "hand with fingers splayed: dark skin tone", "code": "\uD83D\uDD90\uD83C\uDFFF" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "raised hand", "code": "\u270B" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "raised hand: light skin tone", "code": "\u270B\uD83C\uDFFB" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "raised hand: medium-light skin tone", "code": "\u270B\uD83C\uDFFC" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "raised hand: medium skin tone", "code": "\u270B\uD83C\uDFFD" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "raised hand: medium-dark skin tone", "code": "\u270B\uD83C\uDFFE" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "raised hand: dark skin tone", "code": "\u270B\uD83C\uDFFF" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "OK hand", "code": "\uD83D\uDC4C" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "OK hand: light skin tone", "code": "\uD83D\uDC4C\uD83C\uDFFB" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "OK hand: medium-light skin tone", "code": "\uD83D\uDC4C\uD83C\uDFFC" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "OK hand: medium skin tone", "code": "\uD83D\uDC4C\uD83C\uDFFD" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "OK hand: medium-dark skin tone", "code": "\uD83D\uDC4C\uD83C\uDFFE" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "OK hand: dark skin tone", "code": "\uD83D\uDC4C\uD83C\uDFFF" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "thumbs up", "code": "\uD83D\uDC4D" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "thumbs up: light skin tone", "code": "\uD83D\uDC4D\uD83C\uDFFB" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "thumbs up: medium-light skin tone", "code": "\uD83D\uDC4D\uD83C\uDFFC" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "thumbs up: medium skin tone", "code": "\uD83D\uDC4D\uD83C\uDFFD" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "thumbs up: medium-dark skin tone", "code": "\uD83D\uDC4D\uD83C\uDFFE" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "thumbs up: dark skin tone", "code": "\uD83D\uDC4D\uD83C\uDFFF" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "thumbs down", "code": "\uD83D\uDC4E" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "thumbs down: light skin tone", "code": "\uD83D\uDC4E\uD83C\uDFFB" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "thumbs down: medium-light skin tone", "code": "\uD83D\uDC4E\uD83C\uDFFC" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "thumbs down: medium skin tone", "code": "\uD83D\uDC4E\uD83C\uDFFD" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "thumbs down: medium-dark skin tone", "code": "\uD83D\uDC4E\uD83C\uDFFE" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "thumbs down: dark skin tone", "code": "\uD83D\uDC4E\uD83C\uDFFF" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "raised fist", "code": "\u270A" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "raised fist: light skin tone", "code": "\u270A\uD83C\uDFFB" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "raised fist: medium-light skin tone", "code": "\u270A\uD83C\uDFFC" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "raised fist: medium skin tone", "code": "\u270A\uD83C\uDFFD" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "raised fist: medium-dark skin tone", "code": "\u270A\uD83C\uDFFE" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "raised fist: dark skin tone", "code": "\u270A\uD83C\uDFFF" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "oncoming fist", "code": "\uD83D\uDC4A" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "oncoming fist: light skin tone", "code": "\uD83D\uDC4A\uD83C\uDFFB" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "oncoming fist: medium-light skin tone", "code": "\uD83D\uDC4A\uD83C\uDFFC" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "oncoming fist: medium skin tone", "code": "\uD83D\uDC4A\uD83C\uDFFD" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "oncoming fist: medium-dark skin tone", "code": "\uD83D\uDC4A\uD83C\uDFFE" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "oncoming fist: dark skin tone", "code": "\uD83D\uDC4A\uD83C\uDFFF" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "left-facing fist", "code": "\uD83E\uDD1B" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "left-facing fist: light skin tone", "code": "\uD83E\uDD1B\uD83C\uDFFB" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "left-facing fist: medium-light skin tone", "code": "\uD83E\uDD1B\uD83C\uDFFC" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "left-facing fist: medium skin tone", "code": "\uD83E\uDD1B\uD83C\uDFFD" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "left-facing fist: medium-dark skin tone", "code": "\uD83E\uDD1B\uD83C\uDFFE" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "left-facing fist: dark skin tone", "code": "\uD83E\uDD1B\uD83C\uDFFF" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "right-facing fist", "code": "\uD83E\uDD1C" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "right-facing fist: light skin tone", "code": "\uD83E\uDD1C\uD83C\uDFFB" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "right-facing fist: medium-light skin tone", "code": "\uD83E\uDD1C\uD83C\uDFFC" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "right-facing fist: medium skin tone", "code": "\uD83E\uDD1C\uD83C\uDFFD" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "right-facing fist: medium-dark skin tone", "code": "\uD83E\uDD1C\uD83C\uDFFE" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "right-facing fist: dark skin tone", "code": "\uD83E\uDD1C\uD83C\uDFFF" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "raised back of hand", "code": "\uD83E\uDD1A" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "raised back of hand: light skin tone", "code": "\uD83E\uDD1A\uD83C\uDFFB" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "raised back of hand: medium-light skin tone", "code": "\uD83E\uDD1A\uD83C\uDFFC" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "raised back of hand: medium skin tone", "code": "\uD83E\uDD1A\uD83C\uDFFD" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "raised back of hand: medium-dark skin tone", "code": "\uD83E\uDD1A\uD83C\uDFFE" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "raised back of hand: dark skin tone", "code": "\uD83E\uDD1A\uD83C\uDFFF" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "waving hand", "code": "\uD83D\uDC4B" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "waving hand: light skin tone", "code": "\uD83D\uDC4B\uD83C\uDFFB" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "waving hand: medium-light skin tone", "code": "\uD83D\uDC4B\uD83C\uDFFC" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "waving hand: medium skin tone", "code": "\uD83D\uDC4B\uD83C\uDFFD" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "waving hand: medium-dark skin tone", "code": "\uD83D\uDC4B\uD83C\uDFFE" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "waving hand: dark skin tone", "code": "\uD83D\uDC4B\uD83C\uDFFF" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "love-you gesture", "code": "\uD83E\uDD1F" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "love-you gesture: light skin tone", "code": "\uD83E\uDD1F\uD83C\uDFFB" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "love-you gesture: medium-light skin tone", "code": "\uD83E\uDD1F\uD83C\uDFFC" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "love-you gesture: medium skin tone", "code": "\uD83E\uDD1F\uD83C\uDFFD" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "love-you gesture: medium-dark skin tone", "code": "\uD83E\uDD1F\uD83C\uDFFE" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "love-you gesture: dark skin tone", "code": "\uD83E\uDD1F\uD83C\uDFFF" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "writing hand", "code": "\u270D\uFE0F" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "writing hand: light skin tone", "code": "\u270D\uD83C\uDFFB" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "writing hand: medium-light skin tone", "code": "\u270D\uD83C\uDFFC" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "writing hand: medium skin tone", "code": "\u270D\uD83C\uDFFD" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "writing hand: medium-dark skin tone", "code": "\u270D\uD83C\uDFFE" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "writing hand: dark skin tone", "code": "\u270D\uD83C\uDFFF" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "clapping hands", "code": "\uD83D\uDC4F" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "clapping hands: light skin tone", "code": "\uD83D\uDC4F\uD83C\uDFFB" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "clapping hands: medium-light skin tone", "code": "\uD83D\uDC4F\uD83C\uDFFC" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "clapping hands: medium skin tone", "code": "\uD83D\uDC4F\uD83C\uDFFD" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "clapping hands: medium-dark skin tone", "code": "\uD83D\uDC4F\uD83C\uDFFE" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "clapping hands: dark skin tone", "code": "\uD83D\uDC4F\uD83C\uDFFF" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "open hands", "code": "\uD83D\uDC50" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "open hands: light skin tone", "code": "\uD83D\uDC50\uD83C\uDFFB" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "open hands: medium-light skin tone", "code": "\uD83D\uDC50\uD83C\uDFFC" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "open hands: medium skin tone", "code": "\uD83D\uDC50\uD83C\uDFFD" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "open hands: medium-dark skin tone", "code": "\uD83D\uDC50\uD83C\uDFFE" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "open hands: dark skin tone", "code": "\uD83D\uDC50\uD83C\uDFFF" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "raising hands", "code": "\uD83D\uDE4C" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "raising hands: light skin tone", "code": "\uD83D\uDE4C\uD83C\uDFFB" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "raising hands: medium-light skin tone", "code": "\uD83D\uDE4C\uD83C\uDFFC" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "raising hands: medium skin tone", "code": "\uD83D\uDE4C\uD83C\uDFFD" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "raising hands: medium-dark skin tone", "code": "\uD83D\uDE4C\uD83C\uDFFE" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "raising hands: dark skin tone", "code": "\uD83D\uDE4C\uD83C\uDFFF" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "palms up together", "code": "\uD83E\uDD32" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "palms up together: light skin tone", "code": "\uD83E\uDD32\uD83C\uDFFB" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "palms up together: medium-light skin tone", "code": "\uD83E\uDD32\uD83C\uDFFC" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "palms up together: medium skin tone", "code": "\uD83E\uDD32\uD83C\uDFFD" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "palms up together: medium-dark skin tone", "code": "\uD83E\uDD32\uD83C\uDFFE" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "palms up together: dark skin tone", "code": "\uD83E\uDD32\uD83C\uDFFF" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "folded hands", "code": "\uD83D\uDE4F" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "folded hands: light skin tone", "code": "\uD83D\uDE4F\uD83C\uDFFB" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "folded hands: medium-light skin tone", "code": "\uD83D\uDE4F\uD83C\uDFFC" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "folded hands: medium skin tone", "code": "\uD83D\uDE4F\uD83C\uDFFD" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "folded hands: medium-dark skin tone", "code": "\uD83D\uDE4F\uD83C\uDFFE" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "folded hands: dark skin tone", "code": "\uD83D\uDE4F\uD83C\uDFFF" },
            { "category": "smileys_n_people", "keyword": "handshake", "code": "\uD83E\uDD1D" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "nail polish", "code": "\uD83D\uDC85" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "nail polish: light skin tone", "code": "\uD83D\uDC85\uD83C\uDFFB" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "nail polish: medium-light skin tone", "code": "\uD83D\uDC85\uD83C\uDFFC" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "nail polish: medium skin tone", "code": "\uD83D\uDC85\uD83C\uDFFD" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "nail polish: medium-dark skin tone", "code": "\uD83D\uDC85\uD83C\uDFFE" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "nail polish: dark skin tone", "code": "\uD83D\uDC85\uD83C\uDFFF" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "ear", "code": "\uD83D\uDC42" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "ear: light skin tone", "code": "\uD83D\uDC42\uD83C\uDFFB" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "ear: medium-light skin tone", "code": "\uD83D\uDC42\uD83C\uDFFC" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "ear: medium skin tone", "code": "\uD83D\uDC42\uD83C\uDFFD" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "ear: medium-dark skin tone", "code": "\uD83D\uDC42\uD83C\uDFFE" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "ear: dark skin tone", "code": "\uD83D\uDC42\uD83C\uDFFF" },
            { "category": "smileys_n_people", "tone": "default", "keyword": "nose", "code": "\uD83D\uDC43" },
            { "category": "smileys_n_people", "tone": "light", "keyword": "nose: light skin tone", "code": "\uD83D\uDC43\uD83C\uDFFB" },
            { "category": "smileys_n_people", "tone": "medium-light", "keyword": "nose: medium-light skin tone", "code": "\uD83D\uDC43\uD83C\uDFFC" },
            { "category": "smileys_n_people", "tone": "medium", "keyword": "nose: medium skin tone", "code": "\uD83D\uDC43\uD83C\uDFFD" },
            { "category": "smileys_n_people", "tone": "medium-dark", "keyword": "nose: medium-dark skin tone", "code": "\uD83D\uDC43\uD83C\uDFFE" },
            { "category": "smileys_n_people", "tone": "dark", "keyword": "nose: dark skin tone", "code": "\uD83D\uDC43\uD83C\uDFFF" },
            //{ "category": "smileys_n_people", "keyword": "red-haired", "code": "\uD83E\uDDB0" },
            //{ "category": "smileys_n_people", "keyword": "curly-haired", "code": "\uD83E\uDDB1" },
            //{ "category": "smileys_n_people", "keyword": "bald", "code": "\uD83E\uDDB2" },
            //{ "category": "smileys_n_people", "keyword": "white-haired", "code": "\uD83E\uDDB3" },
            { "category": "smileys_n_people", "keyword": "footprints", "code": "\uD83D\uDC63" },
            { "category": "smileys_n_people", "keyword": "eyes", "code": "\uD83D\uDC40" },
            { "category": "smileys_n_people", "keyword": "eye", "code": "\uD83D\uDC41\uFE0F" },
            { "category": "smileys_n_people", "keyword": "eye in speech bubble", "code": "\uD83D\uDC41\uFE0F\u200D\uD83D\uDDE8\uFE0F" },
            { "category": "smileys_n_people", "keyword": "brain", "code": "\uD83E\uDDE0" },
            { "category": "smileys_n_people", "keyword": "bone", "code": "\uD83E\uDDB4" },
            { "category": "smileys_n_people", "keyword": "tooth", "code": "\uD83E\uDDB7" },
            { "category": "smileys_n_people", "keyword": "tongue", "code": "\uD83D\uDC45" },
            { "category": "smileys_n_people", "keyword": "mouth", "code": "\uD83D\uDC44" },
            { "category": "smileys_n_people", "keyword": "kiss mark", "code": "\uD83D\uDC8B" },
            { "category": "smileys_n_people", "keyword": "heart with arrow", "code": "\uD83D\uDC98" },
            { "category": "smileys_n_people", "keyword": "red heart", "code": "\u2764\uFE0F" },
            { "category": "smileys_n_people", "keyword": "beating heart", "code": "\uD83D\uDC93" },
            { "category": "smileys_n_people", "keyword": "broken heart", "code": "\uD83D\uDC94" },
            { "category": "smileys_n_people", "keyword": "two hearts", "code": "\uD83D\uDC95" },
            { "category": "smileys_n_people", "keyword": "sparkling heart", "code": "\uD83D\uDC96" },
            { "category": "smileys_n_people", "keyword": "growing heart", "code": "\uD83D\uDC97" },
            { "category": "smileys_n_people", "keyword": "blue heart", "code": "\uD83D\uDC99" },
            { "category": "smileys_n_people", "keyword": "green heart", "code": "\uD83D\uDC9A" },
            { "category": "smileys_n_people", "keyword": "yellow heart", "code": "\uD83D\uDC9B" },
            { "category": "smileys_n_people", "keyword": "orange heart", "code": "\uD83E\uDDE1" },
            { "category": "smileys_n_people", "keyword": "purple heart", "code": "\uD83D\uDC9C" },
            { "category": "smileys_n_people", "keyword": "black heart", "code": "\uD83D\uDDA4" },
            { "category": "smileys_n_people", "keyword": "heart with ribbon", "code": "\uD83D\uDC9D" },
            { "category": "smileys_n_people", "keyword": "revolving hearts", "code": "\uD83D\uDC9E" },
            { "category": "smileys_n_people", "keyword": "heart decoration", "code": "\uD83D\uDC9F" },
            { "category": "smileys_n_people", "keyword": "heavy heart exclamation", "code": "\u2763\uFE0F" },
            { "category": "smileys_n_people", "keyword": "love letter", "code": "\uD83D\uDC8C" },
            { "category": "smileys_n_people", "keyword": "zzz", "code": "\uD83D\uDCA4" },
            { "category": "smileys_n_people", "keyword": "anger symbol", "code": "\uD83D\uDCA2" },
            { "category": "smileys_n_people", "keyword": "bomb", "code": "\uD83D\uDCA3" },
            { "category": "smileys_n_people", "keyword": "collision", "code": "\uD83D\uDCA5" },
            { "category": "smileys_n_people", "keyword": "sweat droplets", "code": "\uD83D\uDCA6" },
            { "category": "smileys_n_people", "keyword": "dashing away", "code": "\uD83D\uDCA8" },
            { "category": "smileys_n_people", "keyword": "dizzy", "code": "\uD83D\uDCAB" },
            { "category": "smileys_n_people", "keyword": "speech balloon", "code": "\uD83D\uDCAC" },
            { "category": "smileys_n_people", "keyword": "left speech bubble", "code": "\uD83D\uDDE8\uFE0F" },
            { "category": "smileys_n_people", "keyword": "right anger bubble", "code": "\uD83D\uDDEF\uFE0F" },
            { "category": "smileys_n_people", "keyword": "thought balloon", "code": "\uD83D\uDCAD" },
            { "category": "smileys_n_people", "keyword": "hole", "code": "\uD83D\uDD73\uFE0F" },
            { "category": "smileys_n_people", "keyword": "glasses", "code": "\uD83D\uDC53" },
            { "category": "smileys_n_people", "keyword": "sunglasses", "code": "\uD83D\uDD76\uFE0F" },
            { "category": "smileys_n_people", "keyword": "goggles", "code": "\uD83E\uDD7D" },
            { "category": "smileys_n_people", "keyword": "lab coat", "code": "\uD83E\uDD7C" },
            { "category": "smileys_n_people", "keyword": "necktie", "code": "\uD83D\uDC54" },
            { "category": "smileys_n_people", "keyword": "t-shirt", "code": "\uD83D\uDC55" },
            { "category": "smileys_n_people", "keyword": "jeans", "code": "\uD83D\uDC56" },
            { "category": "smileys_n_people", "keyword": "scarf", "code": "\uD83E\uDDE3" },
            { "category": "smileys_n_people", "keyword": "gloves", "code": "\uD83E\uDDE4" },
            { "category": "smileys_n_people", "keyword": "coat", "code": "\uD83E\uDDE5" },
            { "category": "smileys_n_people", "keyword": "socks", "code": "\uD83E\uDDE6" },
            { "category": "smileys_n_people", "keyword": "dress", "code": "\uD83D\uDC57" },
            { "category": "smileys_n_people", "keyword": "kimono", "code": "\uD83D\uDC58" },
            { "category": "smileys_n_people", "keyword": "bikini", "code": "\uD83D\uDC59" },
            { "category": "smileys_n_people", "keyword": "woman’s clothes", "code": "\uD83D\uDC5A" },
            { "category": "smileys_n_people", "keyword": "purse", "code": "\uD83D\uDC5B" },
            { "category": "smileys_n_people", "keyword": "handbag", "code": "\uD83D\uDC5C" },
            { "category": "smileys_n_people", "keyword": "clutch bag", "code": "\uD83D\uDC5D" },
            { "category": "smileys_n_people", "keyword": "shopping bags", "code": "\uD83D\uDECD\uFE0F" },
            { "category": "smileys_n_people", "keyword": "school backpack", "code": "\uD83C\uDF92" },
            { "category": "smileys_n_people", "keyword": "man’s shoe", "code": "\uD83D\uDC5E" },
            { "category": "smileys_n_people", "keyword": "running shoe", "code": "\uD83D\uDC5F" },
            { "category": "smileys_n_people", "keyword": "hiking boot", "code": "\uD83E\uDD7E" },
            { "category": "smileys_n_people", "keyword": "woman’s flat shoe", "code": "\uD83E\uDD7F" },
            { "category": "smileys_n_people", "keyword": "high-heeled shoe", "code": "\uD83D\uDC60" },
            { "category": "smileys_n_people", "keyword": "woman’s sandal", "code": "\uD83D\uDC61" },
            { "category": "smileys_n_people", "keyword": "woman’s boot", "code": "\uD83D\uDC62" },
            { "category": "smileys_n_people", "keyword": "crown", "code": "\uD83D\uDC51" },
            { "category": "smileys_n_people", "keyword": "woman’s hat", "code": "\uD83D\uDC52" },
            { "category": "smileys_n_people", "keyword": "top hat", "code": "\uD83C\uDFA9" },
            { "category": "smileys_n_people", "keyword": "graduation cap", "code": "\uD83C\uDF93" },
            { "category": "smileys_n_people", "keyword": "billed cap", "code": "\uD83E\uDDE2" },
            { "category": "smileys_n_people", "keyword": "rescue worker’s helmet", "code": "\u26D1\uFE0F" },
            { "category": "smileys_n_people", "keyword": "prayer beads", "code": "\uD83D\uDCFF" },
            { "category": "smileys_n_people", "keyword": "lipstick", "code": "\uD83D\uDC84" },
            { "category": "smileys_n_people", "keyword": "ring", "code": "\uD83D\uDC8D" },
            { "category": "smileys_n_people", "keyword": "gem stone", "code": "\uD83D\uDC8E" },
            { "category": "animals_n_nature", "keyword": "monkey face", "code": "\uD83D\uDC35" },
            { "category": "animals_n_nature", "keyword": "monkey", "code": "\uD83D\uDC12" },
            { "category": "animals_n_nature", "keyword": "gorilla", "code": "\uD83E\uDD8D" },
            { "category": "animals_n_nature", "keyword": "dog face", "code": "\uD83D\uDC36" },
            { "category": "animals_n_nature", "keyword": "dog", "code": "\uD83D\uDC15" },
            { "category": "animals_n_nature", "keyword": "poodle", "code": "\uD83D\uDC29" },
            { "category": "animals_n_nature", "keyword": "wolf face", "code": "\uD83D\uDC3A" },
            { "category": "animals_n_nature", "keyword": "fox face", "code": "\uD83E\uDD8A" },
            { "category": "animals_n_nature", "keyword": "raccoon", "code": "\uD83E\uDD9D" },
            { "category": "animals_n_nature", "keyword": "cat face", "code": "\uD83D\uDC31" },
            { "category": "animals_n_nature", "keyword": "cat", "code": "\uD83D\uDC08" },
            { "category": "animals_n_nature", "keyword": "lion face", "code": "\uD83E\uDD81" },
            { "category": "animals_n_nature", "keyword": "tiger face", "code": "\uD83D\uDC2F" },
            { "category": "animals_n_nature", "keyword": "tiger", "code": "\uD83D\uDC05" },
            { "category": "animals_n_nature", "keyword": "leopard", "code": "\uD83D\uDC06" },
            { "category": "animals_n_nature", "keyword": "horse face", "code": "\uD83D\uDC34" },
            { "category": "animals_n_nature", "keyword": "horse", "code": "\uD83D\uDC0E" },
            { "category": "animals_n_nature", "keyword": "unicorn face", "code": "\uD83E\uDD84" },
            { "category": "animals_n_nature", "keyword": "zebra", "code": "\uD83E\uDD93" },
            { "category": "animals_n_nature", "keyword": "deer", "code": "\uD83E\uDD8C" },
            { "category": "animals_n_nature", "keyword": "cow face", "code": "\uD83D\uDC2E" },
            { "category": "animals_n_nature", "keyword": "ox", "code": "\uD83D\uDC02" },
            { "category": "animals_n_nature", "keyword": "water buffalo", "code": "\uD83D\uDC03" },
            { "category": "animals_n_nature", "keyword": "cow", "code": "\uD83D\uDC04" },
            { "category": "animals_n_nature", "keyword": "pig face", "code": "\uD83D\uDC37" },
            { "category": "animals_n_nature", "keyword": "pig", "code": "\uD83D\uDC16" },
            { "category": "animals_n_nature", "keyword": "boar", "code": "\uD83D\uDC17" },
            { "category": "animals_n_nature", "keyword": "pig nose", "code": "\uD83D\uDC3D" },
            { "category": "animals_n_nature", "keyword": "ram", "code": "\uD83D\uDC0F" },
            { "category": "animals_n_nature", "keyword": "ewe", "code": "\uD83D\uDC11" },
            { "category": "animals_n_nature", "keyword": "goat", "code": "\uD83D\uDC10" },
            { "category": "animals_n_nature", "keyword": "camel", "code": "\uD83D\uDC2A" },
            { "category": "animals_n_nature", "keyword": "two-hump camel", "code": "\uD83D\uDC2B" },
            { "category": "animals_n_nature", "keyword": "llama", "code": "\uD83E\uDD99" },
            { "category": "animals_n_nature", "keyword": "giraffe", "code": "\uD83E\uDD92" },
            { "category": "animals_n_nature", "keyword": "elephant", "code": "\uD83D\uDC18" },
            { "category": "animals_n_nature", "keyword": "rhinoceros", "code": "\uD83E\uDD8F" },
            { "category": "animals_n_nature", "keyword": "hippopotamus", "code": "\uD83E\uDD9B" },
            { "category": "animals_n_nature", "keyword": "mouse face", "code": "\uD83D\uDC2D" },
            { "category": "animals_n_nature", "keyword": "mouse", "code": "\uD83D\uDC01" },
            { "category": "animals_n_nature", "keyword": "rat", "code": "\uD83D\uDC00" },
            { "category": "animals_n_nature", "keyword": "hamster face", "code": "\uD83D\uDC39" },
            { "category": "animals_n_nature", "keyword": "rabbit face", "code": "\uD83D\uDC30" },
            { "category": "animals_n_nature", "keyword": "rabbit", "code": "\uD83D\uDC07" },
            { "category": "animals_n_nature", "keyword": "chipmunk", "code": "\uD83D\uDC3F\uFE0F" },
            { "category": "animals_n_nature", "keyword": "hedgehog", "code": "\uD83E\uDD94" },
            { "category": "animals_n_nature", "keyword": "bat", "code": "\uD83E\uDD87" },
            { "category": "animals_n_nature", "keyword": "bear face", "code": "\uD83D\uDC3B" },
            { "category": "animals_n_nature", "keyword": "koala", "code": "\uD83D\uDC28" },
            { "category": "animals_n_nature", "keyword": "panda face", "code": "\uD83D\uDC3C" },
            { "category": "animals_n_nature", "keyword": "kangaroo", "code": "\uD83E\uDD98" },
            { "category": "animals_n_nature", "keyword": "badger", "code": "\uD83E\uDDA1" },
            { "category": "animals_n_nature", "keyword": "paw prints", "code": "\uD83D\uDC3E" },
            { "category": "animals_n_nature", "keyword": "turkey", "code": "\uD83E\uDD83" },
            { "category": "animals_n_nature", "keyword": "chicken", "code": "\uD83D\uDC14" },
            { "category": "animals_n_nature", "keyword": "rooster", "code": "\uD83D\uDC13" },
            { "category": "animals_n_nature", "keyword": "hatching chick", "code": "\uD83D\uDC23" },
            { "category": "animals_n_nature", "keyword": "baby chick", "code": "\uD83D\uDC24" },
            { "category": "animals_n_nature", "keyword": "front-facing baby chick", "code": "\uD83D\uDC25" },
            { "category": "animals_n_nature", "keyword": "bird", "code": "\uD83D\uDC26" },
            { "category": "animals_n_nature", "keyword": "penguin", "code": "\uD83D\uDC27" },
            { "category": "animals_n_nature", "keyword": "dove", "code": "\uD83D\uDD4A\uFE0F" },
            { "category": "animals_n_nature", "keyword": "eagle", "code": "\uD83E\uDD85" },
            { "category": "animals_n_nature", "keyword": "duck", "code": "\uD83E\uDD86" },
            { "category": "animals_n_nature", "keyword": "swan", "code": "\uD83E\uDDA2" },
            { "category": "animals_n_nature", "keyword": "owl", "code": "\uD83E\uDD89" },
            { "category": "animals_n_nature", "keyword": "peacock", "code": "\uD83E\uDD9A" },
            { "category": "animals_n_nature", "keyword": "parrot", "code": "\uD83E\uDD9C" },
            { "category": "animals_n_nature", "keyword": "frog face", "code": "\uD83D\uDC38" },
            { "category": "animals_n_nature", "keyword": "crocodile", "code": "\uD83D\uDC0A" },
            { "category": "animals_n_nature", "keyword": "turtle", "code": "\uD83D\uDC22" },
            { "category": "animals_n_nature", "keyword": "lizard", "code": "\uD83E\uDD8E" },
            { "category": "animals_n_nature", "keyword": "snake", "code": "\uD83D\uDC0D" },
            { "category": "animals_n_nature", "keyword": "dragon face", "code": "\uD83D\uDC32" },
            { "category": "animals_n_nature", "keyword": "dragon", "code": "\uD83D\uDC09" },
            { "category": "animals_n_nature", "keyword": "sauropod", "code": "\uD83E\uDD95" },
            { "category": "animals_n_nature", "keyword": "T-Rex", "code": "\uD83E\uDD96" },
            { "category": "animals_n_nature", "keyword": "spouting whale", "code": "\uD83D\uDC33" },
            { "category": "animals_n_nature", "keyword": "whale", "code": "\uD83D\uDC0B" },
            { "category": "animals_n_nature", "keyword": "dolphin", "code": "\uD83D\uDC2C" },
            { "category": "animals_n_nature", "keyword": "fish", "code": "\uD83D\uDC1F" },
            { "category": "animals_n_nature", "keyword": "tropical fish", "code": "\uD83D\uDC20" },
            { "category": "animals_n_nature", "keyword": "blowfish", "code": "\uD83D\uDC21" },
            { "category": "animals_n_nature", "keyword": "shark", "code": "\uD83E\uDD88" },
            { "category": "animals_n_nature", "keyword": "octopus", "code": "\uD83D\uDC19" },
            { "category": "animals_n_nature", "keyword": "spiral shell", "code": "\uD83D\uDC1A" },
            { "category": "animals_n_nature", "keyword": "crab", "code": "\uD83E\uDD80" },
            { "category": "animals_n_nature", "keyword": "lobster", "code": "\uD83E\uDD9E" },
            { "category": "animals_n_nature", "keyword": "shrimp", "code": "\uD83E\uDD90" },
            { "category": "animals_n_nature", "keyword": "squid", "code": "\uD83E\uDD91" },
            { "category": "animals_n_nature", "keyword": "snail", "code": "\uD83D\uDC0C" },
            { "category": "animals_n_nature", "keyword": "butterfly", "code": "\uD83E\uDD8B" },
            { "category": "animals_n_nature", "keyword": "bug", "code": "\uD83D\uDC1B" },
            { "category": "animals_n_nature", "keyword": "ant", "code": "\uD83D\uDC1C" },
            { "category": "animals_n_nature", "keyword": "honeybee", "code": "\uD83D\uDC1D" },
            { "category": "animals_n_nature", "keyword": "lady beetle", "code": "\uD83D\uDC1E" },
            { "category": "animals_n_nature", "keyword": "cricket", "code": "\uD83E\uDD97" },
            { "category": "animals_n_nature", "keyword": "spider", "code": "\uD83D\uDD77\uFE0F" },
            { "category": "animals_n_nature", "keyword": "spider web", "code": "\uD83D\uDD78\uFE0F" },
            { "category": "animals_n_nature", "keyword": "scorpion", "code": "\uD83E\uDD82" },
            { "category": "animals_n_nature", "keyword": "mosquito", "code": "\uD83E\uDD9F" },
            { "category": "animals_n_nature", "keyword": "microbe", "code": "\uD83E\uDDA0" },
            { "category": "animals_n_nature", "keyword": "bouquet", "code": "\uD83D\uDC90" },
            { "category": "animals_n_nature", "keyword": "cherry blossom", "code": "\uD83C\uDF38" },
            { "category": "animals_n_nature", "keyword": "white flower", "code": "\uD83D\uDCAE" },
            { "category": "animals_n_nature", "keyword": "rosette", "code": "\uD83C\uDFF5\uFE0F" },
            { "category": "animals_n_nature", "keyword": "rose", "code": "\uD83C\uDF39" },
            { "category": "animals_n_nature", "keyword": "wilted flower", "code": "\uD83E\uDD40" },
            { "category": "animals_n_nature", "keyword": "hibiscus", "code": "\uD83C\uDF3A" },
            { "category": "animals_n_nature", "keyword": "sunflower", "code": "\uD83C\uDF3B" },
            { "category": "animals_n_nature", "keyword": "blossom", "code": "\uD83C\uDF3C" },
            { "category": "animals_n_nature", "keyword": "tulip", "code": "\uD83C\uDF37" },
            { "category": "animals_n_nature", "keyword": "seedling", "code": "\uD83C\uDF31" },
            { "category": "animals_n_nature", "keyword": "evergreen tree", "code": "\uD83C\uDF32" },
            { "category": "animals_n_nature", "keyword": "deciduous tree", "code": "\uD83C\uDF33" },
            { "category": "animals_n_nature", "keyword": "palm tree", "code": "\uD83C\uDF34" },
            { "category": "animals_n_nature", "keyword": "cactus", "code": "\uD83C\uDF35" },
            { "category": "animals_n_nature", "keyword": "sheaf of rice", "code": "\uD83C\uDF3E" },
            { "category": "animals_n_nature", "keyword": "herb", "code": "\uD83C\uDF3F" },
            { "category": "animals_n_nature", "keyword": "shamrock", "code": "\u2618\uFE0F" },
            { "category": "animals_n_nature", "keyword": "four leaf clover", "code": "\uD83C\uDF40" },
            { "category": "animals_n_nature", "keyword": "maple leaf", "code": "\uD83C\uDF41" },
            { "category": "animals_n_nature", "keyword": "fallen leaf", "code": "\uD83C\uDF42" },
            { "category": "animals_n_nature", "keyword": "leaf fluttering in wind", "code": "\uD83C\uDF43" },
            { "category": "food_n_drink", "keyword": "grapes", "code": "\uD83C\uDF47" },
            { "category": "food_n_drink", "keyword": "melon", "code": "\uD83C\uDF48" },
            { "category": "food_n_drink", "keyword": "watermelon", "code": "\uD83C\uDF49" },
            { "category": "food_n_drink", "keyword": "tangerine", "code": "\uD83C\uDF4A" },
            { "category": "food_n_drink", "keyword": "lemon", "code": "\uD83C\uDF4B" },
            { "category": "food_n_drink", "keyword": "banana", "code": "\uD83C\uDF4C" },
            { "category": "food_n_drink", "keyword": "pineapple", "code": "\uD83C\uDF4D" },
            { "category": "food_n_drink", "keyword": "mango", "code": "\uD83E\uDD6D" },
            { "category": "food_n_drink", "keyword": "red apple", "code": "\uD83C\uDF4E" },
            { "category": "food_n_drink", "keyword": "green apple", "code": "\uD83C\uDF4F" },
            { "category": "food_n_drink", "keyword": "pear", "code": "\uD83C\uDF50" },
            { "category": "food_n_drink", "keyword": "peach", "code": "\uD83C\uDF51" },
            { "category": "food_n_drink", "keyword": "cherries", "code": "\uD83C\uDF52" },
            { "category": "food_n_drink", "keyword": "strawberry", "code": "\uD83C\uDF53" },
            { "category": "food_n_drink", "keyword": "kiwi fruit", "code": "\uD83E\uDD5D" },
            { "category": "food_n_drink", "keyword": "tomato", "code": "\uD83C\uDF45" },
            { "category": "food_n_drink", "keyword": "coconut", "code": "\uD83E\uDD65" },
            { "category": "food_n_drink", "keyword": "avocado", "code": "\uD83E\uDD51" },
            { "category": "food_n_drink", "keyword": "eggplant", "code": "\uD83C\uDF46" },
            { "category": "food_n_drink", "keyword": "potato", "code": "\uD83E\uDD54" },
            { "category": "food_n_drink", "keyword": "carrot", "code": "\uD83E\uDD55" },
            { "category": "food_n_drink", "keyword": "ear of corn", "code": "\uD83C\uDF3D" },
            { "category": "food_n_drink", "keyword": "hot pepper", "code": "\uD83C\uDF36\uFE0F" },
            { "category": "food_n_drink", "keyword": "cucumber", "code": "\uD83E\uDD52" },
            { "category": "food_n_drink", "keyword": "leafy green", "code": "\uD83E\uDD6C" },
            { "category": "food_n_drink", "keyword": "broccoli", "code": "\uD83E\uDD66" },
            { "category": "food_n_drink", "keyword": "mushroom", "code": "\uD83C\uDF44" },
            { "category": "food_n_drink", "keyword": "peanuts", "code": "\uD83E\uDD5C" },
            { "category": "food_n_drink", "keyword": "chestnut", "code": "\uD83C\uDF30" },
            { "category": "food_n_drink", "keyword": "bread", "code": "\uD83C\uDF5E" },
            { "category": "food_n_drink", "keyword": "croissant", "code": "\uD83E\uDD50" },
            { "category": "food_n_drink", "keyword": "baguette bread", "code": "\uD83E\uDD56" },
            { "category": "food_n_drink", "keyword": "pretzel", "code": "\uD83E\uDD68" },
            { "category": "food_n_drink", "keyword": "bagel", "code": "\uD83E\uDD6F" },
            { "category": "food_n_drink", "keyword": "pancakes", "code": "\uD83E\uDD5E" },
            { "category": "food_n_drink", "keyword": "cheese wedge", "code": "\uD83E\uDDC0" },
            { "category": "food_n_drink", "keyword": "meat on bone", "code": "\uD83C\uDF56" },
            { "category": "food_n_drink", "keyword": "poultry leg", "code": "\uD83C\uDF57" },
            { "category": "food_n_drink", "keyword": "cut of meat", "code": "\uD83E\uDD69" },
            { "category": "food_n_drink", "keyword": "bacon", "code": "\uD83E\uDD53" },
            { "category": "food_n_drink", "keyword": "hamburger", "code": "\uD83C\uDF54" },
            { "category": "food_n_drink", "keyword": "french fries", "code": "\uD83C\uDF5F" },
            { "category": "food_n_drink", "keyword": "pizza", "code": "\uD83C\uDF55" },
            { "category": "food_n_drink", "keyword": "hot dog", "code": "\uD83C\uDF2D" },
            { "category": "food_n_drink", "keyword": "sandwich", "code": "\uD83E\uDD6A" },
            { "category": "food_n_drink", "keyword": "taco", "code": "\uD83C\uDF2E" },
            { "category": "food_n_drink", "keyword": "burrito", "code": "\uD83C\uDF2F" },
            { "category": "food_n_drink", "keyword": "stuffed flatbread", "code": "\uD83E\uDD59" },
            { "category": "food_n_drink", "keyword": "egg", "code": "\uD83E\uDD5A" },
            { "category": "food_n_drink", "keyword": "cooking", "code": "\uD83C\uDF73" },
            { "category": "food_n_drink", "keyword": "shallow pan of food", "code": "\uD83E\uDD58" },
            { "category": "food_n_drink", "keyword": "pot of food", "code": "\uD83C\uDF72" },
            { "category": "food_n_drink", "keyword": "bowl with spoon", "code": "\uD83E\uDD63" },
            { "category": "food_n_drink", "keyword": "green salad", "code": "\uD83E\uDD57" },
            { "category": "food_n_drink", "keyword": "popcorn", "code": "\uD83C\uDF7F" },
            { "category": "food_n_drink", "keyword": "salt", "code": "\uD83E\uDDC2" },
            { "category": "food_n_drink", "keyword": "canned food", "code": "\uD83E\uDD6B" },
            { "category": "food_n_drink", "keyword": "bento box", "code": "\uD83C\uDF71" },
            { "category": "food_n_drink", "keyword": "rice cracker", "code": "\uD83C\uDF58" },
            { "category": "food_n_drink", "keyword": "rice ball", "code": "\uD83C\uDF59" },
            { "category": "food_n_drink", "keyword": "cooked rice", "code": "\uD83C\uDF5A" },
            { "category": "food_n_drink", "keyword": "curry rice", "code": "\uD83C\uDF5B" },
            { "category": "food_n_drink", "keyword": "steaming bowl", "code": "\uD83C\uDF5C" },
            { "category": "food_n_drink", "keyword": "spaghetti", "code": "\uD83C\uDF5D" },
            { "category": "food_n_drink", "keyword": "roasted sweet potato", "code": "\uD83C\uDF60" },
            { "category": "food_n_drink", "keyword": "oden", "code": "\uD83C\uDF62" },
            { "category": "food_n_drink", "keyword": "sushi", "code": "\uD83C\uDF63" },
            { "category": "food_n_drink", "keyword": "fried shrimp", "code": "\uD83C\uDF64" },
            { "category": "food_n_drink", "keyword": "fish cake with swirl", "code": "\uD83C\uDF65" },
            { "category": "food_n_drink", "keyword": "moon cake", "code": "\uD83E\uDD6E" },
            { "category": "food_n_drink", "keyword": "dango", "code": "\uD83C\uDF61" },
            { "category": "food_n_drink", "keyword": "dumpling", "code": "\uD83E\uDD5F" },
            { "category": "food_n_drink", "keyword": "fortune cookie", "code": "\uD83E\uDD60" },
            { "category": "food_n_drink", "keyword": "takeout box", "code": "\uD83E\uDD61" },
            { "category": "food_n_drink", "keyword": "soft ice cream", "code": "\uD83C\uDF66" },
            { "category": "food_n_drink", "keyword": "shaved ice", "code": "\uD83C\uDF67" },
            { "category": "food_n_drink", "keyword": "ice cream", "code": "\uD83C\uDF68" },
            { "category": "food_n_drink", "keyword": "doughnut", "code": "\uD83C\uDF69" },
            { "category": "food_n_drink", "keyword": "cookie", "code": "\uD83C\uDF6A" },
            { "category": "food_n_drink", "keyword": "birthday cake", "code": "\uD83C\uDF82" },
            { "category": "food_n_drink", "keyword": "shortcake", "code": "\uD83C\uDF70" },
            { "category": "food_n_drink", "keyword": "cupcake", "code": "\uD83E\uDDC1" },
            { "category": "food_n_drink", "keyword": "pie", "code": "\uD83E\uDD67" },
            { "category": "food_n_drink", "keyword": "chocolate bar", "code": "\uD83C\uDF6B" },
            { "category": "food_n_drink", "keyword": "candy", "code": "\uD83C\uDF6C" },
            { "category": "food_n_drink", "keyword": "lollipop", "code": "\uD83C\uDF6D" },
            { "category": "food_n_drink", "keyword": "custard", "code": "\uD83C\uDF6E" },
            { "category": "food_n_drink", "keyword": "honey pot", "code": "\uD83C\uDF6F" },
            { "category": "food_n_drink", "keyword": "baby bottle", "code": "\uD83C\uDF7C" },
            { "category": "food_n_drink", "keyword": "glass of milk", "code": "\uD83E\uDD5B" },
            { "category": "food_n_drink", "keyword": "hot beverage", "code": "\u2615" },
            { "category": "food_n_drink", "keyword": "teacup without handle", "code": "\uD83C\uDF75" },
            { "category": "food_n_drink", "keyword": "sake", "code": "\uD83C\uDF76" },
            { "category": "food_n_drink", "keyword": "bottle with popping cork", "code": "\uD83C\uDF7E" },
            { "category": "food_n_drink", "keyword": "wine glass", "code": "\uD83C\uDF77" },
            { "category": "food_n_drink", "keyword": "cocktail glass", "code": "\uD83C\uDF78" },
            { "category": "food_n_drink", "keyword": "tropical drink", "code": "\uD83C\uDF79" },
            { "category": "food_n_drink", "keyword": "beer mug", "code": "\uD83C\uDF7A" },
            { "category": "food_n_drink", "keyword": "clinking beer mugs", "code": "\uD83C\uDF7B" },
            { "category": "food_n_drink", "keyword": "clinking glasses", "code": "\uD83E\uDD42" },
            { "category": "food_n_drink", "keyword": "tumbler glass", "code": "\uD83E\uDD43" },
            { "category": "food_n_drink", "keyword": "cup with straw", "code": "\uD83E\uDD64" },
            { "category": "food_n_drink", "keyword": "chopsticks", "code": "\uD83E\uDD62" },
            { "category": "food_n_drink", "keyword": "fork and knife with plate", "code": "\uD83C\uDF7D\uFE0F" },
            { "category": "food_n_drink", "keyword": "fork and knife", "code": "\uD83C\uDF74" },
            { "category": "food_n_drink", "keyword": "spoon", "code": "\uD83E\uDD44" },
            { "category": "food_n_drink", "keyword": "kitchen knife", "code": "\uD83D\uDD2A" },
            { "category": "food_n_drink", "keyword": "amphora", "code": "\uD83C\uDFFA" },
            { "category": "travel_n_places", "keyword": "globe showing Europe-Africa", "code": "\uD83C\uDF0D" },
            { "category": "travel_n_places", "keyword": "globe showing Americas", "code": "\uD83C\uDF0E" },
            { "category": "travel_n_places", "keyword": "globe showing Asia-Australia", "code": "\uD83C\uDF0F" },
            { "category": "travel_n_places", "keyword": "globe with meridians", "code": "\uD83C\uDF10" },
            { "category": "travel_n_places", "keyword": "world map", "code": "\uD83D\uDDFA\uFE0F" },
            { "category": "travel_n_places", "keyword": "map of Japan", "code": "\uD83D\uDDFE" },
            { "category": "travel_n_places", "keyword": "compass", "code": "\uD83E\uDDED" },
            { "category": "travel_n_places", "keyword": "snow-capped mountain", "code": "\uD83C\uDFD4\uFE0F" },
            { "category": "travel_n_places", "keyword": "mountain", "code": "\u26F0\uFE0F" },
            { "category": "travel_n_places", "keyword": "volcano", "code": "\uD83C\uDF0B" },
            { "category": "travel_n_places", "keyword": "mount fuji", "code": "\uD83D\uDDFB" },
            { "category": "travel_n_places", "keyword": "camping", "code": "\uD83C\uDFD5\uFE0F" },
            { "category": "travel_n_places", "keyword": "beach with umbrella", "code": "\uD83C\uDFD6\uFE0F" },
            { "category": "travel_n_places", "keyword": "desert", "code": "\uD83C\uDFDC\uFE0F" },
            { "category": "travel_n_places", "keyword": "desert island", "code": "\uD83C\uDFDD\uFE0F" },
            { "category": "travel_n_places", "keyword": "national park", "code": "\uD83C\uDFDE\uFE0F" },
            { "category": "travel_n_places", "keyword": "stadium", "code": "\uD83C\uDFDF\uFE0F" },
            { "category": "travel_n_places", "keyword": "classical building", "code": "\uD83C\uDFDB\uFE0F" },
            { "category": "travel_n_places", "keyword": "building construction", "code": "\uD83C\uDFD7\uFE0F" },
            { "category": "travel_n_places", "keyword": "bricks", "code": "\uD83E\uDDF1" },
            { "category": "travel_n_places", "keyword": "houses", "code": "\uD83C\uDFD8\uFE0F" },
            { "category": "travel_n_places", "keyword": "derelict house", "code": "\uD83C\uDFDA\uFE0F" },
            { "category": "travel_n_places", "keyword": "house", "code": "\uD83C\uDFE0" },
            { "category": "travel_n_places", "keyword": "house with garden", "code": "\uD83C\uDFE1" },
            { "category": "travel_n_places", "keyword": "office building", "code": "\uD83C\uDFE2" },
            { "category": "travel_n_places", "keyword": "Japanese post office", "code": "\uD83C\uDFE3" },
            { "category": "travel_n_places", "keyword": "post office", "code": "\uD83C\uDFE4" },
            { "category": "travel_n_places", "keyword": "hospital", "code": "\uD83C\uDFE5" },
            { "category": "travel_n_places", "keyword": "bank", "code": "\uD83C\uDFE6" },
            { "category": "travel_n_places", "keyword": "hotel", "code": "\uD83C\uDFE8" },
            { "category": "travel_n_places", "keyword": "love hotel", "code": "\uD83C\uDFE9" },
            { "category": "travel_n_places", "keyword": "convenience store", "code": "\uD83C\uDFEA" },
            { "category": "travel_n_places", "keyword": "school", "code": "\uD83C\uDFEB" },
            { "category": "travel_n_places", "keyword": "department store", "code": "\uD83C\uDFEC" },
            { "category": "travel_n_places", "keyword": "factory", "code": "\uD83C\uDFED" },
            { "category": "travel_n_places", "keyword": "Japanese castle", "code": "\uD83C\uDFEF" },
            { "category": "travel_n_places", "keyword": "castle", "code": "\uD83C\uDFF0" },
            { "category": "travel_n_places", "keyword": "wedding", "code": "\uD83D\uDC92" },
            { "category": "travel_n_places", "keyword": "Tokyo tower", "code": "\uD83D\uDDFC" },
            { "category": "travel_n_places", "keyword": "Statue of Liberty", "code": "\uD83D\uDDFD" },
            { "category": "travel_n_places", "keyword": "church", "code": "\u26EA" },
            { "category": "travel_n_places", "keyword": "mosque", "code": "\uD83D\uDD4C" },
            { "category": "travel_n_places", "keyword": "synagogue", "code": "\uD83D\uDD4D" },
            { "category": "travel_n_places", "keyword": "shinto shrine", "code": "\u26E9\uFE0F" },
            { "category": "travel_n_places", "keyword": "kaaba", "code": "\uD83D\uDD4B" },
            { "category": "travel_n_places", "keyword": "fountain", "code": "\u26F2" },
            { "category": "travel_n_places", "keyword": "tent", "code": "\u26FA" },
            { "category": "travel_n_places", "keyword": "foggy", "code": "\uD83C\uDF01" },
            { "category": "travel_n_places", "keyword": "night with stars", "code": "\uD83C\uDF03" },
            { "category": "travel_n_places", "keyword": "cityscape", "code": "\uD83C\uDFD9\uFE0F" },
            { "category": "travel_n_places", "keyword": "sunrise over mountains", "code": "\uD83C\uDF04" },
            { "category": "travel_n_places", "keyword": "sunrise", "code": "\uD83C\uDF05" },
            { "category": "travel_n_places", "keyword": "cityscape at dusk", "code": "\uD83C\uDF06" },
            { "category": "travel_n_places", "keyword": "sunset", "code": "\uD83C\uDF07" },
            { "category": "travel_n_places", "keyword": "bridge at night", "code": "\uD83C\uDF09" },
            { "category": "travel_n_places", "keyword": "hot springs", "code": "\u2668\uFE0F" },
            { "category": "travel_n_places", "keyword": "milky way", "code": "\uD83C\uDF0C" },
            { "category": "travel_n_places", "keyword": "carousel horse", "code": "\uD83C\uDFA0" },
            { "category": "travel_n_places", "keyword": "ferris wheel", "code": "\uD83C\uDFA1" },
            { "category": "travel_n_places", "keyword": "roller coaster", "code": "\uD83C\uDFA2" },
            { "category": "travel_n_places", "keyword": "barber pole", "code": "\uD83D\uDC88" },
            { "category": "travel_n_places", "keyword": "circus tent", "code": "\uD83C\uDFAA" },
            { "category": "travel_n_places", "keyword": "locomotive", "code": "\uD83D\uDE82" },
            { "category": "travel_n_places", "keyword": "railway car", "code": "\uD83D\uDE83" },
            { "category": "travel_n_places", "keyword": "high-speed train", "code": "\uD83D\uDE84" },
            { "category": "travel_n_places", "keyword": "bullet train", "code": "\uD83D\uDE85" },
            { "category": "travel_n_places", "keyword": "train", "code": "\uD83D\uDE86" },
            { "category": "travel_n_places", "keyword": "metro", "code": "\uD83D\uDE87" },
            { "category": "travel_n_places", "keyword": "light rail", "code": "\uD83D\uDE88" },
            { "category": "travel_n_places", "keyword": "station", "code": "\uD83D\uDE89" },
            { "category": "travel_n_places", "keyword": "tram", "code": "\uD83D\uDE8A" },
            { "category": "travel_n_places", "keyword": "monorail", "code": "\uD83D\uDE9D" },
            { "category": "travel_n_places", "keyword": "mountain railway", "code": "\uD83D\uDE9E" },
            { "category": "travel_n_places", "keyword": "tram car", "code": "\uD83D\uDE8B" },
            { "category": "travel_n_places", "keyword": "bus", "code": "\uD83D\uDE8C" },
            { "category": "travel_n_places", "keyword": "oncoming bus", "code": "\uD83D\uDE8D" },
            { "category": "travel_n_places", "keyword": "trolleybus", "code": "\uD83D\uDE8E" },
            { "category": "travel_n_places", "keyword": "minibus", "code": "\uD83D\uDE90" },
            { "category": "travel_n_places", "keyword": "ambulance", "code": "\uD83D\uDE91" },
            { "category": "travel_n_places", "keyword": "fire engine", "code": "\uD83D\uDE92" },
            { "category": "travel_n_places", "keyword": "police car", "code": "\uD83D\uDE93" },
            { "category": "travel_n_places", "keyword": "oncoming police car", "code": "\uD83D\uDE94" },
            { "category": "travel_n_places", "keyword": "taxi", "code": "\uD83D\uDE95" },
            { "category": "travel_n_places", "keyword": "oncoming taxi", "code": "\uD83D\uDE96" },
            { "category": "travel_n_places", "keyword": "automobile", "code": "\uD83D\uDE97" },
            { "category": "travel_n_places", "keyword": "oncoming automobile", "code": "\uD83D\uDE98" },
            { "category": "travel_n_places", "keyword": "sport utility vehicle", "code": "\uD83D\uDE99" },
            { "category": "travel_n_places", "keyword": "delivery truck", "code": "\uD83D\uDE9A" },
            { "category": "travel_n_places", "keyword": "articulated lorry", "code": "\uD83D\uDE9B" },
            { "category": "travel_n_places", "keyword": "tractor", "code": "\uD83D\uDE9C" },
            { "category": "travel_n_places", "keyword": "bicycle", "code": "\uD83D\uDEB2" },
            { "category": "travel_n_places", "keyword": "kick scooter", "code": "\uD83D\uDEF4" },
            { "category": "travel_n_places", "keyword": "skateboard", "code": "\uD83D\uDEF9" },
            { "category": "travel_n_places", "keyword": "motor scooter", "code": "\uD83D\uDEF5" },
            { "category": "travel_n_places", "keyword": "bus stop", "code": "\uD83D\uDE8F" },
            { "category": "travel_n_places", "keyword": "motorway", "code": "\uD83D\uDEE3\uFE0F" },
            { "category": "travel_n_places", "keyword": "railway track", "code": "\uD83D\uDEE4\uFE0F" },
            { "category": "travel_n_places", "keyword": "oil drum", "code": "\uD83D\uDEE2\uFE0F" },
            { "category": "travel_n_places", "keyword": "fuel pump", "code": "\u26FD" },
            { "category": "travel_n_places", "keyword": "police car light", "code": "\uD83D\uDEA8" },
            { "category": "travel_n_places", "keyword": "horizontal traffic light", "code": "\uD83D\uDEA5" },
            { "category": "travel_n_places", "keyword": "vertical traffic light", "code": "\uD83D\uDEA6" },
            { "category": "travel_n_places", "keyword": "stop sign", "code": "\uD83D\uDED1" },
            { "category": "travel_n_places", "keyword": "construction", "code": "\uD83D\uDEA7" },
            { "category": "travel_n_places", "keyword": "anchor", "code": "\u2693" },
            { "category": "travel_n_places", "keyword": "sailboat", "code": "\u26F5" },
            { "category": "travel_n_places", "keyword": "canoe", "code": "\uD83D\uDEF6" },
            { "category": "travel_n_places", "keyword": "speedboat", "code": "\uD83D\uDEA4" },
            { "category": "travel_n_places", "keyword": "passenger ship", "code": "\uD83D\uDEF3\uFE0F" },
            { "category": "travel_n_places", "keyword": "ferry", "code": "\u26F4\uFE0F" },
            { "category": "travel_n_places", "keyword": "motor boat", "code": "\uD83D\uDEE5\uFE0F" },
            { "category": "travel_n_places", "keyword": "ship", "code": "\uD83D\uDEA2" },
            { "category": "travel_n_places", "keyword": "airplane", "code": "\u2708\uFE0F" },
            { "category": "travel_n_places", "keyword": "small airplane", "code": "\uD83D\uDEE9\uFE0F" },
            { "category": "travel_n_places", "keyword": "airplane departure", "code": "\uD83D\uDEEB" },
            { "category": "travel_n_places", "keyword": "airplane arrival", "code": "\uD83D\uDEEC" },
            { "category": "travel_n_places", "keyword": "seat", "code": "\uD83D\uDCBA" },
            { "category": "travel_n_places", "keyword": "helicopter", "code": "\uD83D\uDE81" },
            { "category": "travel_n_places", "keyword": "suspension railway", "code": "\uD83D\uDE9F" },
            { "category": "travel_n_places", "keyword": "mountain cableway", "code": "\uD83D\uDEA0" },
            { "category": "travel_n_places", "keyword": "aerial tramway", "code": "\uD83D\uDEA1" },
            { "category": "travel_n_places", "keyword": "satellite", "code": "\uD83D\uDEF0\uFE0F" },
            { "category": "travel_n_places", "keyword": "rocket", "code": "\uD83D\uDE80" },
            { "category": "travel_n_places", "keyword": "flying saucer", "code": "\uD83D\uDEF8" },
            { "category": "travel_n_places", "keyword": "bellhop bell", "code": "\uD83D\uDECE\uFE0F" },
            { "category": "travel_n_places", "keyword": "luggage", "code": "\uD83E\uDDF3" },
            { "category": "travel_n_places", "keyword": "hourglass done", "code": "\u231B" },
            { "category": "travel_n_places", "keyword": "hourglass not done", "code": "\u23F3" },
            { "category": "travel_n_places", "keyword": "watch", "code": "\u231A" },
            { "category": "travel_n_places", "keyword": "alarm clock", "code": "\u23F0" },
            { "category": "travel_n_places", "keyword": "stopwatch", "code": "\u23F1\uFE0F" },
            { "category": "travel_n_places", "keyword": "timer clock", "code": "\u23F2\uFE0F" },
            { "category": "travel_n_places", "keyword": "mantelpiece clock", "code": "\uD83D\uDD70\uFE0F" },
            { "category": "travel_n_places", "keyword": "twelve o’clock", "code": "\uD83D\uDD5B" },
            { "category": "travel_n_places", "keyword": "twelve-thirty", "code": "\uD83D\uDD67" },
            { "category": "travel_n_places", "keyword": "one o’clock", "code": "\uD83D\uDD50" },
            { "category": "travel_n_places", "keyword": "one-thirty", "code": "\uD83D\uDD5C" },
            { "category": "travel_n_places", "keyword": "two o’clock", "code": "\uD83D\uDD51" },
            { "category": "travel_n_places", "keyword": "two-thirty", "code": "\uD83D\uDD5D" },
            { "category": "travel_n_places", "keyword": "three o’clock", "code": "\uD83D\uDD52" },
            { "category": "travel_n_places", "keyword": "three-thirty", "code": "\uD83D\uDD5E" },
            { "category": "travel_n_places", "keyword": "four o’clock", "code": "\uD83D\uDD53" },
            { "category": "travel_n_places", "keyword": "four-thirty", "code": "\uD83D\uDD5F" },
            { "category": "travel_n_places", "keyword": "five o’clock", "code": "\uD83D\uDD54" },
            { "category": "travel_n_places", "keyword": "five-thirty", "code": "\uD83D\uDD60" },
            { "category": "travel_n_places", "keyword": "six o’clock", "code": "\uD83D\uDD55" },
            { "category": "travel_n_places", "keyword": "six-thirty", "code": "\uD83D\uDD61" },
            { "category": "travel_n_places", "keyword": "seven o’clock", "code": "\uD83D\uDD56" },
            { "category": "travel_n_places", "keyword": "seven-thirty", "code": "\uD83D\uDD62" },
            { "category": "travel_n_places", "keyword": "eight o’clock", "code": "\uD83D\uDD57" },
            { "category": "travel_n_places", "keyword": "eight-thirty", "code": "\uD83D\uDD63" },
            { "category": "travel_n_places", "keyword": "nine o’clock", "code": "\uD83D\uDD58" },
            { "category": "travel_n_places", "keyword": "nine-thirty", "code": "\uD83D\uDD64" },
            { "category": "travel_n_places", "keyword": "ten o’clock", "code": "\uD83D\uDD59" },
            { "category": "travel_n_places", "keyword": "ten-thirty", "code": "\uD83D\uDD65" },
            { "category": "travel_n_places", "keyword": "eleven o’clock", "code": "\uD83D\uDD5A" },
            { "category": "travel_n_places", "keyword": "eleven-thirty", "code": "\uD83D\uDD66" },
            { "category": "travel_n_places", "keyword": "new moon", "code": "\uD83C\uDF11" },
            { "category": "travel_n_places", "keyword": "waxing crescent moon", "code": "\uD83C\uDF12" },
            { "category": "travel_n_places", "keyword": "first quarter moon", "code": "\uD83C\uDF13" },
            { "category": "travel_n_places", "keyword": "waxing gibbous moon", "code": "\uD83C\uDF14" },
            { "category": "travel_n_places", "keyword": "full moon", "code": "\uD83C\uDF15" },
            { "category": "travel_n_places", "keyword": "waning gibbous moon", "code": "\uD83C\uDF16" },
            { "category": "travel_n_places", "keyword": "last quarter moon", "code": "\uD83C\uDF17" },
            { "category": "travel_n_places", "keyword": "waning crescent moon", "code": "\uD83C\uDF18" },
            { "category": "travel_n_places", "keyword": "crescent moon", "code": "\uD83C\uDF19" },
            { "category": "travel_n_places", "keyword": "new moon face", "code": "\uD83C\uDF1A" },
            { "category": "travel_n_places", "keyword": "first quarter moon face", "code": "\uD83C\uDF1B" },
            { "category": "travel_n_places", "keyword": "last quarter moon face", "code": "\uD83C\uDF1C" },
            { "category": "travel_n_places", "keyword": "thermometer", "code": "\uD83C\uDF21\uFE0F" },
            { "category": "travel_n_places", "keyword": "sun", "code": "\u2600\uFE0F" },
            { "category": "travel_n_places", "keyword": "full moon face", "code": "\uD83C\uDF1D" },
            { "category": "travel_n_places", "keyword": "sun with face", "code": "\uD83C\uDF1E" },
            { "category": "travel_n_places", "keyword": "star", "code": "\u2B50" },
            { "category": "travel_n_places", "keyword": "glowing star", "code": "\uD83C\uDF1F" },
            { "category": "travel_n_places", "keyword": "shooting star", "code": "\uD83C\uDF20" },
            { "category": "travel_n_places", "keyword": "cloud", "code": "\u2601\uFE0F" },
            { "category": "travel_n_places", "keyword": "sun behind cloud", "code": "\u26C5" },
            { "category": "travel_n_places", "keyword": "cloud with lightning and rain", "code": "\u26C8\uFE0F" },
            { "category": "travel_n_places", "keyword": "sun behind small cloud", "code": "\uD83C\uDF24\uFE0F" },
            { "category": "travel_n_places", "keyword": "sun behind large cloud", "code": "\uD83C\uDF25\uFE0F" },
            { "category": "travel_n_places", "keyword": "sun behind rain cloud", "code": "\uD83C\uDF26\uFE0F" },
            { "category": "travel_n_places", "keyword": "cloud with rain", "code": "\uD83C\uDF27\uFE0F" },
            { "category": "travel_n_places", "keyword": "cloud with snow", "code": "\uD83C\uDF28\uFE0F" },
            { "category": "travel_n_places", "keyword": "cloud with lightning", "code": "\uD83C\uDF29\uFE0F" },
            { "category": "travel_n_places", "keyword": "tornado", "code": "\uD83C\uDF2A\uFE0F" },
            { "category": "travel_n_places", "keyword": "fog", "code": "\uD83C\uDF2B\uFE0F" },
            { "category": "travel_n_places", "keyword": "wind face", "code": "\uD83C\uDF2C\uFE0F" },
            { "category": "travel_n_places", "keyword": "cyclone", "code": "\uD83C\uDF00" },
            { "category": "travel_n_places", "keyword": "rainbow", "code": "\uD83C\uDF08" },
            { "category": "travel_n_places", "keyword": "closed umbrella", "code": "\uD83C\uDF02" },
            { "category": "travel_n_places", "keyword": "umbrella", "code": "\u2602\uFE0F" },
            { "category": "travel_n_places", "keyword": "umbrella with rain drops", "code": "\u2614" },
            { "category": "travel_n_places", "keyword": "umbrella on ground", "code": "\u26F1\uFE0F" },
            { "category": "travel_n_places", "keyword": "high voltage", "code": "\u26A1" },
            { "category": "travel_n_places", "keyword": "snowflake", "code": "\u2744\uFE0F" },
            { "category": "travel_n_places", "keyword": "snowman", "code": "\u2603\uFE0F" },
            { "category": "travel_n_places", "keyword": "snowman without snow", "code": "\u26C4" },
            { "category": "travel_n_places", "keyword": "comet", "code": "\u2604\uFE0F" },
            { "category": "travel_n_places", "keyword": "fire", "code": "\uD83D\uDD25" },
            { "category": "travel_n_places", "keyword": "droplet", "code": "\uD83D\uDCA7" },
            { "category": "travel_n_places", "keyword": "water wave", "code": "\uD83C\uDF0A" },
            { "category": "activities", "keyword": "jack-o-lantern", "code": "\uD83C\uDF83" },
            { "category": "activities", "keyword": "Christmas tree", "code": "\uD83C\uDF84" },
            { "category": "activities", "keyword": "fireworks", "code": "\uD83C\uDF86" },
            { "category": "activities", "keyword": "sparkler", "code": "\uD83C\uDF87" },
            { "category": "activities", "keyword": "firecracker", "code": "\uD83E\uDDE8" },
            { "category": "activities", "keyword": "sparkles", "code": "\u2728" },
            { "category": "activities", "keyword": "balloon", "code": "\uD83C\uDF88" },
            { "category": "activities", "keyword": "party popper", "code": "\uD83C\uDF89" },
            { "category": "activities", "keyword": "confetti ball", "code": "\uD83C\uDF8A" },
            { "category": "activities", "keyword": "tanabata tree", "code": "\uD83C\uDF8B" },
            { "category": "activities", "keyword": "pine decoration", "code": "\uD83C\uDF8D" },
            { "category": "activities", "keyword": "Japanese dolls", "code": "\uD83C\uDF8E" },
            { "category": "activities", "keyword": "carp streamer", "code": "\uD83C\uDF8F" },
            { "category": "activities", "keyword": "wind chime", "code": "\uD83C\uDF90" },
            { "category": "activities", "keyword": "moon viewing ceremony", "code": "\uD83C\uDF91" },
            { "category": "activities", "keyword": "red envelope", "code": "\uD83E\uDDE7" },
            { "category": "activities", "keyword": "ribbon", "code": "\uD83C\uDF80" },
            { "category": "activities", "keyword": "wrapped gift", "code": "\uD83C\uDF81" },
            { "category": "activities", "keyword": "reminder ribbon", "code": "\uD83C\uDF97\uFE0F" },
            { "category": "activities", "keyword": "admission tickets", "code": "\uD83C\uDF9F\uFE0F" },
            { "category": "activities", "keyword": "ticket", "code": "\uD83C\uDFAB" },
            { "category": "activities", "keyword": "military medal", "code": "\uD83C\uDF96\uFE0F" },
            { "category": "activities", "keyword": "trophy", "code": "\uD83C\uDFC6" },
            { "category": "activities", "keyword": "sports medal", "code": "\uD83C\uDFC5" },
            { "category": "activities", "keyword": "1st place medal", "code": "\uD83E\uDD47" },
            { "category": "activities", "keyword": "2nd place medal", "code": "\uD83E\uDD48" },
            { "category": "activities", "keyword": "3rd place medal", "code": "\uD83E\uDD49" },
            { "category": "activities", "keyword": "soccer ball", "code": "\u26BD" },
            { "category": "activities", "keyword": "baseball", "code": "\u26BE" },
            { "category": "activities", "keyword": "softball", "code": "\uD83E\uDD4E" },
            { "category": "activities", "keyword": "basketball", "code": "\uD83C\uDFC0" },
            { "category": "activities", "keyword": "volleyball", "code": "\uD83C\uDFD0" },
            { "category": "activities", "keyword": "american football", "code": "\uD83C\uDFC8" },
            { "category": "activities", "keyword": "rugby football", "code": "\uD83C\uDFC9" },
            { "category": "activities", "keyword": "tennis", "code": "\uD83C\uDFBE" },
            { "category": "activities", "keyword": "flying disc", "code": "\uD83E\uDD4F" },
            { "category": "activities", "keyword": "bowling", "code": "\uD83C\uDFB3" },
            { "category": "activities", "keyword": "cricket game", "code": "\uD83C\uDFCF" },
            { "category": "activities", "keyword": "field hockey", "code": "\uD83C\uDFD1" },
            { "category": "activities", "keyword": "ice hockey", "code": "\uD83C\uDFD2" },
            { "category": "activities", "keyword": "lacrosse", "code": "\uD83E\uDD4D" },
            { "category": "activities", "keyword": "ping pong", "code": "\uD83C\uDFD3" },
            { "category": "activities", "keyword": "badminton", "code": "\uD83C\uDFF8" },
            { "category": "activities", "keyword": "boxing glove", "code": "\uD83E\uDD4A" },
            { "category": "activities", "keyword": "martial arts uniform", "code": "\uD83E\uDD4B" },
            { "category": "activities", "keyword": "goal net", "code": "\uD83E\uDD45" },
            { "category": "activities", "keyword": "flag in hole", "code": "\u26F3" },
            { "category": "activities", "keyword": "ice skate", "code": "\u26F8\uFE0F" },
            { "category": "activities", "keyword": "fishing pole", "code": "\uD83C\uDFA3" },
            { "category": "activities", "keyword": "running shirt", "code": "\uD83C\uDFBD" },
            { "category": "activities", "keyword": "skis", "code": "\uD83C\uDFBF" },
            { "category": "activities", "keyword": "sled", "code": "\uD83D\uDEF7" },
            { "category": "activities", "keyword": "curling stone", "code": "\uD83E\uDD4C" },
            { "category": "activities", "keyword": "direct hit", "code": "\uD83C\uDFAF" },
            { "category": "activities", "keyword": "pool 8 ball", "code": "\uD83C\uDFB1" },
            { "category": "activities", "keyword": "crystal ball", "code": "\uD83D\uDD2E" },
            { "category": "activities", "keyword": "nazar amulet", "code": "\uD83E\uDDFF" },
            { "category": "activities", "keyword": "video game", "code": "\uD83C\uDFAE" },
            { "category": "activities", "keyword": "joystick", "code": "\uD83D\uDD79\uFE0F" },
            { "category": "activities", "keyword": "slot machine", "code": "\uD83C\uDFB0" },
            { "category": "activities", "keyword": "game die", "code": "\uD83C\uDFB2" },
            { "category": "activities", "keyword": "jigsaw", "code": "\uD83E\uDDE9" },
            { "category": "activities", "keyword": "teddy bear", "code": "\uD83E\uDDF8" },
            { "category": "activities", "keyword": "spade suit", "code": "\u2660\uFE0F" },
            { "category": "activities", "keyword": "heart suit", "code": "\u2665\uFE0F" },
            { "category": "activities", "keyword": "diamond suit", "code": "\u2666\uFE0F" },
            { "category": "activities", "keyword": "club suit", "code": "\u2663\uFE0F" },
            { "category": "activities", "keyword": "chess pawn", "code": "\u265F\uFE0F" },
            { "category": "activities", "keyword": "joker", "code": "\uD83C\uDCCF" },
            { "category": "activities", "keyword": "mahjong red dragon", "code": "\uD83C\uDC04" },
            { "category": "activities", "keyword": "flower playing cards", "code": "\uD83C\uDFB4" },
            { "category": "activities", "keyword": "performing arts", "code": "\uD83C\uDFAD" },
            { "category": "activities", "keyword": "framed picture", "code": "\uD83D\uDDBC\uFE0F" },
            { "category": "activities", "keyword": "artist palette", "code": "\uD83C\uDFA8" },
            { "category": "activities", "keyword": "thread", "code": "\uD83E\uDDF5" },
            { "category": "activities", "keyword": "yarn", "code": "\uD83E\uDDF6" },
            { "category": "objects", "keyword": "muted speaker", "code": "\uD83D\uDD07" },
            { "category": "objects", "keyword": "speaker low volume", "code": "\uD83D\uDD08" },
            { "category": "objects", "keyword": "speaker medium volume", "code": "\uD83D\uDD09" },
            { "category": "objects", "keyword": "speaker high volume", "code": "\uD83D\uDD0A" },
            { "category": "objects", "keyword": "loudspeaker", "code": "\uD83D\uDCE2" },
            { "category": "objects", "keyword": "megaphone", "code": "\uD83D\uDCE3" },
            { "category": "objects", "keyword": "postal horn", "code": "\uD83D\uDCEF" },
            { "category": "objects", "keyword": "bell", "code": "\uD83D\uDD14" },
            { "category": "objects", "keyword": "bell with slash", "code": "\uD83D\uDD15" },
            { "category": "objects", "keyword": "musical score", "code": "\uD83C\uDFBC" },
            { "category": "objects", "keyword": "musical note", "code": "\uD83C\uDFB5" },
            { "category": "objects", "keyword": "musical notes", "code": "\uD83C\uDFB6" },
            { "category": "objects", "keyword": "studio microphone", "code": "\uD83C\uDF99\uFE0F" },
            { "category": "objects", "keyword": "level slider", "code": "\uD83C\uDF9A\uFE0F" },
            { "category": "objects", "keyword": "control knobs", "code": "\uD83C\uDF9B\uFE0F" },
            { "category": "objects", "keyword": "microphone", "code": "\uD83C\uDFA4" },
            { "category": "objects", "keyword": "headphone", "code": "\uD83C\uDFA7" },
            { "category": "objects", "keyword": "radio", "code": "\uD83D\uDCFB" },
            { "category": "objects", "keyword": "saxophone", "code": "\uD83C\uDFB7" },
            { "category": "objects", "keyword": "guitar", "code": "\uD83C\uDFB8" },
            { "category": "objects", "keyword": "musical keyboard", "code": "\uD83C\uDFB9" },
            { "category": "objects", "keyword": "trumpet", "code": "\uD83C\uDFBA" },
            { "category": "objects", "keyword": "violin", "code": "\uD83C\uDFBB" },
            { "category": "objects", "keyword": "drum", "code": "\uD83E\uDD41" },
            { "category": "objects", "keyword": "mobile phone", "code": "\uD83D\uDCF1" },
            { "category": "objects", "keyword": "mobile phone with arrow", "code": "\uD83D\uDCF2" },
            { "category": "objects", "keyword": "telephone", "code": "\u260E\uFE0F" },
            { "category": "objects", "keyword": "telephone receiver", "code": "\uD83D\uDCDE" },
            { "category": "objects", "keyword": "pager", "code": "\uD83D\uDCDF" },
            { "category": "objects", "keyword": "fax machine", "code": "\uD83D\uDCE0" },
            { "category": "objects", "keyword": "battery", "code": "\uD83D\uDD0B" },
            { "category": "objects", "keyword": "electric plug", "code": "\uD83D\uDD0C" },
            { "category": "objects", "keyword": "laptop computer", "code": "\uD83D\uDCBB" },
            { "category": "objects", "keyword": "desktop computer", "code": "\uD83D\uDDA5\uFE0F" },
            { "category": "objects", "keyword": "printer", "code": "\uD83D\uDDA8\uFE0F" },
            { "category": "objects", "keyword": "keyboard", "code": "\u2328\uFE0F" },
            { "category": "objects", "keyword": "computer mouse", "code": "\uD83D\uDDB1\uFE0F" },
            { "category": "objects", "keyword": "trackball", "code": "\uD83D\uDDB2\uFE0F" },
            { "category": "objects", "keyword": "computer disk", "code": "\uD83D\uDCBD" },
            { "category": "objects", "keyword": "floppy disk", "code": "\uD83D\uDCBE" },
            { "category": "objects", "keyword": "optical disk", "code": "\uD83D\uDCBF" },
            { "category": "objects", "keyword": "dvd", "code": "\uD83D\uDCC0" },
            { "category": "objects", "keyword": "abacus", "code": "\uD83E\uDDEE" },
            { "category": "objects", "keyword": "movie camera", "code": "\uD83C\uDFA5" },
            { "category": "objects", "keyword": "film frames", "code": "\uD83C\uDF9E\uFE0F" },
            { "category": "objects", "keyword": "film projector", "code": "\uD83D\uDCFD\uFE0F" },
            { "category": "objects", "keyword": "clapper board", "code": "\uD83C\uDFAC" },
            { "category": "objects", "keyword": "television", "code": "\uD83D\uDCFA" },
            { "category": "objects", "keyword": "camera", "code": "\uD83D\uDCF7" },
            { "category": "objects", "keyword": "camera with flash", "code": "\uD83D\uDCF8" },
            { "category": "objects", "keyword": "video camera", "code": "\uD83D\uDCF9" },
            { "category": "objects", "keyword": "videocassette", "code": "\uD83D\uDCFC" },
            { "category": "objects", "keyword": "magnifying glass tilted left", "code": "\uD83D\uDD0D" },
            { "category": "objects", "keyword": "magnifying glass tilted right", "code": "\uD83D\uDD0E" },
            { "category": "objects", "keyword": "candle", "code": "\uD83D\uDD6F\uFE0F" },
            { "category": "objects", "keyword": "light bulb", "code": "\uD83D\uDCA1" },
            { "category": "objects", "keyword": "flashlight", "code": "\uD83D\uDD26" },
            { "category": "objects", "keyword": "red paper lantern", "code": "\uD83C\uDFEE" },
            { "category": "objects", "keyword": "notebook with decorative cover", "code": "\uD83D\uDCD4" },
            { "category": "objects", "keyword": "closed book", "code": "\uD83D\uDCD5" },
            { "category": "objects", "keyword": "open book", "code": "\uD83D\uDCD6" },
            { "category": "objects", "keyword": "green book", "code": "\uD83D\uDCD7" },
            { "category": "objects", "keyword": "blue book", "code": "\uD83D\uDCD8" },
            { "category": "objects", "keyword": "orange book", "code": "\uD83D\uDCD9" },
            { "category": "objects", "keyword": "books", "code": "\uD83D\uDCDA" },
            { "category": "objects", "keyword": "notebook", "code": "\uD83D\uDCD3" },
            { "category": "objects", "keyword": "ledger", "code": "\uD83D\uDCD2" },
            { "category": "objects", "keyword": "page with curl", "code": "\uD83D\uDCC3" },
            { "category": "objects", "keyword": "scroll", "code": "\uD83D\uDCDC" },
            { "category": "objects", "keyword": "page facing up", "code": "\uD83D\uDCC4" },
            { "category": "objects", "keyword": "newspaper", "code": "\uD83D\uDCF0" },
            { "category": "objects", "keyword": "rolled-up newspaper", "code": "\uD83D\uDDDE\uFE0F" },
            { "category": "objects", "keyword": "bookmark tabs", "code": "\uD83D\uDCD1" },
            { "category": "objects", "keyword": "bookmark", "code": "\uD83D\uDD16" },
            { "category": "objects", "keyword": "label", "code": "\uD83C\uDFF7\uFE0F" },
            { "category": "objects", "keyword": "money bag", "code": "\uD83D\uDCB0" },
            { "category": "objects", "keyword": "yen banknote", "code": "\uD83D\uDCB4" },
            { "category": "objects", "keyword": "dollar banknote", "code": "\uD83D\uDCB5" },
            { "category": "objects", "keyword": "euro banknote", "code": "\uD83D\uDCB6" },
            { "category": "objects", "keyword": "pound banknote", "code": "\uD83D\uDCB7" },
            { "category": "objects", "keyword": "money with wings", "code": "\uD83D\uDCB8" },
            { "category": "objects", "keyword": "credit card", "code": "\uD83D\uDCB3" },
            { "category": "objects", "keyword": "receipt", "code": "\uD83E\uDDFE" },
            { "category": "objects", "keyword": "chart increasing with yen", "code": "\uD83D\uDCB9" },
            { "category": "objects", "keyword": "currency exchange", "code": "\uD83D\uDCB1" },
            { "category": "objects", "keyword": "heavy dollar sign", "code": "\uD83D\uDCB2" },
            { "category": "objects", "keyword": "envelope", "code": "\u2709\uFE0F" },
            { "category": "objects", "keyword": "e-mail", "code": "\uD83D\uDCE7" },
            { "category": "objects", "keyword": "incoming envelope", "code": "\uD83D\uDCE8" },
            { "category": "objects", "keyword": "envelope with arrow", "code": "\uD83D\uDCE9" },
            { "category": "objects", "keyword": "outbox tray", "code": "\uD83D\uDCE4" },
            { "category": "objects", "keyword": "inbox tray", "code": "\uD83D\uDCE5" },
            { "category": "objects", "keyword": "package", "code": "\uD83D\uDCE6" },
            { "category": "objects", "keyword": "closed mailbox with raised flag", "code": "\uD83D\uDCEB" },
            { "category": "objects", "keyword": "closed mailbox with lowered flag", "code": "\uD83D\uDCEA" },
            { "category": "objects", "keyword": "open mailbox with raised flag", "code": "\uD83D\uDCEC" },
            { "category": "objects", "keyword": "open mailbox with lowered flag", "code": "\uD83D\uDCED" },
            { "category": "objects", "keyword": "postbox", "code": "\uD83D\uDCEE" },
            { "category": "objects", "keyword": "ballot box with ballot", "code": "\uD83D\uDDF3\uFE0F" },
            { "category": "objects", "keyword": "pencil", "code": "\u270F\uFE0F" },
            { "category": "objects", "keyword": "black nib", "code": "\u2712\uFE0F" },
            { "category": "objects", "keyword": "fountain pen", "code": "\uD83D\uDD8B\uFE0F" },
            { "category": "objects", "keyword": "pen", "code": "\uD83D\uDD8A\uFE0F" },
            { "category": "objects", "keyword": "paintbrush", "code": "\uD83D\uDD8C\uFE0F" },
            { "category": "objects", "keyword": "crayon", "code": "\uD83D\uDD8D\uFE0F" },
            { "category": "objects", "keyword": "memo", "code": "\uD83D\uDCDD" },
            { "category": "objects", "keyword": "briefcase", "code": "\uD83D\uDCBC" },
            { "category": "objects", "keyword": "file folder", "code": "\uD83D\uDCC1" },
            { "category": "objects", "keyword": "open file folder", "code": "\uD83D\uDCC2" },
            { "category": "objects", "keyword": "card index dividers", "code": "\uD83D\uDDC2\uFE0F" },
            { "category": "objects", "keyword": "calendar", "code": "\uD83D\uDCC5" },
            { "category": "objects", "keyword": "tear-off calendar", "code": "\uD83D\uDCC6" },
            { "category": "objects", "keyword": "spiral notepad", "code": "\uD83D\uDDD2\uFE0F" },
            { "category": "objects", "keyword": "spiral calendar", "code": "\uD83D\uDDD3\uFE0F" },
            { "category": "objects", "keyword": "card index", "code": "\uD83D\uDCC7" },
            { "category": "objects", "keyword": "chart increasing", "code": "\uD83D\uDCC8" },
            { "category": "objects", "keyword": "chart decreasing", "code": "\uD83D\uDCC9" },
            { "category": "objects", "keyword": "bar chart", "code": "\uD83D\uDCCA" },
            { "category": "objects", "keyword": "clipboard", "code": "\uD83D\uDCCB" },
            { "category": "objects", "keyword": "pushpin", "code": "\uD83D\uDCCC" },
            { "category": "objects", "keyword": "round pushpin", "code": "\uD83D\uDCCD" },
            { "category": "objects", "keyword": "paperclip", "code": "\uD83D\uDCCE" },
            { "category": "objects", "keyword": "linked paperclips", "code": "\uD83D\uDD87\uFE0F" },
            { "category": "objects", "keyword": "straight ruler", "code": "\uD83D\uDCCF" },
            { "category": "objects", "keyword": "triangular ruler", "code": "\uD83D\uDCD0" },
            { "category": "objects", "keyword": "scissors", "code": "\u2702\uFE0F" },
            { "category": "objects", "keyword": "card file box", "code": "\uD83D\uDDC3\uFE0F" },
            { "category": "objects", "keyword": "file cabinet", "code": "\uD83D\uDDC4\uFE0F" },
            { "category": "objects", "keyword": "wastebasket", "code": "\uD83D\uDDD1\uFE0F" },
            { "category": "objects", "keyword": "locked", "code": "\uD83D\uDD12" },
            { "category": "objects", "keyword": "unlocked", "code": "\uD83D\uDD13" },
            { "category": "objects", "keyword": "locked with pen", "code": "\uD83D\uDD0F" },
            { "category": "objects", "keyword": "locked with key", "code": "\uD83D\uDD10" },
            { "category": "objects", "keyword": "key", "code": "\uD83D\uDD11" },
            { "category": "objects", "keyword": "old key", "code": "\uD83D\uDDDD\uFE0F" },
            { "category": "objects", "keyword": "hammer", "code": "\uD83D\uDD28" },
            { "category": "objects", "keyword": "pick", "code": "\u26CF\uFE0F" },
            { "category": "objects", "keyword": "hammer and pick", "code": "\u2692\uFE0F" },
            { "category": "objects", "keyword": "hammer and wrench", "code": "\uD83D\uDEE0\uFE0F" },
            { "category": "objects", "keyword": "dagger", "code": "\uD83D\uDDE1\uFE0F" },
            { "category": "objects", "keyword": "crossed swords", "code": "\u2694\uFE0F" },
            { "category": "objects", "keyword": "pistol", "code": "\uD83D\uDD2B" },
            { "category": "objects", "keyword": "bow and arrow", "code": "\uD83C\uDFF9" },
            { "category": "objects", "keyword": "shield", "code": "\uD83D\uDEE1\uFE0F" },
            { "category": "objects", "keyword": "wrench", "code": "\uD83D\uDD27" },
            { "category": "objects", "keyword": "nut and bolt", "code": "\uD83D\uDD29" },
            { "category": "objects", "keyword": "gear", "code": "\u2699\uFE0F" },
            { "category": "objects", "keyword": "clamp", "code": "\uD83D\uDDDC\uFE0F" },
            { "category": "objects", "keyword": "balance scale", "code": "\u2696\uFE0F" },
            { "category": "objects", "keyword": "link", "code": "\uD83D\uDD17" },
            { "category": "objects", "keyword": "chains", "code": "\u26D3\uFE0F" },
            { "category": "objects", "keyword": "toolbox", "code": "\uD83E\uDDF0" },
            { "category": "objects", "keyword": "magnet", "code": "\uD83E\uDDF2" },
            { "category": "objects", "keyword": "alembic", "code": "\u2697\uFE0F" },
            { "category": "objects", "keyword": "test tube", "code": "\uD83E\uDDEA" },
            { "category": "objects", "keyword": "petri dish", "code": "\uD83E\uDDEB" },
            { "category": "objects", "keyword": "dna", "code": "\uD83E\uDDEC" },
            { "category": "objects", "keyword": "microscope", "code": "\uD83D\uDD2C" },
            { "category": "objects", "keyword": "telescope", "code": "\uD83D\uDD2D" },
            { "category": "objects", "keyword": "satellite antenna", "code": "\uD83D\uDCE1" },
            { "category": "objects", "keyword": "syringe", "code": "\uD83D\uDC89" },
            { "category": "objects", "keyword": "pill", "code": "\uD83D\uDC8A" },
            { "category": "objects", "keyword": "door", "code": "\uD83D\uDEAA" },
            { "category": "objects", "keyword": "bed", "code": "\uD83D\uDECF\uFE0F" },
            { "category": "objects", "keyword": "couch and lamp", "code": "\uD83D\uDECB\uFE0F" },
            { "category": "objects", "keyword": "toilet", "code": "\uD83D\uDEBD" },
            { "category": "objects", "keyword": "shower", "code": "\uD83D\uDEBF" },
            { "category": "objects", "keyword": "bathtub", "code": "\uD83D\uDEC1" },
            { "category": "objects", "keyword": "lotion bottle", "code": "\uD83E\uDDF4" },
            { "category": "objects", "keyword": "safety pin", "code": "\uD83E\uDDF7" },
            { "category": "objects", "keyword": "broom", "code": "\uD83E\uDDF9" },
            { "category": "objects", "keyword": "basket", "code": "\uD83E\uDDFA" },
            { "category": "objects", "keyword": "roll of paper", "code": "\uD83E\uDDFB" },
            { "category": "objects", "keyword": "soap", "code": "\uD83E\uDDFC" },
            { "category": "objects", "keyword": "sponge", "code": "\uD83E\uDDFD" },
            { "category": "objects", "keyword": "fire extinguisher", "code": "\uD83E\uDDEF" },
            { "category": "objects", "keyword": "shopping cart", "code": "\uD83D\uDED2" },
            { "category": "objects", "keyword": "cigarette", "code": "\uD83D\uDEAC" },
            { "category": "objects", "keyword": "coffin", "code": "\u26B0\uFE0F" },
            { "category": "objects", "keyword": "funeral urn", "code": "\u26B1\uFE0F" },
            { "category": "objects", "keyword": "moai", "code": "\uD83D\uDDFF" },
            { "category": "symbols", "keyword": "ATM sign", "code": "\uD83C\uDFE7" },
            { "category": "symbols", "keyword": "litter in bin sign", "code": "\uD83D\uDEAE" },
            { "category": "symbols", "keyword": "potable water", "code": "\uD83D\uDEB0" },
            { "category": "symbols", "keyword": "wheelchair symbol", "code": "\u267F" },
            { "category": "symbols", "keyword": "men’s room", "code": "\uD83D\uDEB9" },
            { "category": "symbols", "keyword": "women’s room", "code": "\uD83D\uDEBA" },
            { "category": "symbols", "keyword": "restroom", "code": "\uD83D\uDEBB" },
            { "category": "symbols", "keyword": "baby symbol", "code": "\uD83D\uDEBC" },
            { "category": "symbols", "keyword": "water closet", "code": "\uD83D\uDEBE" },
            { "category": "symbols", "keyword": "passport control", "code": "\uD83D\uDEC2" },
            { "category": "symbols", "keyword": "customs", "code": "\uD83D\uDEC3" },
            { "category": "symbols", "keyword": "baggage claim", "code": "\uD83D\uDEC4" },
            { "category": "symbols", "keyword": "left luggage", "code": "\uD83D\uDEC5" },
            { "category": "symbols", "keyword": "warning", "code": "\u26A0\uFE0F" },
            { "category": "symbols", "keyword": "children crossing", "code": "\uD83D\uDEB8" },
            { "category": "symbols", "keyword": "no entry", "code": "\u26D4" },
            { "category": "symbols", "keyword": "prohibited", "code": "\uD83D\uDEAB" },
            { "category": "symbols", "keyword": "no bicycles", "code": "\uD83D\uDEB3" },
            { "category": "symbols", "keyword": "no smoking", "code": "\uD83D\uDEAD" },
            { "category": "symbols", "keyword": "no littering", "code": "\uD83D\uDEAF" },
            { "category": "symbols", "keyword": "non-potable water", "code": "\uD83D\uDEB1" },
            { "category": "symbols", "keyword": "no pedestrians", "code": "\uD83D\uDEB7" },
            { "category": "symbols", "keyword": "no mobile phones", "code": "\uD83D\uDCF5" },
            { "category": "symbols", "keyword": "no one under eighteen", "code": "\uD83D\uDD1E" },
            { "category": "symbols", "keyword": "radioactive", "code": "\u2622\uFE0F" },
            { "category": "symbols", "keyword": "biohazard", "code": "\u2623\uFE0F" },
            { "category": "symbols", "keyword": "up arrow", "code": "\u2B06\uFE0F" },
            { "category": "symbols", "keyword": "up-right arrow", "code": "\u2197\uFE0F" },
            { "category": "symbols", "keyword": "right arrow", "code": "\u27A1\uFE0F" },
            { "category": "symbols", "keyword": "down-right arrow", "code": "\u2198\uFE0F" },
            { "category": "symbols", "keyword": "down arrow", "code": "\u2B07\uFE0F" },
            { "category": "symbols", "keyword": "down-left arrow", "code": "\u2199\uFE0F" },
            { "category": "symbols", "keyword": "left arrow", "code": "\u2B05\uFE0F" },
            { "category": "symbols", "keyword": "up-left arrow", "code": "\u2196\uFE0F" },
            { "category": "symbols", "keyword": "up-down arrow", "code": "\u2195\uFE0F" },
            { "category": "symbols", "keyword": "left-right arrow", "code": "\u2194\uFE0F" },
            { "category": "symbols", "keyword": "right arrow curving left", "code": "\u21A9\uFE0F" },
            { "category": "symbols", "keyword": "left arrow curving right", "code": "\u21AA\uFE0F" },
            { "category": "symbols", "keyword": "right arrow curving up", "code": "\u2934\uFE0F" },
            { "category": "symbols", "keyword": "right arrow curving down", "code": "\u2935\uFE0F" },
            { "category": "symbols", "keyword": "clockwise vertical arrows", "code": "\uD83D\uDD03" },
            { "category": "symbols", "keyword": "counterclockwise arrows button", "code": "\uD83D\uDD04" },
            { "category": "symbols", "keyword": "BACK arrow", "code": "\uD83D\uDD19" },
            { "category": "symbols", "keyword": "END arrow", "code": "\uD83D\uDD1A" },
            { "category": "symbols", "keyword": "ON! arrow", "code": "\uD83D\uDD1B" },
            { "category": "symbols", "keyword": "SOON arrow", "code": "\uD83D\uDD1C" },
            { "category": "symbols", "keyword": "TOP arrow", "code": "\uD83D\uDD1D" },
            { "category": "symbols", "keyword": "place of worship", "code": "\uD83D\uDED0" },
            { "category": "symbols", "keyword": "atom symbol", "code": "\u269B\uFE0F" },
            { "category": "symbols", "keyword": "om", "code": "\uD83D\uDD49\uFE0F" },
            { "category": "symbols", "keyword": "star of David", "code": "\u2721\uFE0F" },
            { "category": "symbols", "keyword": "wheel of dharma", "code": "\u2638\uFE0F" },
            { "category": "symbols", "keyword": "yin yang", "code": "\u262F\uFE0F" },
            { "category": "symbols", "keyword": "latin cross", "code": "\u271D\uFE0F" },
            { "category": "symbols", "keyword": "orthodox cross", "code": "\u2626\uFE0F" },
            { "category": "symbols", "keyword": "star and crescent", "code": "\u262A\uFE0F" },
            { "category": "symbols", "keyword": "peace symbol", "code": "\u262E\uFE0F" },
            { "category": "symbols", "keyword": "menorah", "code": "\uD83D\uDD4E" },
            { "category": "symbols", "keyword": "dotted six-pointed star", "code": "\uD83D\uDD2F" },
            { "category": "symbols", "keyword": "Aries", "code": "\u2648" },
            { "category": "symbols", "keyword": "Taurus", "code": "\u2649" },
            { "category": "symbols", "keyword": "Gemini", "code": "\u264A" },
            { "category": "symbols", "keyword": "Cancer", "code": "\u264B" },
            { "category": "symbols", "keyword": "Leo", "code": "\u264C" },
            { "category": "symbols", "keyword": "Virgo", "code": "\u264D" },
            { "category": "symbols", "keyword": "Libra", "code": "\u264E" },
            { "category": "symbols", "keyword": "Scorpio", "code": "\u264F" },
            { "category": "symbols", "keyword": "Sagittarius", "code": "\u2650" },
            { "category": "symbols", "keyword": "Capricorn", "code": "\u2651" },
            { "category": "symbols", "keyword": "Aquarius", "code": "\u2652" },
            { "category": "symbols", "keyword": "Pisces", "code": "\u2653" },
            { "category": "symbols", "keyword": "Ophiuchus", "code": "\u26CE" },
            { "category": "symbols", "keyword": "shuffle tracks button", "code": "\uD83D\uDD00" },
            { "category": "symbols", "keyword": "repeat button", "code": "\uD83D\uDD01" },
            { "category": "symbols", "keyword": "repeat single button", "code": "\uD83D\uDD02" },
            { "category": "symbols", "keyword": "play button", "code": "\u25B6\uFE0F" },
            { "category": "symbols", "keyword": "fast-forward button", "code": "\u23E9" },
            { "category": "symbols", "keyword": "next track button", "code": "\u23ED\uFE0F" },
            { "category": "symbols", "keyword": "play or pause button", "code": "\u23EF\uFE0F" },
            { "category": "symbols", "keyword": "reverse button", "code": "\u25C0\uFE0F" },
            { "category": "symbols", "keyword": "fast reverse button", "code": "\u23EA" },
            { "category": "symbols", "keyword": "last track button", "code": "\u23EE\uFE0F" },
            { "category": "symbols", "keyword": "upwards button", "code": "\uD83D\uDD3C" },
            { "category": "symbols", "keyword": "fast up button", "code": "\u23EB" },
            { "category": "symbols", "keyword": "downwards button", "code": "\uD83D\uDD3D" },
            { "category": "symbols", "keyword": "fast down button", "code": "\u23EC" },
            { "category": "symbols", "keyword": "pause button", "code": "\u23F8\uFE0F" },
            { "category": "symbols", "keyword": "stop button", "code": "\u23F9\uFE0F" },
            { "category": "symbols", "keyword": "record button", "code": "\u23FA\uFE0F" },
            { "category": "symbols", "keyword": "eject button", "code": "\u23CF\uFE0F" },
            { "category": "symbols", "keyword": "cinema", "code": "\uD83C\uDFA6" },
            { "category": "symbols", "keyword": "dim button", "code": "\uD83D\uDD05" },
            { "category": "symbols", "keyword": "bright button", "code": "\uD83D\uDD06" },
            { "category": "symbols", "keyword": "antenna bars", "code": "\uD83D\uDCF6" },
            { "category": "symbols", "keyword": "vibration mode", "code": "\uD83D\uDCF3" },
            { "category": "symbols", "keyword": "mobile phone off", "code": "\uD83D\uDCF4" },
            { "category": "symbols", "keyword": "female sign", "code": "\u2640\uFE0F" },
            { "category": "symbols", "keyword": "male sign", "code": "\u2642\uFE0F" },
            { "category": "symbols", "keyword": "medical symbol", "code": "\u2695\uFE0F" },
            { "category": "symbols", "keyword": "infinity", "code": "\u267E\uFE0F" },
            { "category": "symbols", "keyword": "recycling symbol", "code": "\u267B\uFE0F" },
            { "category": "symbols", "keyword": "fleur-de-lis", "code": "\u269C\uFE0F" },
            { "category": "symbols", "keyword": "trident emblem", "code": "\uD83D\uDD31" },
            { "category": "symbols", "keyword": "name badge", "code": "\uD83D\uDCDB" },
            { "category": "symbols", "keyword": "Japanese symbol for beginner", "code": "\uD83D\uDD30" },
            { "category": "symbols", "keyword": "heavy large circle", "code": "\u2B55" },
            { "category": "symbols", "keyword": "white heavy check mark", "code": "\u2705" },
            { "category": "symbols", "keyword": "ballot box with check", "code": "\u2611\uFE0F" },
            { "category": "symbols", "keyword": "heavy check mark", "code": "\u2714\uFE0F" },
            { "category": "symbols", "keyword": "heavy multiplication x", "code": "\u2716\uFE0F" },
            { "category": "symbols", "keyword": "cross mark", "code": "\u274C" },
            { "category": "symbols", "keyword": "cross mark button", "code": "\u274E" },
            { "category": "symbols", "keyword": "heavy plus sign", "code": "\u2795" },
            { "category": "symbols", "keyword": "heavy minus sign", "code": "\u2796" },
            { "category": "symbols", "keyword": "heavy division sign", "code": "\u2797" },
            { "category": "symbols", "keyword": "curly loop", "code": "\u27B0" },
            { "category": "symbols", "keyword": "double curly loop", "code": "\u27BF" },
            { "category": "symbols", "keyword": "part alternation mark", "code": "\u303D\uFE0F" },
            { "category": "symbols", "keyword": "eight-spoked asterisk", "code": "\u2733\uFE0F" },
            { "category": "symbols", "keyword": "eight-pointed star", "code": "\u2734\uFE0F" },
            { "category": "symbols", "keyword": "sparkle", "code": "\u2747\uFE0F" },
            { "category": "symbols", "keyword": "double exclamation mark", "code": "\u203C\uFE0F" },
            { "category": "symbols", "keyword": "exclamation question mark", "code": "\u2049\uFE0F" },
            { "category": "symbols", "keyword": "question mark", "code": "\u2753" },
            { "category": "symbols", "keyword": "white question mark", "code": "\u2754" },
            { "category": "symbols", "keyword": "white exclamation mark", "code": "\u2755" },
            { "category": "symbols", "keyword": "exclamation mark", "code": "\u2757" },
            { "category": "symbols", "keyword": "wavy dash", "code": "\u3030\uFE0F" },
            { "category": "symbols", "keyword": "copyright", "code": "%A9\uFE0F" },
            { "category": "symbols", "keyword": "registered", "code": "%AE\uFE0F" },
            { "category": "symbols", "keyword": "trade mark", "code": "\u2122\uFE0F" },
            { "category": "symbols", "keyword": "keycap: #", "code": "%23\uFE0F\u20E3" },
            { "category": "symbols", "keyword": "keycap: *", "code": "*\uFE0F\u20E3" },
            { "category": "symbols", "keyword": "keycap: 0", "code": "0\uFE0F\u20E3" },
            { "category": "symbols", "keyword": "keycap: 1", "code": "1\uFE0F\u20E3" },
            { "category": "symbols", "keyword": "keycap: 2", "code": "2\uFE0F\u20E3" },
            { "category": "symbols", "keyword": "keycap: 3", "code": "3\uFE0F\u20E3" },
            { "category": "symbols", "keyword": "keycap: 4", "code": "4\uFE0F\u20E3" },
            { "category": "symbols", "keyword": "keycap: 5", "code": "5\uFE0F\u20E3" },
            { "category": "symbols", "keyword": "keycap: 6", "code": "6\uFE0F\u20E3" },
            { "category": "symbols", "keyword": "keycap: 7", "code": "7\uFE0F\u20E3" },
            { "category": "symbols", "keyword": "keycap: 8", "code": "8\uFE0F\u20E3" },
            { "category": "symbols", "keyword": "keycap: 9", "code": "9\uFE0F\u20E3" },
            { "category": "symbols", "keyword": "keycap: 10", "code": "\uD83D\uDD1F" },
            { "category": "symbols", "keyword": "hundred points", "code": "\uD83D\uDCAF" },
            { "category": "symbols", "keyword": "input latin uppercase", "code": "\uD83D\uDD20" },
            { "category": "symbols", "keyword": "input latin lowercase", "code": "\uD83D\uDD21" },
            { "category": "symbols", "keyword": "input numbers", "code": "\uD83D\uDD22" },
            { "category": "symbols", "keyword": "input symbols", "code": "\uD83D\uDD23" },
            { "category": "symbols", "keyword": "input latin letters", "code": "\uD83D\uDD24" },
            { "category": "symbols", "keyword": "A button (blood type)", "code": "\uD83C\uDD70\uFE0F" },
            { "category": "symbols", "keyword": "AB button (blood type)", "code": "\uD83C\uDD8E" },
            { "category": "symbols", "keyword": "B button (blood type)", "code": "\uD83C\uDD71\uFE0F" },
            { "category": "symbols", "keyword": "CL button", "code": "\uD83C\uDD91" },
            { "category": "symbols", "keyword": "COOL button", "code": "\uD83C\uDD92" },
            { "category": "symbols", "keyword": "FREE button", "code": "\uD83C\uDD93" },
            { "category": "symbols", "keyword": "information", "code": "\u2139\uFE0F" },
            { "category": "symbols", "keyword": "ID button", "code": "\uD83C\uDD94" },
            { "category": "symbols", "keyword": "circled M", "code": "\u24C2\uFE0F" },
            { "category": "symbols", "keyword": "NEW button", "code": "\uD83C\uDD95" },
            { "category": "symbols", "keyword": "NG button", "code": "\uD83C\uDD96" },
            { "category": "symbols", "keyword": "O button (blood type)", "code": "\uD83C\uDD7E\uFE0F" },
            { "category": "symbols", "keyword": "OK button", "code": "\uD83C\uDD97" },
            { "category": "symbols", "keyword": "P button", "code": "\uD83C\uDD7F\uFE0F" },
            { "category": "symbols", "keyword": "SOS button", "code": "\uD83C\uDD98" },
            { "category": "symbols", "keyword": "UP! button", "code": "\uD83C\uDD99" },
            { "category": "symbols", "keyword": "VS button", "code": "\uD83C\uDD9A" },
            { "category": "symbols", "keyword": "Japanese “here” button", "code": "\uD83C\uDE01" },
            { "category": "symbols", "keyword": "Japanese “service charge” button", "code": "\uD83C\uDE02\uFE0F" },
            { "category": "symbols", "keyword": "Japanese “monthly amount” button", "code": "\uD83C\uDE37\uFE0F" },
            { "category": "symbols", "keyword": "Japanese “not free of charge” button", "code": "\uD83C\uDE36" },
            { "category": "symbols", "keyword": "Japanese “reserved” button", "code": "\uD83C\uDE2F" },
            { "category": "symbols", "keyword": "Japanese “bargain” button", "code": "\uD83C\uDE50" },
            { "category": "symbols", "keyword": "Japanese “discount” button", "code": "\uD83C\uDE39" },
            { "category": "symbols", "keyword": "Japanese “free of charge” button", "code": "\uD83C\uDE1A" },
            { "category": "symbols", "keyword": "Japanese “prohibited” button", "code": "\uD83C\uDE32" },
            { "category": "symbols", "keyword": "Japanese “acceptable” button", "code": "\uD83C\uDE51" },
            { "category": "symbols", "keyword": "Japanese “application” button", "code": "\uD83C\uDE38" },
            { "category": "symbols", "keyword": "Japanese “passing grade” button", "code": "\uD83C\uDE34" },
            { "category": "symbols", "keyword": "Japanese “vacancy” button", "code": "\uD83C\uDE33" },
            { "category": "symbols", "keyword": "Japanese “congratulations” button", "code": "\u3297\uFE0F" },
            { "category": "symbols", "keyword": "Japanese “secret” button", "code": "\u3299\uFE0F" },
            { "category": "symbols", "keyword": "Japanese “open for business” button", "code": "\uD83C\uDE3A" },
            { "category": "symbols", "keyword": "Japanese “no vacancy” button", "code": "\uD83C\uDE35" },
            { "category": "symbols", "keyword": "black small square", "code": "\u25AA\uFE0F" },
            { "category": "symbols", "keyword": "white small square", "code": "\u25AB\uFE0F" },
            { "category": "symbols", "keyword": "white medium square", "code": "\u25FB\uFE0F" },
            { "category": "symbols", "keyword": "black medium square", "code": "\u25FC\uFE0F" },
            { "category": "symbols", "keyword": "white medium-small square", "code": "\u25FD" },
            { "category": "symbols", "keyword": "black medium-small square", "code": "\u25FE" },
            { "category": "symbols", "keyword": "black large square", "code": "\u2B1B" },
            { "category": "symbols", "keyword": "white large square", "code": "\u2B1C" },
            { "category": "symbols", "keyword": "large orange diamond", "code": "\uD83D\uDD36" },
            { "category": "symbols", "keyword": "large blue diamond", "code": "\uD83D\uDD37" },
            { "category": "symbols", "keyword": "small orange diamond", "code": "\uD83D\uDD38" },
            { "category": "symbols", "keyword": "small blue diamond", "code": "\uD83D\uDD39" },
            { "category": "symbols", "keyword": "red triangle pointed up", "code": "\uD83D\uDD3A" },
            { "category": "symbols", "keyword": "red triangle pointed down", "code": "\uD83D\uDD3B" },
            { "category": "symbols", "keyword": "diamond with a dot", "code": "\uD83D\uDCA0" },
            { "category": "symbols", "keyword": "radio button", "code": "\uD83D\uDD18" },
            { "category": "symbols", "keyword": "black square button", "code": "\uD83D\uDD32" },
            { "category": "symbols", "keyword": "white square button", "code": "\uD83D\uDD33" },
            { "category": "symbols", "keyword": "white circle", "code": "\u26AA" },
            { "category": "symbols", "keyword": "black circle", "code": "\u26AB" },
            { "category": "symbols", "keyword": "red circle", "code": "\uD83D\uDD34" },
            { "category": "symbols", "keyword": "blue circle", "code": "\uD83D\uDD35" },
            { "category": "flags", "keyword": "chequered flag", "code": "\uD83C\uDFC1" },
            { "category": "flags", "keyword": "triangular flag", "code": "\uD83D\uDEA9" },
            { "category": "flags", "keyword": "crossed flags", "code": "\uD83C\uDF8C" },
            { "category": "flags", "keyword": "black flag", "code": "\uD83C\uDFF4" },
            { "category": "flags", "keyword": "white flag", "code": "\uD83C\uDFF3\uFE0F" },
            { "category": "flags", "keyword": "rainbow flag", "code": "\uD83C\uDFF3\uFE0F\u200D\uD83C\uDF08" },
            { "category": "flags", "keyword": "pirate flag", "code": "\uD83C\uDFF4\u200D\u2620\uFE0F" },
            { "category": "flags", "keyword": "Ascension Island", "code": "\uD83C\uDDE6\uD83C\uDDE8" },
            { "category": "flags", "keyword": "Andorra", "code": "\uD83C\uDDE6\uD83C\uDDE9" },
            { "category": "flags", "keyword": "United Arab Emirates", "code": "\uD83C\uDDE6\uD83C\uDDEA" },
            { "category": "flags", "keyword": "Afghanistan", "code": "\uD83C\uDDE6\uD83C\uDDEB" },
            { "category": "flags", "keyword": "Antigua & Barbuda", "code": "\uD83C\uDDE6\uD83C\uDDEC" },
            { "category": "flags", "keyword": "Anguilla", "code": "\uD83C\uDDE6\uD83C\uDDEE" },
            { "category": "flags", "keyword": "Albania", "code": "\uD83C\uDDE6\uD83C\uDDF1" },
            { "category": "flags", "keyword": "Armenia", "code": "\uD83C\uDDE6\uD83C\uDDF2" },
            { "category": "flags", "keyword": "Angola", "code": "\uD83C\uDDE6\uD83C\uDDF4" },
            { "category": "flags", "keyword": "Antarctica", "code": "\uD83C\uDDE6\uD83C\uDDF6" },
            { "category": "flags", "keyword": "Argentina", "code": "\uD83C\uDDE6\uD83C\uDDF7" },
            { "category": "flags", "keyword": "American Samoa", "code": "\uD83C\uDDE6\uD83C\uDDF8" },
            { "category": "flags", "keyword": "Austria", "code": "\uD83C\uDDE6\uD83C\uDDF9" },
            { "category": "flags", "keyword": "Australia", "code": "\uD83C\uDDE6\uD83C\uDDFA" },
            { "category": "flags", "keyword": "Aruba", "code": "\uD83C\uDDE6\uD83C\uDDFC" },
            { "category": "flags", "keyword": "Åland Islands", "code": "\uD83C\uDDE6\uD83C\uDDFD" },
            { "category": "flags", "keyword": "Azerbaijan", "code": "\uD83C\uDDE6\uD83C\uDDFF" },
            { "category": "flags", "keyword": "Bosnia & Herzegovina", "code": "\uD83C\uDDE7\uD83C\uDDE6" },
            { "category": "flags", "keyword": "Barbados", "code": "\uD83C\uDDE7\uD83C\uDDE7" },
            { "category": "flags", "keyword": "Bangladesh", "code": "\uD83C\uDDE7\uD83C\uDDE9" },
            { "category": "flags", "keyword": "Belgium", "code": "\uD83C\uDDE7\uD83C\uDDEA" },
            { "category": "flags", "keyword": "Burkina Faso", "code": "\uD83C\uDDE7\uD83C\uDDEB" },
            { "category": "flags", "keyword": "Bulgaria", "code": "\uD83C\uDDE7\uD83C\uDDEC" },
            { "category": "flags", "keyword": "Bahrain", "code": "\uD83C\uDDE7\uD83C\uDDED" },
            { "category": "flags", "keyword": "Burundi", "code": "\uD83C\uDDE7\uD83C\uDDEE" },
            { "category": "flags", "keyword": "Benin", "code": "\uD83C\uDDE7\uD83C\uDDEF" },
            { "category": "flags", "keyword": "St. Barthélemy", "code": "\uD83C\uDDE7\uD83C\uDDF1" },
            { "category": "flags", "keyword": "Bermuda", "code": "\uD83C\uDDE7\uD83C\uDDF2" },
            { "category": "flags", "keyword": "Brunei", "code": "\uD83C\uDDE7\uD83C\uDDF3" },
            { "category": "flags", "keyword": "Bolivia", "code": "\uD83C\uDDE7\uD83C\uDDF4" },
            { "category": "flags", "keyword": "Caribbean Netherlands", "code": "\uD83C\uDDE7\uD83C\uDDF6" },
            { "category": "flags", "keyword": "Brazil", "code": "\uD83C\uDDE7\uD83C\uDDF7" },
            { "category": "flags", "keyword": "Bahamas", "code": "\uD83C\uDDE7\uD83C\uDDF8" },
            { "category": "flags", "keyword": "Bhutan", "code": "\uD83C\uDDE7\uD83C\uDDF9" },
            { "category": "flags", "keyword": "Bouvet Island", "code": "\uD83C\uDDE7\uD83C\uDDFB" },
            { "category": "flags", "keyword": "Botswana", "code": "\uD83C\uDDE7\uD83C\uDDFC" },
            { "category": "flags", "keyword": "Belarus", "code": "\uD83C\uDDE7\uD83C\uDDFE" },
            { "category": "flags", "keyword": "Belize", "code": "\uD83C\uDDE7\uD83C\uDDFF" },
            { "category": "flags", "keyword": "Canada", "code": "\uD83C\uDDE8\uD83C\uDDE6" },
            { "category": "flags", "keyword": "Cocos (Keeling) Islands", "code": "\uD83C\uDDE8\uD83C\uDDE8" },
            { "category": "flags", "keyword": "Congo - Kinshasa", "code": "\uD83C\uDDE8\uD83C\uDDE9" },
            { "category": "flags", "keyword": "Central African Republic", "code": "\uD83C\uDDE8\uD83C\uDDEB" },
            { "category": "flags", "keyword": "Congo - Brazzaville", "code": "\uD83C\uDDE8\uD83C\uDDEC" },
            { "category": "flags", "keyword": "Switzerland", "code": "\uD83C\uDDE8\uD83C\uDDED" },
            { "category": "flags", "keyword": "Côte d’Ivoire", "code": "\uD83C\uDDE8\uD83C\uDDEE" },
            { "category": "flags", "keyword": "Cook Islands", "code": "\uD83C\uDDE8\uD83C\uDDF0" },
            { "category": "flags", "keyword": "Chile", "code": "\uD83C\uDDE8\uD83C\uDDF1" },
            { "category": "flags", "keyword": "Cameroon", "code": "\uD83C\uDDE8\uD83C\uDDF2" },
            { "category": "flags", "keyword": "China", "code": "\uD83C\uDDE8\uD83C\uDDF3" },
            { "category": "flags", "keyword": "Colombia", "code": "\uD83C\uDDE8\uD83C\uDDF4" },
            { "category": "flags", "keyword": "Clipperton Island", "code": "\uD83C\uDDE8\uD83C\uDDF5" },
            { "category": "flags", "keyword": "Costa Rica", "code": "\uD83C\uDDE8\uD83C\uDDF7" },
            { "category": "flags", "keyword": "Cuba", "code": "\uD83C\uDDE8\uD83C\uDDFA" },
            { "category": "flags", "keyword": "Cape Verde", "code": "\uD83C\uDDE8\uD83C\uDDFB" },
            { "category": "flags", "keyword": "Curaçao", "code": "\uD83C\uDDE8\uD83C\uDDFC" },
            { "category": "flags", "keyword": "Christmas Island", "code": "\uD83C\uDDE8\uD83C\uDDFD" },
            { "category": "flags", "keyword": "Cyprus", "code": "\uD83C\uDDE8\uD83C\uDDFE" },
            { "category": "flags", "keyword": "Czechia", "code": "\uD83C\uDDE8\uD83C\uDDFF" },
            { "category": "flags", "keyword": "Germany", "code": "\uD83C\uDDE9\uD83C\uDDEA" },
            { "category": "flags", "keyword": "Diego Garcia", "code": "\uD83C\uDDE9\uD83C\uDDEC" },
            { "category": "flags", "keyword": "Djibouti", "code": "\uD83C\uDDE9\uD83C\uDDEF" },
            { "category": "flags", "keyword": "Denmark", "code": "\uD83C\uDDE9\uD83C\uDDF0" },
            { "category": "flags", "keyword": "Dominica", "code": "\uD83C\uDDE9\uD83C\uDDF2" },
            { "category": "flags", "keyword": "Dominican Republic", "code": "\uD83C\uDDE9\uD83C\uDDF4" },
            { "category": "flags", "keyword": "Algeria", "code": "\uD83C\uDDE9\uD83C\uDDFF" },
            { "category": "flags", "keyword": "Ceuta & Melilla", "code": "\uD83C\uDDEA\uD83C\uDDE6" },
            { "category": "flags", "keyword": "Ecuador", "code": "\uD83C\uDDEA\uD83C\uDDE8" },
            { "category": "flags", "keyword": "Estonia", "code": "\uD83C\uDDEA\uD83C\uDDEA" },
            { "category": "flags", "keyword": "Egypt", "code": "\uD83C\uDDEA\uD83C\uDDEC" },
            { "category": "flags", "keyword": "Western Sahara", "code": "\uD83C\uDDEA\uD83C\uDDED" },
            { "category": "flags", "keyword": "Eritrea", "code": "\uD83C\uDDEA\uD83C\uDDF7" },
            { "category": "flags", "keyword": "Spain", "code": "\uD83C\uDDEA\uD83C\uDDF8" },
            { "category": "flags", "keyword": "Ethiopia", "code": "\uD83C\uDDEA\uD83C\uDDF9" },
            { "category": "flags", "keyword": "European Union", "code": "\uD83C\uDDEA\uD83C\uDDFA" },
            { "category": "flags", "keyword": "Finland", "code": "\uD83C\uDDEB\uD83C\uDDEE" },
            { "category": "flags", "keyword": "Fiji", "code": "\uD83C\uDDEB\uD83C\uDDEF" },
            { "category": "flags", "keyword": "Falkland Islands", "code": "\uD83C\uDDEB\uD83C\uDDF0" },
            { "category": "flags", "keyword": "Micronesia", "code": "\uD83C\uDDEB\uD83C\uDDF2" },
            { "category": "flags", "keyword": "Faroe Islands", "code": "\uD83C\uDDEB\uD83C\uDDF4" },
            { "category": "flags", "keyword": "France", "code": "\uD83C\uDDEB\uD83C\uDDF7" },
            { "category": "flags", "keyword": "Gabon", "code": "\uD83C\uDDEC\uD83C\uDDE6" },
            { "category": "flags", "keyword": "United Kingdom", "code": "\uD83C\uDDEC\uD83C\uDDE7" },
            { "category": "flags", "keyword": "Grenada", "code": "\uD83C\uDDEC\uD83C\uDDE9" },
            { "category": "flags", "keyword": "Georgia", "code": "\uD83C\uDDEC\uD83C\uDDEA" },
            { "category": "flags", "keyword": "French Guiana", "code": "\uD83C\uDDEC\uD83C\uDDEB" },
            { "category": "flags", "keyword": "Guernsey", "code": "\uD83C\uDDEC\uD83C\uDDEC" },
            { "category": "flags", "keyword": "Ghana", "code": "\uD83C\uDDEC\uD83C\uDDED" },
            { "category": "flags", "keyword": "Gibraltar", "code": "\uD83C\uDDEC\uD83C\uDDEE" },
            { "category": "flags", "keyword": "Greenland", "code": "\uD83C\uDDEC\uD83C\uDDF1" },
            { "category": "flags", "keyword": "Gambia", "code": "\uD83C\uDDEC\uD83C\uDDF2" },
            { "category": "flags", "keyword": "Guinea", "code": "\uD83C\uDDEC\uD83C\uDDF3" },
            { "category": "flags", "keyword": "Guadeloupe", "code": "\uD83C\uDDEC\uD83C\uDDF5" },
            { "category": "flags", "keyword": "Equatorial Guinea", "code": "\uD83C\uDDEC\uD83C\uDDF6" },
            { "category": "flags", "keyword": "Greece", "code": "\uD83C\uDDEC\uD83C\uDDF7" },
            { "category": "flags", "keyword": "South Georgia & South Sandwich Islands", "code": "\uD83C\uDDEC\uD83C\uDDF8" },
            { "category": "flags", "keyword": "Guatemala", "code": "\uD83C\uDDEC\uD83C\uDDF9" },
            { "category": "flags", "keyword": "Guam", "code": "\uD83C\uDDEC\uD83C\uDDFA" },
            { "category": "flags", "keyword": "Guinea-Bissau", "code": "\uD83C\uDDEC\uD83C\uDDFC" },
            { "category": "flags", "keyword": "Guyana", "code": "\uD83C\uDDEC\uD83C\uDDFE" },
            { "category": "flags", "keyword": "Hong Kong SAR China", "code": "\uD83C\uDDED\uD83C\uDDF0" },
            { "category": "flags", "keyword": "Heard & McDonald Islands", "code": "\uD83C\uDDED\uD83C\uDDF2" },
            { "category": "flags", "keyword": "Honduras", "code": "\uD83C\uDDED\uD83C\uDDF3" },
            { "category": "flags", "keyword": "Croatia", "code": "\uD83C\uDDED\uD83C\uDDF7" },
            { "category": "flags", "keyword": "Haiti", "code": "\uD83C\uDDED\uD83C\uDDF9" },
            { "category": "flags", "keyword": "Hungary", "code": "\uD83C\uDDED\uD83C\uDDFA" },
            { "category": "flags", "keyword": "Canary Islands", "code": "\uD83C\uDDEE\uD83C\uDDE8" },
            { "category": "flags", "keyword": "Indonesia", "code": "\uD83C\uDDEE\uD83C\uDDE9" },
            { "category": "flags", "keyword": "Ireland", "code": "\uD83C\uDDEE\uD83C\uDDEA" },
            { "category": "flags", "keyword": "Israel", "code": "\uD83C\uDDEE\uD83C\uDDF1" },
            { "category": "flags", "keyword": "Isle of Man", "code": "\uD83C\uDDEE\uD83C\uDDF2" },
            { "category": "flags", "keyword": "India", "code": "\uD83C\uDDEE\uD83C\uDDF3" },
            { "category": "flags", "keyword": "British Indian Ocean Territory", "code": "\uD83C\uDDEE\uD83C\uDDF4" },
            { "category": "flags", "keyword": "Iraq", "code": "\uD83C\uDDEE\uD83C\uDDF6" },
            { "category": "flags", "keyword": "Iran", "code": "\uD83C\uDDEE\uD83C\uDDF7" },
            { "category": "flags", "keyword": "Iceland", "code": "\uD83C\uDDEE\uD83C\uDDF8" },
            { "category": "flags", "keyword": "Italy", "code": "\uD83C\uDDEE\uD83C\uDDF9" },
            { "category": "flags", "keyword": "Jersey", "code": "\uD83C\uDDEF\uD83C\uDDEA" },
            { "category": "flags", "keyword": "Jamaica", "code": "\uD83C\uDDEF\uD83C\uDDF2" },
            { "category": "flags", "keyword": "Jordan", "code": "\uD83C\uDDEF\uD83C\uDDF4" },
            { "category": "flags", "keyword": "Japan", "code": "\uD83C\uDDEF\uD83C\uDDF5" },
            { "category": "flags", "keyword": "Kenya", "code": "\uD83C\uDDF0\uD83C\uDDEA" },
            { "category": "flags", "keyword": "Kyrgyzstan", "code": "\uD83C\uDDF0\uD83C\uDDEC" },
            { "category": "flags", "keyword": "Cambodia", "code": "\uD83C\uDDF0\uD83C\uDDED" },
            { "category": "flags", "keyword": "Kiribati", "code": "\uD83C\uDDF0\uD83C\uDDEE" },
            { "category": "flags", "keyword": "Comoros", "code": "\uD83C\uDDF0\uD83C\uDDF2" },
            { "category": "flags", "keyword": "St. Kitts & Nevis", "code": "\uD83C\uDDF0\uD83C\uDDF3" },
            { "category": "flags", "keyword": "North Korea", "code": "\uD83C\uDDF0\uD83C\uDDF5" },
            { "category": "flags", "keyword": "South Korea", "code": "\uD83C\uDDF0\uD83C\uDDF7" },
            { "category": "flags", "keyword": "Kuwait", "code": "\uD83C\uDDF0\uD83C\uDDFC" },
            { "category": "flags", "keyword": "Cayman Islands", "code": "\uD83C\uDDF0\uD83C\uDDFE" },
            { "category": "flags", "keyword": "Kazakhstan", "code": "\uD83C\uDDF0\uD83C\uDDFF" },
            { "category": "flags", "keyword": "Laos", "code": "\uD83C\uDDF1\uD83C\uDDE6" },
            { "category": "flags", "keyword": "Lebanon", "code": "\uD83C\uDDF1\uD83C\uDDE7" },
            { "category": "flags", "keyword": "St. Lucia", "code": "\uD83C\uDDF1\uD83C\uDDE8" },
            { "category": "flags", "keyword": "Liechtenstein", "code": "\uD83C\uDDF1\uD83C\uDDEE" },
            { "category": "flags", "keyword": "Sri Lanka", "code": "\uD83C\uDDF1\uD83C\uDDF0" },
            { "category": "flags", "keyword": "Liberia", "code": "\uD83C\uDDF1\uD83C\uDDF7" },
            { "category": "flags", "keyword": "Lesotho", "code": "\uD83C\uDDF1\uD83C\uDDF8" },
            { "category": "flags", "keyword": "Lithuania", "code": "\uD83C\uDDF1\uD83C\uDDF9" },
            { "category": "flags", "keyword": "Luxembourg", "code": "\uD83C\uDDF1\uD83C\uDDFA" },
            { "category": "flags", "keyword": "Latvia", "code": "\uD83C\uDDF1\uD83C\uDDFB" },
            { "category": "flags", "keyword": "Libya", "code": "\uD83C\uDDF1\uD83C\uDDFE" },
            { "category": "flags", "keyword": "Morocco", "code": "\uD83C\uDDF2\uD83C\uDDE6" },
            { "category": "flags", "keyword": "Monaco", "code": "\uD83C\uDDF2\uD83C\uDDE8" },
            { "category": "flags", "keyword": "Moldova", "code": "\uD83C\uDDF2\uD83C\uDDE9" },
            { "category": "flags", "keyword": "Montenegro", "code": "\uD83C\uDDF2\uD83C\uDDEA" },
            { "category": "flags", "keyword": "St. Martin", "code": "\uD83C\uDDF2\uD83C\uDDEB" },
            { "category": "flags", "keyword": "Madagascar", "code": "\uD83C\uDDF2\uD83C\uDDEC" },
            { "category": "flags", "keyword": "Marshall Islands", "code": "\uD83C\uDDF2\uD83C\uDDED" },
            { "category": "flags", "keyword": "Macedonia", "code": "\uD83C\uDDF2\uD83C\uDDF0" },
            { "category": "flags", "keyword": "Mali", "code": "\uD83C\uDDF2\uD83C\uDDF1" },
            { "category": "flags", "keyword": "Myanmar (Burma)", "code": "\uD83C\uDDF2\uD83C\uDDF2" },
            { "category": "flags", "keyword": "Mongolia", "code": "\uD83C\uDDF2\uD83C\uDDF3" },
            { "category": "flags", "keyword": "Macau SAR China", "code": "\uD83C\uDDF2\uD83C\uDDF4" },
            { "category": "flags", "keyword": "Northern Mariana Islands", "code": "\uD83C\uDDF2\uD83C\uDDF5" },
            { "category": "flags", "keyword": "Martinique", "code": "\uD83C\uDDF2\uD83C\uDDF6" },
            { "category": "flags", "keyword": "Mauritania", "code": "\uD83C\uDDF2\uD83C\uDDF7" },
            { "category": "flags", "keyword": "Montserrat", "code": "\uD83C\uDDF2\uD83C\uDDF8" },
            { "category": "flags", "keyword": "Malta", "code": "\uD83C\uDDF2\uD83C\uDDF9" },
            { "category": "flags", "keyword": "Mauritius", "code": "\uD83C\uDDF2\uD83C\uDDFA" },
            { "category": "flags", "keyword": "Maldives", "code": "\uD83C\uDDF2\uD83C\uDDFB" },
            { "category": "flags", "keyword": "Malawi", "code": "\uD83C\uDDF2\uD83C\uDDFC" },
            { "category": "flags", "keyword": "Mexico", "code": "\uD83C\uDDF2\uD83C\uDDFD" },
            { "category": "flags", "keyword": "Malaysia", "code": "\uD83C\uDDF2\uD83C\uDDFE" },
            { "category": "flags", "keyword": "Mozambique", "code": "\uD83C\uDDF2\uD83C\uDDFF" },
            { "category": "flags", "keyword": "Namibia", "code": "\uD83C\uDDF3\uD83C\uDDE6" },
            { "category": "flags", "keyword": "New Caledonia", "code": "\uD83C\uDDF3\uD83C\uDDE8" },
            { "category": "flags", "keyword": "Niger", "code": "\uD83C\uDDF3\uD83C\uDDEA" },
            { "category": "flags", "keyword": "Norfolk Island", "code": "\uD83C\uDDF3\uD83C\uDDEB" },
            { "category": "flags", "keyword": "Nigeria", "code": "\uD83C\uDDF3\uD83C\uDDEC" },
            { "category": "flags", "keyword": "Nicaragua", "code": "\uD83C\uDDF3\uD83C\uDDEE" },
            { "category": "flags", "keyword": "Netherlands", "code": "\uD83C\uDDF3\uD83C\uDDF1" },
            { "category": "flags", "keyword": "Norway", "code": "\uD83C\uDDF3\uD83C\uDDF4" },
            { "category": "flags", "keyword": "Nepal", "code": "\uD83C\uDDF3\uD83C\uDDF5" },
            { "category": "flags", "keyword": "Nauru", "code": "\uD83C\uDDF3\uD83C\uDDF7" },
            { "category": "flags", "keyword": "Niue", "code": "\uD83C\uDDF3\uD83C\uDDFA" },
            { "category": "flags", "keyword": "New Zealand", "code": "\uD83C\uDDF3\uD83C\uDDFF" },
            { "category": "flags", "keyword": "Oman", "code": "\uD83C\uDDF4\uD83C\uDDF2" },
            { "category": "flags", "keyword": "Panama", "code": "\uD83C\uDDF5\uD83C\uDDE6" },
            { "category": "flags", "keyword": "Peru", "code": "\uD83C\uDDF5\uD83C\uDDEA" },
            { "category": "flags", "keyword": "French Polynesia", "code": "\uD83C\uDDF5\uD83C\uDDEB" },
            { "category": "flags", "keyword": "Papua New Guinea", "code": "\uD83C\uDDF5\uD83C\uDDEC" },
            { "category": "flags", "keyword": "Philippines", "code": "\uD83C\uDDF5\uD83C\uDDED" },
            { "category": "flags", "keyword": "Pakistan", "code": "\uD83C\uDDF5\uD83C\uDDF0" },
            { "category": "flags", "keyword": "Poland", "code": "\uD83C\uDDF5\uD83C\uDDF1" },
            { "category": "flags", "keyword": "St. Pierre & Miquelon", "code": "\uD83C\uDDF5\uD83C\uDDF2" },
            { "category": "flags", "keyword": "Pitcairn Islands", "code": "\uD83C\uDDF5\uD83C\uDDF3" },
            { "category": "flags", "keyword": "Puerto Rico", "code": "\uD83C\uDDF5\uD83C\uDDF7" },
            { "category": "flags", "keyword": "Palestinian Territories", "code": "\uD83C\uDDF5\uD83C\uDDF8" },
            { "category": "flags", "keyword": "Portugal", "code": "\uD83C\uDDF5\uD83C\uDDF9" },
            { "category": "flags", "keyword": "Palau", "code": "\uD83C\uDDF5\uD83C\uDDFC" },
            { "category": "flags", "keyword": "Paraguay", "code": "\uD83C\uDDF5\uD83C\uDDFE" },
            { "category": "flags", "keyword": "Qatar", "code": "\uD83C\uDDF6\uD83C\uDDE6" },
            { "category": "flags", "keyword": "Réunion", "code": "\uD83C\uDDF7\uD83C\uDDEA" },
            { "category": "flags", "keyword": "Romania", "code": "\uD83C\uDDF7\uD83C\uDDF4" },
            { "category": "flags", "keyword": "Serbia", "code": "\uD83C\uDDF7\uD83C\uDDF8" },
            { "category": "flags", "keyword": "Russia", "code": "\uD83C\uDDF7\uD83C\uDDFA" },
            { "category": "flags", "keyword": "Rwanda", "code": "\uD83C\uDDF7\uD83C\uDDFC" },
            { "category": "flags", "keyword": "Saudi Arabia", "code": "\uD83C\uDDF8\uD83C\uDDE6" },
            { "category": "flags", "keyword": "Solomon Islands", "code": "\uD83C\uDDF8\uD83C\uDDE7" },
            { "category": "flags", "keyword": "Seychelles", "code": "\uD83C\uDDF8\uD83C\uDDE8" },
            { "category": "flags", "keyword": "Sudan", "code": "\uD83C\uDDF8\uD83C\uDDE9" },
            { "category": "flags", "keyword": "Sweden", "code": "\uD83C\uDDF8\uD83C\uDDEA" },
            { "category": "flags", "keyword": "Singapore", "code": "\uD83C\uDDF8\uD83C\uDDEC" },
            { "category": "flags", "keyword": "St. Helena", "code": "\uD83C\uDDF8\uD83C\uDDED" },
            { "category": "flags", "keyword": "Slovenia", "code": "\uD83C\uDDF8\uD83C\uDDEE" },
            { "category": "flags", "keyword": "Svalbard & Jan Mayen", "code": "\uD83C\uDDF8\uD83C\uDDEF" },
            { "category": "flags", "keyword": "Slovakia", "code": "\uD83C\uDDF8\uD83C\uDDF0" },
            { "category": "flags", "keyword": "Sierra Leone", "code": "\uD83C\uDDF8\uD83C\uDDF1" },
            { "category": "flags", "keyword": "San Marino", "code": "\uD83C\uDDF8\uD83C\uDDF2" },
            { "category": "flags", "keyword": "Senegal", "code": "\uD83C\uDDF8\uD83C\uDDF3" },
            { "category": "flags", "keyword": "Somalia", "code": "\uD83C\uDDF8\uD83C\uDDF4" },
            { "category": "flags", "keyword": "Suriname", "code": "\uD83C\uDDF8\uD83C\uDDF7" },
            { "category": "flags", "keyword": "South Sudan", "code": "\uD83C\uDDF8\uD83C\uDDF8" },
            { "category": "flags", "keyword": "São Tomé & Príncipe", "code": "\uD83C\uDDF8\uD83C\uDDF9" },
            { "category": "flags", "keyword": "El Salvador", "code": "\uD83C\uDDF8\uD83C\uDDFB" },
            { "category": "flags", "keyword": "Sint Maarten", "code": "\uD83C\uDDF8\uD83C\uDDFD" },
            { "category": "flags", "keyword": "Syria", "code": "\uD83C\uDDF8\uD83C\uDDFE" },
            { "category": "flags", "keyword": "Swaziland", "code": "\uD83C\uDDF8\uD83C\uDDFF" },
            { "category": "flags", "keyword": "Tristan da Cunha", "code": "\uD83C\uDDF9\uD83C\uDDE6" },
            { "category": "flags", "keyword": "Turks & Caicos Islands", "code": "\uD83C\uDDF9\uD83C\uDDE8" },
            { "category": "flags", "keyword": "Chad", "code": "\uD83C\uDDF9\uD83C\uDDE9" },
            { "category": "flags", "keyword": "French Southern Territories", "code": "\uD83C\uDDF9\uD83C\uDDEB" },
            { "category": "flags", "keyword": "Togo", "code": "\uD83C\uDDF9\uD83C\uDDEC" },
            { "category": "flags", "keyword": "Thailand", "code": "\uD83C\uDDF9\uD83C\uDDED" },
            { "category": "flags", "keyword": "Tajikistan", "code": "\uD83C\uDDF9\uD83C\uDDEF" },
            { "category": "flags", "keyword": "Tokelau", "code": "\uD83C\uDDF9\uD83C\uDDF0" },
            { "category": "flags", "keyword": "Timor-Leste", "code": "\uD83C\uDDF9\uD83C\uDDF1" },
            { "category": "flags", "keyword": "Turkmenistan", "code": "\uD83C\uDDF9\uD83C\uDDF2" },
            { "category": "flags", "keyword": "Tunisia", "code": "\uD83C\uDDF9\uD83C\uDDF3" },
            { "category": "flags", "keyword": "Tonga", "code": "\uD83C\uDDF9\uD83C\uDDF4" },
            { "category": "flags", "keyword": "Turkey", "code": "\uD83C\uDDF9\uD83C\uDDF7" },
            { "category": "flags", "keyword": "Trinidad & Tobago", "code": "\uD83C\uDDF9\uD83C\uDDF9" },
            { "category": "flags", "keyword": "Tuvalu", "code": "\uD83C\uDDF9\uD83C\uDDFB" },
            { "category": "flags", "keyword": "Taiwan", "code": "\uD83C\uDDF9\uD83C\uDDFC" },
            { "category": "flags", "keyword": "Tanzania", "code": "\uD83C\uDDF9\uD83C\uDDFF" },
            { "category": "flags", "keyword": "Ukraine", "code": "\uD83C\uDDFA\uD83C\uDDE6" },
            { "category": "flags", "keyword": "Uganda", "code": "\uD83C\uDDFA\uD83C\uDDEC" },
            { "category": "flags", "keyword": "U.S. Outlying Islands", "code": "\uD83C\uDDFA\uD83C\uDDF2" },
            { "category": "flags", "keyword": "United Nations", "code": "\uD83C\uDDFA\uD83C\uDDF3" },
            { "category": "flags", "keyword": "United States", "code": "\uD83C\uDDFA\uD83C\uDDF8" },
            { "category": "flags", "keyword": "Uruguay", "code": "\uD83C\uDDFA\uD83C\uDDFE" },
            { "category": "flags", "keyword": "Uzbekistan", "code": "\uD83C\uDDFA\uD83C\uDDFF" },
            { "category": "flags", "keyword": "Vatican City", "code": "\uD83C\uDDFB\uD83C\uDDE6" },
            { "category": "flags", "keyword": "St. Vincent & Grenadines", "code": "\uD83C\uDDFB\uD83C\uDDE8" },
            { "category": "flags", "keyword": "Venezuela", "code": "\uD83C\uDDFB\uD83C\uDDEA" },
            { "category": "flags", "keyword": "British Virgin Islands", "code": "\uD83C\uDDFB\uD83C\uDDEC" },
            { "category": "flags", "keyword": "U.S. Virgin Islands", "code": "\uD83C\uDDFB\uD83C\uDDEE" },
            { "category": "flags", "keyword": "Vietnam", "code": "\uD83C\uDDFB\uD83C\uDDF3" },
            { "category": "flags", "keyword": "Vanuatu", "code": "\uD83C\uDDFB\uD83C\uDDFA" },
            { "category": "flags", "keyword": "Wallis & Futuna", "code": "\uD83C\uDDFC\uD83C\uDDEB" },
            { "category": "flags", "keyword": "Samoa", "code": "\uD83C\uDDFC\uD83C\uDDF8" },
            { "category": "flags", "keyword": "Kosovo", "code": "\uD83C\uDDFD\uD83C\uDDF0" },
            { "category": "flags", "keyword": "Yemen", "code": "\uD83C\uDDFE\uD83C\uDDEA" },
            { "category": "flags", "keyword": "Mayotte", "code": "\uD83C\uDDFE\uD83C\uDDF9" },
            { "category": "flags", "keyword": "South Africa", "code": "\uD83C\uDDFF\uD83C\uDDE6" },
            { "category": "flags", "keyword": "Zambia", "code": "\uD83C\uDDFF\uD83C\uDDF2" },
            { "category": "flags", "keyword": "Zimbabwe", "code": "\uD83C\uDDFF\uD83C\uDDFC" },
            { "category": "flags", "keyword": "England", "code": "\uD83C\uDFF4\uDB40\uDC67\uDB40\uDC62\uDB40\uDC65\uDB40\uDC6E\uDB40\uDC67\uDB40\uDC7F" },
            { "category": "flags", "keyword": "Scotland", "code": "\uD83C\uDFF4\uDB40\uDC67\uDB40\uDC62\uDB40\uDC73\uDB40\uDC63\uDB40\uDC74\uDB40\uDC7F" },
            { "category": "flags", "keyword": "Wales", "code": "\uD83C\uDFF4\uDB40\uDC67\uDB40\uDC62\uDB40\uDC77\uDB40\uDC6C\uDB40\uDC73\uDB40\uDC7F" },
        ];
        this._filterd = this._emojis;
        this._custom_emojis = [];
        return this;
    }

    find(conditions) {
        let category = conditions.category || 'all';
        let tone = conditions.tone || 'default';
        let keyword = conditions.keyword ? new RegExp(conditions.keyword) : undefined;
        this._filterd = this._emojis.filter(e =>
            (category === "all" || e.category === category) &&
            (!e.tone || e.tone === tone) &&
            (!keyword || keyword.test(e.keyword))
        );
        return this;
    }

    add_custom_emojis(_emojis = []) {
        _emojis = _emojis
            .filter(e => typeof e.code !== 'undefined')
            .map(e => ({
                "category": "custom",
                "keyword": e.keyword || e.code || '',
                "code": e.code,
                "payload": e.payload || undefined
            }));
        this._emojis.push(..._emojis);
        this._custom_emojis.push(..._emojis);
        return this;
    }

    get custom_emojis() {
        return this._custom_emojis;
    }

    get payload() {
        return this.custom_emojis.map(e => e.payload)
    }

    toString() {
        return this.filterd.map(e => e.code).join('');
    }

    get filterd() {
        return this._filterd;
    }
}